import { Button } from 'component/Button'
import { Input } from 'component/Input'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'

export const DeleteCollectionModal = observer(() => {
  const store = modal.deleteCollectionModal.it
  const { name, text, canDelete } = store

  const onChangeText = useLatestCallback((value: string) => {
    store.text = value
  })

  const onCancel = useLatestCallback(() => {
    modal.deleteCollectionModal.close()
  })

  const onDelete = useLatestCallback(async () => {
    modal.deleteCollectionModal.close()
    await store.deleteCollection()
  })

  return <Modal title={i18n('collection.DeleteCollection')} onCancel={onCancel}>
    <Vertical gap={12}>
      <Typo size={14}>
        {i18n('collection.YouAreAboutToDeleteNameCollection', { n: <Typo size={14} bold />, name })}
      </Typo>
      <Typo size={14}>
        {i18n('common.PleaseTypeTextToConfirm', { t: <Typo size={14} secondary />, text: name })}
      </Typo>
      <Input value={text} onChange={onChangeText} />
    </Vertical>
    <Space spread gap={12}>
      <Button primary action={onDelete} disabled={!canDelete}>{i18n('common.Delete')}</Button>
      <Button action={onCancel}>{i18n('common.Cancel')}</Button>
    </Space>
  </Modal>
})
