import { IconButton } from 'component/Button/IconButton'
import { Link } from 'component/Link'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { FilterText } from 'saas/page/groups/components/FilterText'
import { groupDeleteModal, GroupDeleteModalStore } from 'saas/store/groups/GroupDeleteModalStore'
import { GroupStore } from 'saas/store/groups/GroupStore'
import { route } from 'store/route'
import { compact } from 'util/array'
import { formatDate } from 'util/date'

type Props = {
  group: GroupStore
}

export const GroupRow = observer(({ group }: Props) => {
  const { company_id, group_id, group_name, filter, full_count, created_at } = group.json
  const path = `/company/${company_id}/group/${group_id}`

  const actions: DropdownItem[] = compact([
    {
      key: 'edit',
      label: i18n('common.Edit'),
      action() {
        route.push(path)
      },
    },
    {
      key: 'delete',
      label: i18n('common.Delete'),
      action() {
        groupDeleteModal.open(new GroupDeleteModalStore(group))
      },
    },
  ])

  return <tr>
    <td>
      <Link path={path}>
        <Typo size={13}>
          {group_name}
        </Typo>
      </Link>
    </td>
    <td>
      <Vertical gap={4}>
        {filter.filters.map((filter, index) => <FilterText key={index} filter={filter} />)}
      </Vertical>
    </td>
    <td>
      <Typo size={13}>
        {full_count?.toFixed()}
      </Typo>
    </td>
    <td>
      <Typo size={13}>
        {formatDate(created_at)}
      </Typo>
    </td>
    <td>
      <Space right>
        <Dropdown items={actions} placement={'bottomRight'}>
          <IconButton><Icon name="more_horiz" /></IconButton>
        </Dropdown>
      </Space>
    </td>
  </tr>
})
