import clsx from 'clsx'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { watermarkPositions } from 'saas/store/watermark/types'
import css from './WatermarkPosition.module.scss'

type Props = {
  value: (typeof watermarkPositions)[number] | null
  onChange: (position: (typeof watermarkPositions)[number] | null) => void
}

export const WatermarkPosition = observer(({ value, onChange }: Props) => {
  const [active, setActive] = useState<number | null>(() => {
    const index = watermarkPositions.findIndex((position) => position === value)
    if (index > -1) {
      return index
    }

    return 0
  })

  const onClick = (index: number) => {
    setActive(index)
    onChange(watermarkPositions[index] || watermarkPositions[0])
  }

  useEffect(() => {
    if (value) {
      const index = watermarkPositions.findIndex(
        (position) => position === value,
      )
      setActive(index > -1 ? index : 0)
    }
  }, [value])

  return (
    <Space gap={4}>
      {watermarkPositions.map((position, index) => {
        const positionClassName = position.replace('-', '')
        return (
          <div
            onClick={() => onClick(index)}
            key={position}
            className={clsx(css.positionItem, index === active && css.active)}
          >
            <div className={css.wrapper}>
              <div
                className={clsx(css.positionItemInner, css[positionClassName])}
              />
            </div>
          </div>
        )
      })}{' '}
    </Space>
  )
})
