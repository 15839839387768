import { PointTooltipProps } from '@nivo/line'
import { Card } from 'component/Card'
import { Fixed } from 'component/Portal/Fixed'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useMemo } from 'react'
import { getSerieTitle } from 'saas/store/stat/ChartSerie'
import { chart } from 'saas/store/stat/ChartStore'
import { notNil } from 'util/null'
import { by } from 'util/sort'
import css from './Tooltip.module.scss'

export const Tooltip = ({ point }: PointTooltipProps) => {
  const { series } = chart

  const cross = useMemo(() => {
    return series.map(serie => {
      const data = serie.data.find(d => d.x === point.data.x)
      if (!data) return undefined

      const { id, color } = serie
      const title = getSerieTitle(id)
      return { id, title, color, data }
    }).filter(notNil).sort(by(serie => serie.data.y, true))
  }, [point.data.x, series])

  return <Fixed>
    <Card pad={[8, 12]} className={css.card}>
      <Vertical gap={4}>
        {cross.map(serie => {
          const { id, title, color, data } = serie

          return <Space key={id} gap={16} opposite>
            <Space gap={8}>
              <Space width={12} height={12} round={6} margin={[-1, 0, 0, 0]} style={{ backgroundColor: color }} />
              <Typo size={13}>
                {title}
              </Typo>
            </Space>
            <Typo size={13} semi>
              {data.y}
            </Typo>
          </Space>
        })}
      </Vertical>
    </Card>
  </Fixed>
}
