import { clsx } from 'clsx'
import css from 'component/Portal/Fixed.module.scss'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { cloneElement, LegacyRef, ReactElement, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'

type ChildProps = {
  ref?: LegacyRef<HTMLElement>
  className?: string
}

type Props = {
  children: ReactElement<ChildProps>
}

export const Fixed = ({ children }: Props) => {
  const relativeRef = useRef<HTMLElement>()
  const fixedRef = useRef<HTMLElement>()
  const container = useMemo(() => document.createElement('div'), [])
  const className = children.props.className
  const relativeClass = clsx(className, css.relative)
  const fixedClass = clsx(className, css.fixed)

  useEffectOnce(() => {
    document.body.appendChild(container)
    return () => void document.body.removeChild(container)
  })

  const update = useLatestCallback(() => {
    const relative = relativeRef.current
    const fixed = fixedRef.current
    if (!relative || !fixed) return

    const { x, y, width, height } = relative.getBoundingClientRect()
    fixed.style.left = x + 'px'
    fixed.style.top = y + 'px'
    fixed.style.width = width + 'px'
    fixed.style.height = height + 'px'
  })

  useEffectOnce(() => {
    let id = 0

    function tick() {
      id = requestAnimationFrame(tick)
      update()
    }

    tick()
    return () => cancelAnimationFrame(id)
  })

  const onRelativeRef = useLatestCallback((ref: HTMLElement | null) => {
    relativeRef.current = ref ?? undefined
  })

  const fixedTimer = useRef<TimeoutId>()

  const onFixedRef = useLatestCallback((ref: HTMLElement | null) => {
    clearTimeout(fixedTimer.current)
    if (ref) fixedTimer.current = setTimeout(() => fixedRef.current = ref, 10)
    else fixedRef.current = undefined
  })

  const relativeElement = cloneElement(children, { ref: onRelativeRef, className: relativeClass })
  const fixedElement = cloneElement(children, { ref: onFixedRef, className: fixedClass })

  return <>
    {relativeElement}
    {createPortal(fixedElement, container)}
  </>
}
