import { Dropdown } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { i18n } from 'i18n'
export const ProductSelectStatus = observer(() => {
  const options = [
    {
      key: 'all',
      label: i18n('products.filter.AllProducts'),
      action: () => {
        productsSaas.it.filters = {
          ...productsSaas.it.filters,
          published: 'all',
        }
      },
    },
    {
      key: 'active',
      label: i18n('products.filter.Active'),
      action: () => {
        productsSaas.it.filters = {
          ...productsSaas.it.filters,
          published: 'active',
        }
      },
    },
    {
      key: 'inactive',
      label: i18n('products.filter.Inactive'),
      action: () => {
        productsSaas.it.filters = {
          ...productsSaas.it.filters,
          published: 'inactive',
        }
      },
    },
  ]

  return (
    <Space>
      <Dropdown placement="bottomRight" items={options}>
        <Space gap={8} height={20} oh>
          <Typo size={13} medium secondary>
            {i18n('products.filter.Status')}:
          </Typo>
          <Space>
            <Typo size={13}>
              {options.find(
                (it) => it.key === productsSaas.it.filters.published
              )?.label || options[0]!.label}
            </Typo>
            <Icon name="keyboard_arrow_down" />
          </Space>
        </Space>
      </Dropdown>
    </Space>
  )
})
