import { getWindowHost } from 'app/config/window'
import clsx from 'clsx'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import css from 'saas/page/product/stand/LinkButton/LinkButton.module.scss'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'

type Props = {
  product?: ProductSaasStore
  className?: string
}

export const LinkButton = observer(({ product, className }: Props) => {
  product ??= productSaas.it
  const { published } = product.json
  const host = getWindowHost()
  const linkFull = `${host}/${product.product_id}`

  if (!published) return null

  return (
    <Link
      href={linkFull}
      className={clsx(css.link, className)}
    >
      <Space className={css.button} pad={6} gap={4} round={6}>
        <Typo size={11}>{i18n('label.Website')}</Typo>
        <Icon name="open_in_new" size={12} />
      </Space>
    </Link>
  )
})
