import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { CreateCatalogStep } from 'modal/UploadCollectionModal/CreateCatalogStep'
import { SelectCatalogStep } from 'modal/UploadCollectionModal/SelectCatalogStep'

export const CatalogStep = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const { create } = mod.catalogStep

  if (create) return <CreateCatalogStep />
  else return <SelectCatalogStep />
})
