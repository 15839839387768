import { AxisTickProps } from '@nivo/axes'
import { formatGroupDates } from 'saas/store/stat/ChartSerie'
import { chart } from 'saas/store/stat/ChartStore'
import { last } from 'util/array'
import css from './DateTick.module.scss'

export function renderDateTick(props: AxisTickProps<number>) {
  const { series, group } = chart
  const { x, y, textX, textY, textBaseline, value } = props
  const transform = `translate(${textX}, ${textY}) rotate(0)`
  const dates = formatGroupDates(value, group)
  const latest = last(series[0]?.data ?? [])
  const end = latest?.x === value
  const textAnchor = end ? 'end' : 'start'

  return <g className={css.tick} transform={`translate(${x}, ${y})`}>
    <text dominantBaseline={textBaseline} textAnchor={textAnchor} transform={transform}>
      <tspan className={css.date} x={0} dy={0}>
        {dates[0]}
      </tspan>
      <tspan className={css.year} x={0} dy={16}>
        {dates[1]}
      </tspan>
    </text>
  </g>
}
