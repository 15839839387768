// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchCustomSelect-select-0a3f{min-height:var(--height)}.SearchCustomSelect-select-0a3f .ant-select-selection-item{font-weight:500}.SearchCustomSelect-select-0a3f .ant-select-selection-search-input{font-weight:500}.SearchCustomSelect-select-0a3f .ant-select-selector{padding:4px;min-height:var(--height)}.SearchCustomSelect-select-0a3f .ant-select-selector:not(:focus-within){border-color:rgba(0,0,0,0) !important}.SearchCustomSelect-select-0a3f .ant-select-selector:focus-within{background-color:var(--ui-background-color) !important}.SearchCustomSelect-select-0a3f .ant-select-selection-overflow{gap:4px}.SearchCustomSelect-item-0a3f{height:auto;padding:0}`, "",{"version":3,"sources":["webpack://./src/component/Select/SearchCustomSelect.module.scss"],"names":[],"mappings":"AAAA,gCACE,wBAAA,CAEA,2DACE,eAAA,CAGF,mEACE,eAAA,CAGF,qDACE,WAAA,CACA,wBAAA,CAGF,wEACE,qCAAA,CAGF,kEACE,sDAAA,CAGF,+DACE,OAAA,CAIJ,8BACE,WAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `SearchCustomSelect-select-0a3f`,
	"item": `SearchCustomSelect-item-0a3f`
};
module.exports = ___CSS_LOADER_EXPORT___;
