type Predicate<T> = (value: T) => boolean

export function every<T>(iterable: Iterable<T>, predicate: Predicate<T>): boolean {
  for (const value of iterable) {
    if (!predicate(value)) return false
  }
  return true
}

export function map<T, R>(iterable: Iterable<T>, convert: (value: T, index: number) => R): R[] {
  const result = []
  let index = 0
  for (const value of iterable) {
    result.push(convert(value, index))
    ++index
  }
  return result
}

export function filter<T>(iterable: Iterable<T>, predicate: (value: T, index: number) => boolean): T[] {
  const result = []
  let index = 0
  for (const value of iterable) {
    if (predicate(value, index)) result.push(value)
    ++index
  }
  return result
}

export function each<T, R>(iterable: Iterable<T>, callback: (value: T, index: number) => R): void {
  let index = 0
  for (const value of iterable) {
    callback(value, index)
    ++index
  }
}
