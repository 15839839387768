import { Space } from 'component/Space'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Info } from 'saas/page/statistics/feedback/Info'
import { DISLIKE_COLOR, LIKE_COLOR, NONE_COLOR } from 'saas/store/stat/Feedback'
import { feedback } from 'saas/store/stat/FeedbackStore'

export const Likes = observer(() => {
  const { likes, dislikes } = feedback

  if (!likes || !dislikes) return null

  return <Space gap={24} pad={[8, 0]} center wrap>
    <Space gap={8} top>
      <Icon size={16} name="thumb_up" fill color={likes.count ? LIKE_COLOR : NONE_COLOR} />
      <Info data={likes} />
    </Space>
    <Space gap={8} top>
      <Icon size={16} name="thumb_up" fill color={dislikes.count ? DISLIKE_COLOR : NONE_COLOR} />
      <Info data={dislikes} />
    </Space>
  </Space>
})
