import { ResponsivePie } from '@nivo/pie'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { Label } from 'saas/page/statistics/feedback/Label'
import { Tooltip } from 'saas/page/statistics/feedback/Tooltip'
import { EMPTY_SERIES, PieSerie } from 'saas/store/stat/Feedback'
import { feedback } from 'saas/store/stat/FeedbackStore'

type Datum = {
  data: PieSerie
}

export const Pie = observer(() => {
  const { series } = feedback
  const active = !!series.length

  return <Vertical width={220} height={220} flex="0 0 auto">
    <ResponsivePie
      animate={false}
      arcLabelsComponent={Label}
      arcLabelsTextColor={'#00000080'}
      colors={(d: Datum) => d.data.color}
      cornerRadius={8}
      data={active ? series : EMPTY_SERIES}
      enableArcLabels={active}
      enableArcLinkLabels={false}
      innerRadius={0.5}
      isInteractive={active}
      legends={[]}
      padAngle={2}
      tooltip={Tooltip}
      value={(data: PieSerie) => data.percent}
    />
  </Vertical>
})
