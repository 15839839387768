import { Image } from 'component/Image'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { ItemStore } from 'saas/page/tsum/store/ItemStore'

type Props = {
  item: ItemStore
}

export const Images = observer(({ item }: Props) => {
  const { images } = item.json

  return <Space gap={12}>
    {images.slice(0, 3).map((image, index) => <Space key={index} width={200} height={300}>
      <Image src={image} sources={images} />
    </Space>)}
  </Space>
})
