import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ApiKeysCard } from 'saas/page/company/manage/api/ApiKeysCard'
import { DataSection } from 'saas/page/company/manage/DataSection'
import { MemberSection } from 'saas/page/company/manage/MemberSection'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'
import { ui } from 'store/home/ui/HomeUiStore'
import { Watermark } from './sections/Watermark'

export const Layout = observer(() => {
  const large = ui.window.width > 1685
  const { company_id } = companyEdit.it

  if (large) return <Space gap={16} top wide>
    <Vertical gap={16} flex="1 0 520px">
      <Card gap={24} pad={24} vertical wide>
        <DataSection />
      </Card>
      <Watermark />
      <MemberSection />
    </Vertical>
    {can.ViewApiKeys(company_id) && <Vertical width={520}>
      <ApiKeysCard />
    </Vertical>}
  </Space>
  else return <Vertical gap={16}>
    <Card gap={24} pad={24} vertical wide>
      <DataSection />
    </Card>
    <Watermark />
    {can.ViewApiKeys(company_id) && <ApiKeysCard />}
    <MemberSection />
  </Vertical>
})
