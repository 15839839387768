import { makeAutoObservable } from 'mobx'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { createProduct } from 'saas/store/product/utils'
import { productsConfig, ProductsConfigStore } from 'saas/store/products/ProductsConfigStore'
import { SetStore } from 'store/base/SetStore'
import { Product } from 'type/product/Product'
import { extractProducts } from 'type/product/utils'
import { notNil } from 'util/null'
import { ApiKeyStore } from '../key/ApiKeyStore'

export function createProducts(company_id: string, config: ProductsConfigStore): ProductSaasStore[] {
  const products = extractProducts(config.json)
  return products.map(json => createProduct(company_id, json)).filter(notNil)
}

export type ProductFilters = { type?: string, published?: string }

export class ProductsSaasStore {
  readonly company_id: string
  readonly products: ProductSaasStore[]
  readonly keys: SetStore<ApiKeyStore>
  sortedProducts: ProductSaasStore[] = []
  private _filters: ProductFilters = { type: 'all', published: 'all' }

  constructor(company_id: string, config: ProductsConfigStore, keys: SetStore<ApiKeyStore>) {
    makeAutoObservable(this)
    this.company_id = company_id
    this.products = createProducts(company_id, config)
    this.sortedProducts = this.products
    this.keys = keys
  }

  get config(): ProductsConfigStore {
    return productsConfig.get(this.company_id)
  }

  get busy(): boolean {
    return this.config.busy
  }

  set filters(filters: ProductFilters) {
    this._filters = filters
    this.filterProducts()
  }

  get filters(): ProductFilters {
    return this._filters
  }

  async filterProducts() {
    const { type, published } = this.filters
    const isAll = type === 'all'
    const isPublished = published !== undefined && published !== 'all'
    const publishedFilter = (published: string, productPublished: boolean) => published === 'active' ? productPublished : !productPublished

    const products = this.products.filter(
      it => (!isAll ? it.json.type === type : true)
        && (isPublished ? publishedFilter(published!, it.json.published!) : true))
    this.sortedProducts = products
  }

  async addProduct(product: Product) {
    const store = createProduct(this.company_id, product)
    if (!store) throw new Error('no product')
    this.products.push(store)
    await this.config.addProduct(product)
  }

  async deleteProduct(product: ProductSaasStore) {
    const draft = product.json._draft
    const index = this.products.indexOf(product)
    if (index < 0) throw new Error('unknown product')

    this.products.splice(index, 1)
    const config = productsConfig.get(this.company_id)

    if (!draft) await config.deleteProduct(product.json)
  }
}
