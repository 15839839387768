// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductList-productList-0764{display:grid;grid-template-columns:1fr;grid-template-rows:max-content}@media all and (min-width: 768px){.ProductList-productList-0764{grid-template-columns:repeat(1, 1fr)}}@media all and (min-width: 1100px){.ProductList-productList-0764{grid-template-columns:repeat(2, 1fr)}}@media all and (min-width: 1440px){.ProductList-productList-0764{grid-template-columns:repeat(3, 1fr)}}@media all and (min-width: 1920px){.ProductList-productList-0764{grid-template-columns:repeat(4, 1fr)}}`, "",{"version":3,"sources":["webpack://./src/saas/page/products/components/ProductList.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,yBAAA,CACA,8BAAA,CAEA,kCALF,8BAMI,oCAAA,CAAA,CAGF,mCATF,8BAUI,oCAAA,CAAA,CAGF,mCAbF,8BAcI,oCAAA,CAAA,CAGF,mCAjBF,8BAkBI,oCAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productList": `ProductList-productList-0764`
};
module.exports = ___CSS_LOADER_EXPORT___;
