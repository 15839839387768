import { IconButton } from 'component/Button/IconButton'
import { Sticker } from 'component/Glass'
import { Image, Preview } from 'component/Image'
import { usePreviewControl } from 'component/Image/PreviewControl'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { Vertical } from 'component/Vertical'
import { FieldStore } from 'form/store/FieldStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ImageFile } from 'store/base/ImageFile'
import { nil } from 'util/null'
import css from './ImageUpload.module.scss'

type Props = {
  field: FieldStore<ImageFile | undefined>
  title: string
  description?: string
  original?: string | nil
  disabled?: boolean
}

export const ImageUpload = observer(
  ({ field, title, disabled, description }: Props) => {
    const source = field.value?.url
    const sources = source ? [source] : []
    const control = usePreviewControl()

    const onFile = useLatestCallback((file: File) => {
      field.value = ImageFile.fromFile(file)
    })

    const onDelete = useLatestCallback(() => {
      field.value = undefined
    })

    return (
      <Vertical gap={8} className={css.wrapper}>
        <Typo size={13} semi>
          {title}
        </Typo>
        <Preview control={control} sources={sources} />
        <Upload
          disabled={disabled}
          accept="image"
          wide
          height={148}
          onFile={onFile}
          className={css.upload}
        >
          {!source && (
            <Vertical gap={8} center>
              <div className={css.iconUpload}>
                <Icon size={24} name="upload_file" />
              </div>
              <Vertical>
                <Typo secondary>{title}</Typo>
                {description && <Typo secondary>{description}</Typo>}
              </Vertical>
            </Vertical>
          )}
          {source && <Image src={source} />}
        </Upload>
        {source && (
          <Sticker pin={[8, 8, null, null]}>
            <IconButton small action={onDelete}>
              <Icon name="delete" size={16} />
            </IconButton>
          </Sticker>
        )}
      </Vertical>
    )
  }
)
