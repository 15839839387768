import { Card } from 'component/Card'
import { Divider } from 'component/Space/Divider'
import { observer } from 'mobx-react-lite'
import { ApiKeySelect } from 'saas/page/generations/co/ApiKeySelect'
import { CollectionSelect } from 'saas/page/generations/co/CollectionSelect'
import { filters } from 'saas/page/generations/store/FiltersStore'
import { IntervalSelect } from 'saas/shared/interval/IntervalSelect'
import css from './FiltersCard.module.scss'

export const FiltersCard = observer(() => {
  const { interval } = filters.it

  return <Card vertical gap={16} pad={16}>
    <div className={css.filters}>
      <ApiKeySelect />
      <CollectionSelect />
    </div>
    <Divider secondary />
    <IntervalSelect interval={interval} />
  </Card>
})
