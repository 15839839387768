import { when } from 'mobx'
import { DeleteCollectionModalStore } from 'modal/DeleteCollectionModal'
import { DeleteProductModalStore } from 'modal/DeleteProductModal'
import { DeleteSourceModalStore } from 'modal/DeleteSourceModal'
import { EditCollectionModalStore } from 'modal/EditCollectionModal'
import { InviteUsersModalStore } from 'modal/InviteUsersModal/InviteUsersModalStore'
import { ProfileModalStore } from 'modal/ProfileModal'
import { SupportModalStore } from 'modal/SupportModal'
import { TestItemModalStore } from 'modal/TestItemModal'
import { UploadCollectionModalStore } from 'modal/UploadCollectionModal/store/UploadCollectionModalStore'
import { inviteUsers, openInviteUsers } from 'page/invite/store/InviteUsersStore'
import { mx } from 'store/base/mx'
import { CollectionStore } from 'store/collection'
import { CompanyStore } from 'store/company/CompanyStore'
import { SourceStore } from 'store/generation/SourceStore'
import { ProductStore } from 'store/product'
import { ProductItem } from 'store/product/ProductItem'
import { CreateCompanyModalStore } from 'modal/CreateCompanyModal'

export class ModalController {
  readonly editCollectionModal = mx.ref<EditCollectionModalStore>()
  readonly uploadCollectionModal = mx.ref<UploadCollectionModalStore>()
  readonly inviteUsersModal = mx.ref<InviteUsersModalStore>()
  readonly deleteCollectionModal = mx.ref<DeleteCollectionModalStore>()
  readonly deleteProductModal = mx.ref<DeleteProductModalStore>()
  readonly deleteSourceModal = mx.ref<DeleteSourceModalStore>()
  readonly profileModal = mx.ref<ProfileModalStore>()
  readonly supportModal = mx.ref<SupportModalStore>()
  readonly testItemModal = mx.ref<TestItemModalStore>()
  readonly createCompanyModal = mx.ref<CreateCompanyModalStore>()
  async openCreateCollectionModal() {
    this.editCollectionModal.it = new EditCollectionModalStore()
  }

  async openEditCollectionModal(collection: CollectionStore) {
    this.editCollectionModal.it = new EditCollectionModalStore(collection)
  }

  async openUploadCollectionModal(collection?: CollectionStore) {
    this.uploadCollectionModal.it = new UploadCollectionModalStore(collection)
  }

  openInviteUsersModal(company: CompanyStore | undefined) {
    openInviteUsers(company)
    this.inviteUsersModal.it = new InviteUsersModalStore()
    when(() => !inviteUsers.present, () => this.inviteUsersModal.close())
  }

  async openDeleteCollectionModal(collection: CollectionStore) {
    this.deleteCollectionModal.it = new DeleteCollectionModalStore(collection)
  }

  openDeleteProductModal(item: ProductItem): boolean {
    const store = new DeleteProductModalStore(item)
    if (store.skip) return false
    this.deleteProductModal.it = store
    return true
  }

  async openDeleteSourceModal(product: ProductStore, source: SourceStore) {
    this.deleteSourceModal.it = new DeleteSourceModalStore(product, source)
  }

  async openProfileModal() {
    this.profileModal.it = new ProfileModalStore()
  }

  async openSupportModal() {
    this.supportModal.it = new SupportModalStore()
  }

  async openTestItemModal(product: ProductStore) {
    this.testItemModal.it = new TestItemModalStore(product)
  }

  async openCreateCompanyModal() {
    this.createCompanyModal.it = new CreateCompanyModalStore()
  }
}
