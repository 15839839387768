import { clsx } from 'clsx'
import { Space } from 'component/Space'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { BOT, ProductTypeList } from 'type/product/ProductType'
import css from './StatusTag.module.scss'

type Props = {
  product?: ProductSaasStore
  colored?: boolean
  collapse?: boolean
  outlined?: boolean
}

const productStyleMap = {
  [ProductTypeList.BOT]: css.bot,
  [ProductTypeList.STAND]: css.stand,
  [ProductTypeList.TRY_ON]: css.tryOn,
}

function icon(product: ProductSaasStore) {
  const { type, published } = product.json
  const bot = type === BOT

  if (published) return <Icon size={12} name="check_circle" fill />
  else if (bot) return <Icon size={12} name="help" fill />
  else return <Icon size={12} name="stop_circle" />
}

function text(product: ProductSaasStore) {
  const { type, published } = product.json
  const bot = type === BOT

  if (published) return bot ? i18n('bot.Connected') : i18n('product.Published')
  else return bot ? i18n('bot.NotConnected') : i18n('product.NotPublished')
}

export const StatusTag = observer(
  ({ product, colored, outlined, collapse }: Props) => {
    product ??= productSaas.it

    const { published, type } = product.json

    if (!published) return null

    return (
      <Space
        className={clsx(
          css.status,
          published && css.success,
          colored && css.colored,
          colored && productStyleMap[type],
          outlined && css.outlined,
          collapse && css.collapse
        )}
      >
        {icon(product)}
        <span className={css.statusbagText}>{text(product)}</span>
      </Space>
    )
  }
)
