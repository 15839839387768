import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Sticker } from 'component/Glass'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'
import css from './ImageInput.module.scss'

export const ImageInput = observer(() => {
  const original = stand.it.json.image
  const { image } = stand.it.edit
  const { value, busy, src } = image
  const changed = busy || value !== original

  const onFile = useLatestCallback((file: File) => {
    image.file = file
  })

  const onDelete = useLatestCallback(() => {
    image.value = undefined
  })

  const onUndo = useLatestCallback(() => {
    image.value = original
  })

  return <Space>
    <Upload active={!!src} accept="image" onFile={onFile}>
      <Vertical pad={12} gap={8} width={400} maxWidth="calc(100vw - 48px)">
        <Space gap={8}>
          <Typo size={13} semi>{i18n('stand.MetaImage')}</Typo>
          {busy && <Spinner size={16} />}
        </Space>
        <Space className={clsx(css.image)} height={192} center>
          {src ? <img src={src} /> : <Vertical gap={8} center>
            <Card width={32} height={32} round={40} panel center>
              <Icon size={16} name="upload" secondary />
            </Card>
            <Vertical gap={4}>
              <Typo size={11} first>{i18n('stand.MetaImageUpload')}</Typo>
              <Typo size={11} secondary>JPG</Typo>
            </Vertical>
          </Vertical>}
        </Space>
      </Vertical>
    </Upload>
    {src && <Sticker pin={[6, 6, null, null]}>
      <IconButton action={onDelete}>
        <Icon size={16} name="delete" />
      </IconButton>
    </Sticker>}
    {changed && <Sticker pin={[null, 6, 6, null]}>
      <IconButton action={onUndo}>
        <Icon size={16} name="undo" />
      </IconButton>
    </Sticker>}
  </Space>
})
