import { disk, emdash } from 'util/typo'

export type ApiKey = {
  key_name: string
  access_key: string
  created_at: string
  origins?: string | null
  read_only?: 0 | 1
  is_deleted?: 0 | 1
  priority: number
}
export type CreateApiKeyRequest = {
  user_id: string
  company_id: string
  name: string
  origins: string
  priority?: number
}
export type UpdateApiKeyRequest = {
  user_id: string
  company_id: string
  access_key: string
  name: string
  origins: string
  priority?: number
}
export type CreateApiKeyResponse = {
  access_key: string
}
export type UpdateApiKeyResponse = ApiKey | ApiKey[]

export function formatShortApiKey(value: string | null | undefined): string {
  if (!value) return emdash

  const left = value.substring(0, 8)
  const right = value.substring(value.length - 8, value.length)
  return left + disk + disk + disk + right
}
