import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'

type Props = {
  /** page number starting from `1` */
  page: number
  /** has more */
  more: boolean
  /** change page number */
  onChange(page: number): void
  /** scroll page up on change */
  scroll?: boolean
  /** search is busy */
  busy?: boolean
}

export const MoreInput = observer((props: Props) => {
  const { page, more, onChange, scroll, busy } = props
  const start = page <= 1
  const end = !more

  const shiftPage = useLatestCallback((shift: number) => {
    const value = Math.max(1, page + shift)
    if (value === page) return
    onChange(value)
    if (scroll) document.documentElement.scrollTop = 0
  })

  const onLeft = useLatestCallback(() => shiftPage(-1))
  const onRight = useLatestCallback(() => shiftPage(+1))

  return <Space gap={4}>
    <IconButton disabled={start || busy} action={onLeft}>
      <Icon size={16} name="keyboard_arrow_left" />
    </IconButton>
    <Space gap={8} center>
      <Typo size={13} line={16} medium>{page}</Typo>
    </Space>
    <IconButton disabled={end || busy} action={onRight}>
      <Icon size={16} name="keyboard_arrow_right" />
    </IconButton>
  </Space>
})
