import { makeAutoObservable, observable, runInAction } from 'mobx'
import { CHART_SERIES, ChartSerie, ChartSerieId, createSeries, SerieGroup } from 'saas/store/stat/ChartSerie'
import { api } from 'store/api'
import { StatRequest } from 'type/Statistics'
import { skipAsync } from 'util/async'

export class ChartStore {
  ready = false
  error = false
  private _series: ChartSerie[] = []
  group: SerieGroup = 'day'
  private selected = new Set<ChartSerieId>(CHART_SERIES)

  constructor() {
    makeAutoObservable<this, '_series'>(this, { _series: observable.ref })
  }

  get all(): ChartSerie[] {
    return this._series
  }

  get series(): ChartSerie[] {
    return this._series.filter(serie => this.checked(serie.id))
  }

  check(id: string, check: boolean) {
    if (check) this.selected.add(id)
    else this.selected.delete(id)
  }

  checked(id: string) {
    return this.selected.has(id)
  }

  reset() {
    this.ready = false
    this.error = false
    this._series = []
  }

  readonly update = skipAsync(async (request: StatRequest) => {
    this.reset()
    try {
      const response = await api.getSeries(request)
      const { series, group } = createSeries(response)
      runInAction(() => {
        this._series = series
        this.group = group
        this.ready = true
      })
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => this.error = true)
    }
  })
}

export const chart = new ChartStore()
