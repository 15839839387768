import { Tooltip as TooltipAntd } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import css from './Tooltip.module.scss'

type Props = {
  arrow?: boolean
  bottom?: boolean
  children: ReactNode
  className?: string
  left?: boolean
  placement?: TooltipPlacement
  raw?: boolean
  right?: boolean
  title?: ReactNode
  top?: boolean
}

function getOffset(placement: string, space: number): [number, number] {
  if (placement.startsWith('top')) {
    return [0, -space]
  } else if (placement.startsWith('bottom')) {
    return [0, space]
  } else if (placement.startsWith('left')) {
    return [-space, 0]
  } else if (placement.startsWith('right')) {
    return [space, 0]
  }
  console.error('unexpected placement', placement)
  return [0, 0]
}

function getPlacement(props: Props): TooltipPlacement {
  const { bottom, left, right, top, placement } = props
  return left ? 'left' : right ? 'right' : top ? 'top' : bottom ? 'bottom' : placement ?? 'top'
}

export function Tooltip(props: Props) {
  const { arrow, children, raw, title } = props
  const className = clsx(props.className, css.tooltip)
  const placement = getPlacement(props)
  const align = { offset: getOffset(placement, 8) }

  return <TooltipAntd rootClassName={className} title={title} placement={placement} align={align}
    transitionName={''} arrow={arrow ?? true} mouseLeaveDelay={0} mouseEnterDelay={0.200}>
    {raw ? children : <div className={css.child}>{children}</div>}
  </TooltipAntd>
}
