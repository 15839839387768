import css from 'component/Select/SearchCustomSelect.module.scss'
import { Select } from 'component/Select/Select'
import { SelectTag } from 'component/Select/SelectTag'
import { useFocus } from 'hook/useFocus'
import { useLatestCallback } from 'hook/useLatestCallback'
import { type BaseSelectRef, CustomTagProps } from 'rc-select/lib/BaseSelect'
import type { FlattenOptionData } from 'rc-select/lib/interface'
import { LabelInValueType } from 'rc-select/lib/Select'
import { ReactNode } from 'react'
import { matchSearch } from 'util/search'
import { ensureString } from 'util/string'
import { styles } from 'util/style'

export type SelectOption<Value = string, Data = undefined> = {
  className?: string
  disabled?: boolean
  label?: ReactNode
  options?: undefined
  value: Value
  data: Data
}

type Variations<Value, Data> = {
  single: true
  onChange?(value?: Value, option?: SelectOption<Value, Data>): void
  value: Value | undefined
} | {
  multiple: true
  onChange?(value: Value[], option: SelectOption<Value, Data>[]): void
  value: readonly Value[]
}

type Props<Value = string, Data = unknown> = {
  disabled?: boolean
  error?: boolean
  height?: number
  onFocus?(focus: boolean): void
  onRef?: (ref: BaseSelectRef | undefined) => void
  options: SelectOption<Value, Data>[]
  placeholder?: string
  renderOption?: (value: Value, option?: SelectOption<Value, Data>) => ReactNode
  renderLabel?: (value: Value, option?: SelectOption<Value, Data>) => ReactNode
  renderTag?: (value: Value, option?: SelectOption<Value, Data>) => ReactNode
  filterOption?: (search: string, option?: SelectOption<Value, Data>) => boolean
} & Variations<Value, Data>

function filterOptionDefault<Value, Data>(search: string, option: SelectOption<Value, Data> | undefined) {
  const label = ensureString(option?.label) ?? ensureString(option?.value)
  return matchSearch(search, [label])
}

export function SearchCustomSelect<Value, Data>(props: Props<Value, Data>) {
  const { disabled, error, height, onChange, onRef, options, placeholder, value } = props
  const mode = 'single' in props && props.single ? undefined : 'multiple'
  const status = error ? 'error' : undefined
  const focus = useFocus(props)
  const style = styles({ '--height': height ? height + 'px' : undefined })

  const optionRenderFunc = useLatestCallback((data: FlattenOptionData<unknown>) => {
    if (!props.renderOption) return undefined
    return props.renderOption(data.value as Value, data.data as SelectOption<Value, Data>)
  })

  const labelRenderFunc = useLatestCallback((data: LabelInValueType) => {
    if (!props.renderLabel) return undefined
    const option = options.find(option => option.value === value)
    return props.renderLabel(data.value as Value, option)
  })

  const tagRenderFunc = useLatestCallback((data: CustomTagProps) => {
    if (!props.renderTag) return undefined!
    const option = options.find(option => option.value === value)
    const label = props.renderTag(data.value, option)
    return <SelectTag {...data} className={css.item} label={label} />
  })

  const optionRender = props.renderOption && optionRenderFunc
  const labelRender = props.renderLabel && labelRenderFunc
  const tagRender = props.renderTag && tagRenderFunc
  const filterOption = props.filterOption ?? filterOptionDefault

  return <Select className={css.select} style={style} virtual={false} mode={mode} showSearch
    onRef={onRef} optionRender={optionRender} tagRender={tagRender} labelRender={labelRender}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wide={true} value={value as any} options={options as any} onChange={onChange as any}
    placeholder={placeholder} disabled={disabled} status={status} filterOption={filterOption}
    onBlur={focus.onBlur} onFocus={focus.onFocus} />
}
