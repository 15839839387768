import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { TextArea } from 'component/TextArea'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'

export const UploadStep = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const { text, done } = mod.uploadStep

  const onFile = useLatestCallback(async (file: File) => {
    text.value = await file.text()
  })

  const onChange = useLatestCallback((value: string) => {
    text.value = value
    text.touch = true
  })

  const error = text.error

  return <Vertical gap={24}>
    <Typo size={16} secondary>
      {i18n('catalog.UploadLinksToGoods')}
    </Typo>

    <Vertical gap={12}>
      <Space gap={16} wrap>
        <Upload button accept="csv" onFile={onFile}>
          {i18n('catalog.UploadCsv')}
        </Upload>

        <Vertical gap={4}>
          <Typo size={12} secondary one>
            {i18n('catalog.CsvLineFormat')}
          </Typo>
          <Typo size={12} secondary one>
            {i18n('catalog.FirstLineIsHeader')}
          </Typo>
        </Vertical>
      </Space>

      <TextArea mono rows={12} error={error} value={text.value} onChange={onChange} />
    </Vertical>

    <Space gap={12} right>
      <Button action={mod.onBack}>
        {i18n('common.GoBack')}
      </Button>
      <Button primary disabled={!done} action={mod.onNext}>
        {i18n('common.GoNext')}
      </Button>
    </Space>
  </Vertical>
})
