import { makeAutoObservable } from 'mobx'
import { loadFilters, saveFilters } from 'saas/page/generations/store/storage'
import { IntervalStore } from 'saas/shared/interval/IntervalStore'
import { mx } from 'store/base/mx'
import { me } from 'store/me'
import { FilterCollection, FilterKey, GenerationsRequestBase } from 'type/Generations'

export class FiltersStore {
  readonly company_id: string

  readonly keys: FilterKey[]
  readonly collections: FilterCollection[]

  private _key = ''
  private _collection = ''
  private _item = ''

  readonly interval = new IntervalStore()

  constructor(company_id: string, collections: FilterCollection[], keys: FilterKey[]) {
    makeAutoObservable(this)

    this.company_id = company_id
    this.keys = keys
    this.collections = collections
    this.reset()
  }

  get key(): string {
    return this._key
  }

  set key(value: string) {
    this._key = value
  }

  get collection(): string {
    return this._collection
  }

  set collection(value: string) {
    this._collection = value
  }

  get item(): string {
    return this._item
  }

  set item(value: string) {
    this._item = value
  }

  get changes(): GenerationsRequestBase | undefined {
    if (!me.present) return

    const { user_id } = me.user
    const { company_id } = this

    this.store()

    const from = this.interval.start.format('YYYY-MM-DD')
    const to = this.interval.end.add(1, 'day').format('YYYY-MM-DD')
    const api_key = this.key
    const collection_id = this.collection
    const item_id = this.item
    return { user_id, company_id, from, to, api_key, collection_id, item_id }
  }

  store() {
    const company_id = this.company_id
    const key = this.key
    const collection = this.collection
    const item = this.item
    const start = this.interval.start
    const end = this.interval.end
    saveFilters(company_id, { key, collection, item, start, end })
  }

  private reset() {
    const filters = loadFilters(this.company_id)
    this._key = filters.key ?? ''
    this._collection = filters.collection ?? ''
    this._item = filters.item ?? ''
    this.interval.start = filters.start
    this.interval.end = filters.end
  }
}

export const filters = mx.ref<FiltersStore>()
