import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable } from 'mobx'
import { ProductsConfigStore } from 'saas/store/products/ProductsConfigStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { mx } from 'store/base/mx'
import { ObjectStore } from 'store/base/ObjectStore'
import { StringStore } from 'store/base/StringStore'
import { route } from 'store/route'
import { Product } from 'type/product/Product'
import { ProductType, TRY_ON } from 'type/product/ProductType'
import { buildProductDraft } from 'type/product/utils'
import { fixText } from 'util/form'

export class ProductCreateStore {
  private readonly form = new FormStore()
  readonly type = this.form.field(new ObjectStore<ProductType>(TRY_ON), { required: true })
  readonly name = this.form.field(new StringStore(''), { required: true, fix: fixText })
  readonly config: ProductsConfigStore

  constructor(config: ProductsConfigStore, type?: ProductType) {
    makeAutoObservable(this)
    this.config = config
    this.type.value = type
  }

  get canSave(): boolean {
    return !this.form.error
  }

  check() {
    return this.form.check()
  }

  save() {
    if (!this.check()) return
    const { company_id } = productsSaas.it
    const product = this.buildProduct()
    const { product_id } = product
    void productsSaas.it.addProduct(product)
    route.push(`/company/${company_id}/product/${product_id}`)
    productCreate.close()
  }

  private buildProduct(): Product {
    const { company_id } = productsSaas.it
    const type = this.type.value
    if (!type) throw new Error('no type')
    return buildProductDraft(company_id, type)
  }
}

export const productCreate = mx.ref<ProductCreateStore>()
