import { Field } from 'component/Field'
import { InputColor } from 'component/InputColor'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const BackgroundColorInput = observer(() => {
  const { background, theme } = tryon.it.edit
  const isDarkTheme = theme === 'dark'
  const value = background || (isDarkTheme ? '#3D3D3D' : '#ffffff')

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.background = value.trim()
  })

  return (
    <Field label={i18n('widget.BackgroundColor')}>
      <InputColor
        mono
        lazy
        placeholder="#ffffff"
        value={value}
        onChange={onChange}
      />
    </Field>
  )
})
