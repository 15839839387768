import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { generationsHistory } from 'saas/shared/generations/hisory/GenerationsHistoryStore'
import { disk } from 'util/typo'
import css from './Dots.module.scss'

export const Dots = observer(() => {
  const store = generationsHistory.it
  const { generations, index } = store
  const len = generations.length

  const onLeft = useLatestCallback(() => {
    store.go(index - 1)
  })

  const onRight = useLatestCallback(() => {
    store.go(index + 1)
  })

  return <Space className={css.x} gap={4} center>
    <IconButton action={onLeft}>
      <Icon name="arrow_left_alt" />
    </IconButton>
    <Space gap={8} center>
      <Typo size={13} line={16} medium secondary={index >= len}>
        {index < len ? index + 1 : disk}
      </Typo>
      <Typo size={13} line={16} medium tertiary>/</Typo>
      <Typo size={13} line={16} medium tertiary>{len}</Typo>
    </Space>
    <IconButton action={onRight}>
      <Icon name="arrow_right_alt" />
    </IconButton>
  </Space>
})
