import { Checkbox as CheckboxAntd } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { clsx } from 'clsx'
import { CSSProperties, ReactNode } from 'react'
import { styles } from 'util/style'
import css from './Checkbox.module.scss'

type Props<Data> = {
  checked: boolean
  children?: ReactNode
  className?: string
  color?: string
  data?: Data
  disabled?: boolean
  error?: boolean
  indeterminate?: boolean
  onChange(checked: boolean, data: Data): void
  style?: CSSProperties
}

export const Checkbox = <Data = void, >(props: Props<Data>) => {
  const { checked, children, className, color, data, disabled, error, indeterminate } = props
  const classes = clsx(className, css.checkbox, color && css.color, error && css.error)
  const style = styles({ ...props.style, '--custom-color': color })

  const onChange = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    props.onChange(checked, data!)
  }

  return <CheckboxAntd className={classes} style={style} disabled={disabled}
    checked={checked} indeterminate={indeterminate} onChange={onChange}>
    {children}
  </CheckboxAntd>
}
