import { Checkbox } from 'component/Checkbox'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const CustomCheckbox = observer(() => {
  const { customSelfies } = tryon.it.edit.selfies

  const onChange = useLatestCallback((value: boolean) => {
    tryon.it.edit.selfies.customSelfies = value
  })

  return <Checkbox checked={customSelfies} onChange={onChange}>
    <Vertical gap={4}>
      <Typo size={13} medium>{i18n('tryon.CustomSelfieTemplates')}</Typo>
      <Typo size={11} secondary>{i18n('tryon.OtherwiseBuiltinTemplatesWillBeUsed')}</Typo>
    </Vertical>
  </Checkbox>
})
