// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemsTable-table-9073{width:100%;border-collapse:collapse;color:var(--ui-text-color);font-size:13px}.ItemsTable-table-9073 thead th{position:relative;padding:8px;font-weight:600;background-color:var(--ui-background-color-soft);height:40px}.ItemsTable-table-9073 thead th:first-child{border-top-left-radius:var(--ui-border-radius-half)}.ItemsTable-table-9073 thead th:last-child{border-top-right-radius:var(--ui-border-radius-half)}.ItemsTable-table-9073 thead th:nth-child(1){width:40px;min-width:40px}.ItemsTable-table-9073 thead th:nth-child(2){width:640px;min-width:640px}.ItemsTable-table-9073 thead th+th:before{content:"";position:absolute;width:1px;left:0;top:8px;bottom:8px;background-color:var(--ui-background-color-hover)}.ItemsTable-table-9073 tbody{opacity:1;transition:opacity 200ms ease-in-out}.ItemsTable-table-9073.ItemsTable-busy-9073 tbody{opacity:.3;transition:opacity 1000ms ease-in-out}.ItemsTable-table-9073 tbody td{padding:8px;color:var(--ui-text-color);height:40px;vertical-align:top}.ItemsTable-table-9073 tbody td:nth-child(n+1){border-top:solid 1px var(--ui-border-color-tertiary)}`, "",{"version":3,"sources":["webpack://./src/saas/page/tsum/co/table/ItemsTable.module.scss"],"names":[],"mappings":"AAAA,uBACE,UAAA,CACA,wBAAA,CACA,0BAAA,CACA,cAAA,CAEA,gCACE,iBAAA,CACA,WAAA,CACA,eAAA,CACA,gDAAA,CACA,WAAA,CAGF,4CACE,mDAAA,CAGF,2CACE,oDAAA,CAGF,6CACE,UAAA,CACA,cAAA,CAGF,6CACE,WAAA,CACA,eAAA,CAGF,0CACE,UAAA,CACA,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,iDAAA,CAGF,6BACE,SAAA,CACA,oCAAA,CAGF,kDACE,UAAA,CACA,qCAAA,CAGF,gCACE,WAAA,CACA,0BAAA,CACA,WAAA,CACA,kBAAA,CAGF,+CACE,oDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ItemsTable-table-9073`,
	"busy": `ItemsTable-busy-9073`
};
module.exports = ___CSS_LOADER_EXPORT___;
