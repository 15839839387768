import { Carousel } from 'antd'
import { CarouselRef } from 'antd/es/carousel'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { generationsHistory } from 'saas/shared/generations/hisory/GenerationsHistoryStore'
import { InferenceCard } from 'saas/shared/generations/hisory/InferenceCard'
import { disk } from 'util/typo'
import css from './InferenceCarousel.module.scss'

export const InferenceCarousel = observer(() => {
  const store = generationsHistory.it
  const { busy, error, generations, more, index } = store

  const onRef = useLatestCallback((ref: CarouselRef | null) => {
    store.ref = ref
  })

  const onAfterChange = useLatestCallback((value: number) => {
    store.index = value
  })

  return <Carousel ref={onRef} rootClassName={css.carousel}
    initialSlide={index} afterChange={onAfterChange}
    dots={false} arrows={false} infinite={false} speed={100}>
    {generations.map(it => <div key={it.key} className={css.item}>
      <InferenceCard generation={it} />
    </div>)}
    <Space className={css.item} height={420}>
      {error ? <Typo size={13} error>
        {i18n('common.Error')}
      </Typo> : busy ? <Spinner /> : more ? <Typo size={13} secondary>
        {disk}
      </Typo> : <Typo size={13} secondary>
        {i18n('search.NoMoreItems')}
      </Typo>}
    </Space>
  </Carousel>
})
