import { Checkbox } from 'component/Checkbox'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const TsumBrandCheckbox = observer(() => {
  const { tsum } = tryon.it.edit

  const onChange = useLatestCallback((value: boolean) => {
    tryon.it.edit.tsum = value
  })

  return <Checkbox checked={tsum} onChange={onChange}>
    {i18n('tryon.UseTsumBrandColorsAndFonts')}
  </Checkbox>
})
