import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { StatData } from 'saas/store/stat/Feedback'
import { formatNumber, formatPercent } from 'util/number'
import { disk } from 'util/typo'

type Props = {
  data: StatData
}

export const Info = observer(({ data }: Props) => {
  const empty = !data.count

  return <Vertical gap={2}>
    <Typo size={11} secondary>
      {data.label}
    </Typo>
    <Space gap={4}>
      <Typo size={11} bold tertiary={empty}>
        {formatNumber(data.count)}
      </Typo>
      {!empty && <Typo size={11} tertiary={empty}>
        {disk}
      </Typo>}
      {!empty && <Typo size={11} bold tertiary={empty}>
        {formatPercent(data.percent)}
      </Typo>}
    </Space>
  </Vertical>
})
