import { TryOnColor } from "type/product/TryOnProduct"

const light: TryOnColor = {
  primary: '#1F1F1F',
  text: '#1E1E1E7A',
  background: '#FFFFFF'
}

const dark: TryOnColor = {
  primary: '#1F1F1F',
  text: '#FFFFFF7A',
  background: '#3D3D3D'
}

export const ThemeColors = {
  light,
  dark,
}
