// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Segmented-segmented-ee5e{padding:6px;font-size:13px;font-weight:500}.Segmented-segmented-ee5e .ant-segmented-group{display:flex;align-items:center;gap:6px}.Segmented-segmented-ee5e .ant-segmented-item{display:flex;align-items:center;justify-content:center;gap:8px;flex:1 1 0;height:36px;overflow:hidden}.Segmented-segmented-ee5e .ant-segmented-item-label{display:flex;align-items:center;gap:8px}.Segmented-segmented-ee5e .ant-segmented-item-label>*{margin:0 !important;overflow:hidden;text-overflow:ellipsis}.Segmented-segmented-ee5e .ant-segmented-item-icon{display:contents}.Segmented-fit-ee5e .ant-segmented-item{flex:none}`, "",{"version":3,"sources":["webpack://./src/component/Segmented/Segmented.module.scss"],"names":[],"mappings":"AAAA,0BACE,WAAA,CACA,cAAA,CACA,eAAA,CAGE,+CACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,8CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CAGF,oDACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,sDACE,mBAAA,CACA,eAAA,CACA,sBAAA,CAIJ,mDACE,gBAAA,CAOF,wCACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segmented": `Segmented-segmented-ee5e`,
	"fit": `Segmented-fit-ee5e`
};
module.exports = ___CSS_LOADER_EXPORT___;
