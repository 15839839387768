import { clsx } from 'clsx'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { route } from 'store/route'
import css from './DashboardHeader.module.scss'

export const DashboardHeader = () => {
  const path = route.pathname
  const statistics = path === '/'
  const generations = path === '/generations'

  return <Space gap={24} className={css.header}>
    <Space className={css.line} />
    <Link path="/">
      <Space className={clsx(css.tab, statistics && css.active)}>
        <Typo size={24} semi>
          {i18n('title.Statistics')}
        </Typo>
      </Space>
    </Link>
    <Link path="/generations">
      <Space className={clsx(css.tab, generations && css.active)}>
        <Typo size={24} semi>
          {i18n('title.Generations')}
        </Typo>
      </Space>
    </Link>
  </Space>
}
