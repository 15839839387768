import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { FiltersCard } from 'saas/page/generations/co/FiltersCard'
import { SearchCard } from 'saas/page/generations/co/SearchCard'
import { generationsPageController } from 'saas/page/generations/GenerationsPageController'
import { usePageController } from 'store/base/page/usePageController'
import { home } from 'store/home'

export const GenerationsView = observer(() => {
  const company_id = home.company.optional?.company_id

  const stub = usePageController(generationsPageController, { company_id })
  if (stub) return stub

  return <Vertical gap={24}>
    <Vertical gap={16}>
      <Typo size={18} semi>
        {i18n('search.Filters')}
      </Typo>
      <FiltersCard />
    </Vertical>
    <SearchCard />
  </Vertical>
})
