import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { SkipStep } from 'modal/UploadCollectionModal/SkipStep'

export const NoItemIdStep = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const store = mod.data.it.no_item_id

  return <SkipStep store={store}>
    {i18n('catalog.FoundCountRowsWithNoItemId', { count: store.items.length })}
  </SkipStep>
})
