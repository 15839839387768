import { clsx } from 'clsx'
import { Space, SpaceProps } from 'component/Space'
import { ClickAction, useOnClickAction } from 'hook/useOnClickAction'
import { ForwardedRef, forwardRef } from 'react'
import { styles } from 'util/style'
import css from './Card.module.scss'

export type CardProps = SpaceProps & {
  action?: ClickAction | false
  active?: boolean
  error?: boolean
  ghost?: boolean
  medium?: boolean
  overflow?: boolean
  pale?: boolean
  panel?: boolean
  pic?: boolean
  secondary?: boolean
  shadow?: boolean
  small?: boolean
  success?: boolean
  tertiary?: boolean
}

export const Card = forwardRef(
  (props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      action,
      active,
      error,
      ghost,
      medium,
      overflow,
      pale,
      panel,
      pic,
      round,
      secondary,
      shadow,
      small,
      success,
      tertiary,
    } = props
    const { busy, onClick } = useOnClickAction(props)
    const type = ghost
      ? css.ghost
      : pale
      ? css.pale
      : panel
      ? css.panel
      : shadow
      ? css.shadow
      : css.border
    const radius = round == null ? undefined : round + 'px'
    const style = styles({ '--card-border-radius': radius })
    const className = clsx(
      props.className,
      css.card,
      type,
      action != null && css.action,
      busy && css.busy,
      active && css.active,
      medium && css.medium,
      small && css.small,
      secondary && css.secondary,
      tertiary && css.tertiary,
      error && css.error,
      success && css.success,
      overflow && css.overflow,
      pic && css.pic
    )

    return (
      <Space
        {...props}
        ref={ref}
        className={className}
        style={style}
        round={round}
        onClick={onClick}
      />
    )
  }
)
