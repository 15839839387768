// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenerationCard-like-db4c{width:24px;height:24px;border-radius:999px;background:rgba(255,255,255,.48)}`, "",{"version":3,"sources":["webpack://./src/saas/page/generations/co/GenerationCard.module.scss"],"names":[],"mappings":"AAAA,0BACE,UAAA,CACA,WAAA,CACA,mBAAA,CACA,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"like": `GenerationCard-like-db4c`
};
module.exports = ___CSS_LOADER_EXPORT___;
