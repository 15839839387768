import { makeAutoObservable } from 'mobx'
import { ArrayStore } from 'store/base/ArrayStore'
import { SetStore } from 'store/base/SetStore'
import { StringStore } from 'store/base/StringStore'
import { CollectionStore } from 'store/collection'
import { Collection } from 'type/Collection'
import { every } from 'util/iterable'
import { matchSearch } from 'util/search'
import { compareIgnoreCase, toLowerCase } from 'util/string'

function isVisible(collection: CollectionStore, search: string, tags: ReadonlySet<string>): boolean {
  const data = collection.data
  if (!matchSearch(search, [data.name])) return false

  const collectionTags = data.tags.map(toLowerCase)
  return every(tags, tag => collectionTags.includes(tag))
}

export class CollectionListStore {
  readonly collections = new ArrayStore<CollectionStore>([])
  readonly selectedTags = new SetStore<string>([])
  readonly search = new StringStore('')

  constructor(collections: readonly Collection[]) {
    this.collections.value = collections.map(json => CollectionStore.fromJson(json))
    makeAutoObservable(this)
  }

  findCollection(collectionId: string | undefined): CollectionStore | undefined {
    if (collectionId == null) return undefined
    return this.collections.find(store => store.collectionId === collectionId)
  }

  findRoute(collectionId: string | undefined): CollectionStore | undefined {
    if (collectionId == null) return undefined
    return this.collections.find(store => collectionId === store.collectionId)
  }

  get tags(): readonly string[] {
    const tags = this.collections.value.flatMap(collection => collection.data.tags)
    const map = new Map(tags.reverse().map(tag => [tag.toLowerCase(), tag]))
    return Array.from(map.values()).sort(compareIgnoreCase)
  }

  get collectionsVisible(): readonly CollectionStore[] {
    const search = this.search.value
    const tags = this.selectedTags.value
    const collections: ReadonlyArray<CollectionStore> = this.collections.value
    return collections.filter(collection => isVisible(collection, search, tags))
  }

  deleteCollection(collection: CollectionStore): void {
    this.collections.remove(collection)
    const tags = this.tags.map(toLowerCase)
    for (const tag of this.selectedTags.value) {
      if (!tags.includes(tag)) {
        this.selectedTags.remove(tag)
      }
    }
  }

  resetFilters(): void {
    this.selectedTags.clear()
    this.search.value = ''
  }
}
