import { animated, Interpolation, SpringValue } from '@react-spring/web'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { PieSerie } from 'saas/store/stat/Feedback'
import { formatPercent } from 'util/number'

type Props = {
  datum: {
    data: PieSerie
  }
  style: {
    progress: SpringValue<number>
    transform: Interpolation<string>
    textColor: string
  }
}

export const Label = observer((props: Props) => {
  const { datum, style } = props
  const label = formatPercent(datum.data.percent)

  const [width, setWidth] = useState(40)
  const [height, setHeight] = useState(20)

  const radius = 10
  const x = width / 2
  const y = height / 2

  const onRef = useLatestCallback((ref: SVGTextElement | null) => {
    if (!ref) return
    const rect = ref.getBoundingClientRect()
    setWidth(rect.width + radius)
    setHeight(rect.height + radius)
  })

  return <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
    <rect x={-x} y={-y} width={width} height={height} rx={radius} ry={radius} fill="#FFFFFFE0" />
    <text ref={onRef} textAnchor="middle" dominantBaseline="central"
      fill="#1E1E1EE0" style={{ fontSize: 9, fontWeight: 600 }}>
      {label}
    </text>
  </animated.g>
})
