import { useEffectOnce } from 'hook/useEffectOnce'
import { ReactElement, useEffect, useMemo } from 'react'
import { PageController } from 'store/base/page/PageController'
import { PageStore } from 'store/base/page/PageStore'

export function usePageController<P, D>(controller: PageController<P, D>, params: P): ReactElement | undefined {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  params = useMemo(() => params, [JSON.stringify(params)])
  const store = useMemo(() => new PageStore<P>(controller), [controller])
  useEffect(() => void store.open(params), [params, store])
  useEffectOnce(() => () => store.close())
  return store.stub
}
