import { Card } from 'component/Card'
import { Divider } from 'component/Space/Divider'
import { observer } from 'mobx-react-lite'
import { KeySection } from 'saas/page/company/manage/api/KeySection'
import { WebhookSection } from 'saas/page/company/manage/api/WebhookSection'

export const ApiKeysCard = observer(() => {
  return <Card vertical gap={16} pad={24}>
    <KeySection />
    <Divider />
    <WebhookSection />
  </Card>
})
