// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal-wrap-477f{height:fit-content;max-height:100vh;margin:auto}.Modal-modal-477f{top:0;padding-top:var(--ant-margin);min-width:min-content;min-height:min-content;padding-bottom:48px;margin:0 auto !important}@media(max-width: 767px){.Modal-modal-477f{padding-top:8px}}.Modal-modal-477f .ant-modal-content{padding:24px;border-radius:var(--ui-border-radius)}.Modal-modal-477f .ant-modal-close{--ant-font-size-lg: 14px;top:24px;right:24px;width:32px;height:32px;color:rgba(30,30,30,.88) !important;animation:600ms ease-in Modal-appear-477f;background-color:var(--ui-background-color) !important}@keyframes Modal-appear-477f{0%{opacity:0}50%{opacity:0}100%{opacity:1}}.Modal-modal-477f .ant-modal-close:hover,.Modal-modal-477f .ant-modal-close:focus{color:#1e1e1e !important}.Modal-modal-477f .ant-modal-header{margin-top:4px;margin-bottom:24px;padding-right:32px}.Modal-modal-477f .ant-modal-title{font-size:18px;font-weight:600}.Modal-modal-477f .ant-modal-body{display:flex;flex-direction:column;gap:24px}.ant-modal-wrap{-ms-overflow-style:none;scrollbar-width:none}.ant-modal-wrap::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/component/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA,iBACE,kBAAA,CACA,gBAAA,CACA,WAAA,CAGF,kBACE,KAAA,CACA,6BAAA,CACA,qBAAA,CACA,sBAAA,CACA,mBAAA,CACA,wBAAA,CAEA,yBARF,kBASI,eAAA,CAAA,CAGF,qCACE,YAAA,CACA,qCAAA,CAGF,mCACE,wBAAA,CAEA,QAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,mCAAA,CACA,yCAAA,CACA,sDAAA,CAEA,6BACE,GACE,SAAA,CAEF,IACE,SAAA,CAEF,KACE,SAAA,CAAA,CAIJ,kFACE,wBAAA,CAIJ,oCACE,cAAA,CACA,kBAAA,CACA,kBAAA,CAGF,mCACE,cAAA,CACA,eAAA,CAGF,kCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAIJ,gBACE,uBAAA,CACA,oBAAA,CAGF,mCACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Modal-wrap-477f`,
	"modal": `Modal-modal-477f`,
	"appear": `Modal-appear-477f`
};
module.exports = ___CSS_LOADER_EXPORT___;
