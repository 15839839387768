import { linearGradientDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { renderDateTick } from 'saas/page/statistics/chart/DateTick'
import { Tooltip } from 'saas/page/statistics/chart/Tooltip'
import { chart } from 'saas/store/stat/ChartStore'

export const Chart = observer(() => {
  const { series } = chart
  // const count = Math.max(10, series[0]?.data?.length || 0)
  // const width = count * 80

  return (
    <Vertical wide height={480} tall>
      <ResponsiveLine
        data={series}
        margin={{ top: 20, right: 20, bottom: 70, left: 60 }}
        xScale={{
          type: 'point',
        }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          nice: true,
          stacked: false,
        }}
        curve="monotoneX"
        tooltip={Tooltip}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 16,
          renderTick: renderDateTick,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 16,
          tickValues: 8,
        }}
        lineWidth={2}
        pointSize={10}
        pointColor={{ from: 'color' }}
        pointBorderWidth={2}
        pointBorderColor={{ theme: 'background' }}
        useMesh={true}
        enableCrosshair={true}
        enableTouchCrosshair={true}
        legends={[]}
        animate={false}
        crosshairType={'cross'}
        colors={(serie) => serie.color}
        enableArea={true}
        areaOpacity={1}
        defs={[
          linearGradientDef('gradient', [
            { offset: 0, color: 'inherit', opacity: 0.06 },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[{ match: '*', id: 'gradient' }]}
        theme={{
          background: 'var(--ui-background-color)',
          text: {
            fontFamily: 'inherit',
            fontSize: 13,
            textAnchor: 'end',
          },
          grid: {
            line: {
              stroke: 'var(--ui-text-color)',
              strokeOpacity: 0.1,
            },
          },
          crosshair: {
            line: {
              stroke: 'var(--ui-text-color)',
              strokeOpacity: 0.4,
              strokeDasharray: '4,4',
            },
          },
        }}
      />
    </Vertical>
  )
})
