import { Field } from 'component/Field'
import { Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { formatShortApiKey } from 'type/ApiKey'
import { emdash } from 'util/typo'

export const KeyFastSelect = observer(() => {
  const key = tryon.it.key

  const options = tryon.it.company.keys.map(key => ({
    value: key.key,
    label: <Space gap={16} wide opposite oh>
      <Typo size={13} mono ellipsis secondary={!key.value}>{key.name}</Typo>
      <Typo size={11} mono>{formatShortApiKey(key.value)}</Typo>
    </Space>,
  }))

  const onChange = useLatestCallback((value: string | undefined) => {
    void tryon.it.changeKey(value)
  })

  return <Field label={i18n('company.ApiKey')}>
    <Select value={key?.key} options={options}
      wide placeholder={emdash} onChange={onChange} />
  </Field>
})
