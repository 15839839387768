import { groupsSaas } from 'saas/store/groups/GroupsCache'
import { productCreate } from 'saas/store/product/ProductCreateStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { PageController } from 'store/base/page/PageController'
import { home } from 'store/home'

type Params = {
  company_id: string
}

type Data = {
  empty?: void
}

export class SaasProductsPageController implements PageController<Params, Data> {
  async load({ company_id }: Params): Promise<Data | undefined> {
    const company = home.selectCompany(company_id)
    if (!company) return
    await productsSaas.add(company_id).load()
    await groupsSaas.add(company_id).load()
    return {}
  }

  select(params: Params): void {
    productsSaas.select(params.company_id)
    groupsSaas.select(params.company_id)
  }

  close(): void {
    productCreate.close()
    productsSaas.close()
  }
}

export const saasProductsPageController = new SaasProductsPageController()
