import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { CustomCheckbox } from 'saas/page/product/tryon/SelfiesPanel/CustomCheckbox'
import { CustomSelfies } from 'saas/page/product/tryon/SelfiesPanel/CustomSelfies'
import { TemplateCheckbox } from 'saas/page/product/tryon/SelfiesPanel/TemplateCheckbox'
import { tryon } from 'saas/store/product/ProductSaasController'

export const SelfiesPanel = observer(() => {
  const { allowSelfieTemplate, customSelfies } = tryon.it.edit.selfies

  return <Vertical gap={8}>
    <TemplateCheckbox />
    {allowSelfieTemplate && <CustomCheckbox />}
    {customSelfies && <CustomSelfies />}
    {customSelfies && <CustomSelfies female />}
  </Vertical>
})
