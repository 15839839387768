import { Button } from 'component/Button'
import { LoadingPage } from 'feature/page/LoadingPage'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Page } from 'saas/screen/Page'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { can } from 'store/can'
import { route } from 'store/route'
import { CompanyCard } from './CompanyCard'
import { PageHeader } from 'component/PageHeader'
import { Icon } from 'icon/Icon'
import { CardContainer } from 'component/CardContainer'
import { ui } from 'store/home/ui/HomeUiStore'
import { modal } from 'modal'

export const CompaniesPage = observer(() => {
  const { mobile } = ui.window

  const [loading, setLoading] = useState(true)

  useEffectOnce(async () => {
    await companiesSaas.load()
    const company = companiesSaas.companies[0]
    if (company && companiesSaas.companies.length === 1) {
      const { company_id } = company
      const canView = can.ViewCompanyInformation(company_id)
      if (canView) route.replace(`/company/${company_id}/manage`)
    }
    setLoading(false)
  })

  const onCreate = useLatestCallback(() => {
    // route.push('/companies/create')
    modal.openCreateCompanyModal()
  })

  if (loading) return <LoadingPage />

  return (
    <Page>
      <PageHeader title={i18n('label.Companies')}>
        {can.CreateCompany() &&
          (mobile ? (
            <Button primary action={onCreate}>
              <Icon size={16} name="add_circle" />
            </Button>
          ) : (
            <Button
              icon={<Icon size={16} name="add_circle" />}
              primary
              action={onCreate}
            >
              {i18n('company.CreateNew')}
            </Button>
          ))}
      </PageHeader>
      <CardContainer>
        {companiesSaas.companies.map((co) => (
          <CompanyCard key={co.company_id} company={co} />
        ))}
      </CardContainer>
    </Page>
  )
})
