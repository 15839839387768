// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterText-action-99fe{background:rgba(30,30,30,.08);border-radius:4px;padding:2px 6px;color:rgba(30,30,30,.88);margin:-2px 0}`, "",{"version":3,"sources":["webpack://./src/saas/page/groups/components/FilterText.module.scss"],"names":[],"mappings":"AAAA,wBACE,6BAAA,CACA,iBAAA,CACA,eAAA,CACA,wBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action": `FilterText-action-99fe`
};
module.exports = ___CSS_LOADER_EXPORT___;
