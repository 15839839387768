import { Button } from 'component/Button'
import { PageHeader } from 'component/PageHeader'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Layout } from 'saas/page/company/manage/Layout'
import { CompanyDeleteModal } from 'saas/page/company/manage/modal/CompanyDeleteModal'
import { EditMemberModal } from 'saas/page/company/manage/modal/EditMemberModal/EditMemberModal'
import { KeyDeleteModal } from 'saas/page/company/manage/modal/KeyDeleteModal'
import { MemberDeleteModal } from 'saas/page/company/manage/modal/MemberDeleteModal'
import { KeyEditModal } from 'saas/page/company/modal/KeyEditModal/KeyEditModal'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { keyModal } from 'saas/store/key/ApiKeyModalStore'
import { memberEdit } from 'saas/store/MemberEditStore'
import { watermarkModal } from 'saas/store/watermark/WatermarkModalStore'
import { can } from 'store/can'
import { WatermarkEditModal } from './modal/WatermarkModals/WatermarkEditModal'
import { route } from 'store/route'

export const CompanyManageView = observer(() => {
  const { company_id, companyDeleting, keyDeleting, memberDeleting, canSave } =
    companyEdit.it
  const canEdit = can.EditCompanyInformation(company_id)

  const canDelete = can.DeleteCompany(company_id)

  const onDelete = useLatestCallback(async () => {
    companyEdit.it.companyDeleting = true
  })

  const onSave = useLatestCallback(async () => {
    await companyEdit.it.save()
  })

  return (
    <Vertical>
      <PageHeader
        title={i18n('company.ManageCompany')}
        onBack={() => route.push('/companies')}
        onBackTitle={i18n('company.backToCompanies')}
      >
        <Space gap={12}>
          {canEdit && (
            <Button primary disabled={!canSave} action={onSave}>
              {i18n('common.Save')}
            </Button>
          )}
          {canDelete && (
            <Button
              link
              error
              icon={<Icon size={16} name="delete" />}
              onClick={onDelete}
            >
              {i18n('company.DeleteCompany')}
            </Button>
          )}
        </Space>
      </PageHeader>
      <Layout />
      {companyDeleting && <CompanyDeleteModal />}
      {keyModal.present && <KeyEditModal />}
      {keyDeleting && <KeyDeleteModal />}
      {memberEdit.present && <EditMemberModal />}
      {memberDeleting && <MemberDeleteModal />}
      {watermarkModal.present && <WatermarkEditModal />}
    </Vertical>
  )
})
