import { Dayjs } from 'dayjs'
import { makeAutoObservable } from 'mobx'
import { all, getIntervalName, month, quarter, today, week } from 'util/dayjs'
import { nil } from 'util/null'

export class IntervalStore {
  private _start: Dayjs = week()
  private _end: Dayjs = today()

  constructor() {
    makeAutoObservable(this)
  }

  get name() {
    return getIntervalName(this.start, this.end)
  }

  get start(): Dayjs {
    return this._start
  }

  set start(value: Dayjs | nil) {
    const start = value ?? today()
    if (start.isSame(this.start)) return
    this._start = start
  }

  get end(): Dayjs {
    return this._end ?? today()
  }

  set end(value: Dayjs | nil) {
    const end = value ?? today()
    if (end.isSame(this.end)) return
    this._end = value ?? end
  }

  get value(): [Dayjs, Dayjs] {
    return [this.start, this.end]
  }

  get today(): boolean {
    return this.name === 'today'
  }

  set today(_: true) {
    this.start = today()
    this.end = today()
  }

  get week(): boolean {
    return this.name === 'week'
  }

  set week(_: true) {
    this.start = week()
    this.end = today()
  }

  get month(): boolean {
    return this.name === 'month'
  }

  set month(_: true) {
    this.start = month()
    this.end = today()
  }

  get quarter(): boolean {
    return this.name === 'quarter'
  }

  set quarter(_: true) {
    this.start = quarter()
    this.end = today()
  }

  get all(): boolean {
    return this.name === 'all'
  }

  set all(_: true) {
    this.start = all()
    this.end = today()
  }

  get by_hours(): boolean {
    const DAY = 24 * 60 * 60 * 1000
    const diff = this.end.add(1, 'day').diff(this.start)
    const days = diff / DAY
    return days < 7
  }
}
