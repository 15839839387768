import { CopyButton } from 'component/Button/CopyButton'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Dots } from 'saas/shared/generations/hisory/Dots'
import { generationsHistory } from 'saas/shared/generations/hisory/GenerationsHistoryStore'
import { InferenceCarousel } from 'saas/shared/generations/hisory/InferenceCarousel'

export const GenerationsHistoryModal = observer(() => {
  const title = i18n('statistics.HistoryOfGenerations')

  const { item_id, item_name, brand_name } = generationsHistory.it

  const onCancel = useLatestCallback(() => {
    generationsHistory.close()
  })

  return <Modal width={800} onCancel={onCancel} title={title}>
    <Vertical>
      <Vertical>
        <Typo line={20} size={13} semi secondary>
          {brand_name}
        </Typo>
        <Typo line={15} size={15} semi>
          {item_name}
        </Typo>
        <Gap height={4} />
        <Space gap={4}>
          <Space>
            <Typo line={1.5} size={11} secondary ellipsis>
              {i18n('label.ItemId')}
            </Typo>
          </Space>
          <Space gap={4} oh>
            <Typo line={1.5} size={11} ellipsis>{item_id}</Typo>
            <CopyButton data={item_id} />
          </Space>
        </Space>
      </Vertical>
      <Gap height={16} />
      <Space margin={[0, -24]} pad={[0, 24]} oh>
        <InferenceCarousel />
      </Space>
      <Gap height={24} />
      <Dots />
    </Vertical>
  </Modal>
})
