import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { LoadingPage } from 'feature/page/LoadingPage'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Title } from 'page/internal/collections/Title'
import { View } from 'page/internal/collections/View'
import { useEffect } from 'react'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { Page2 } from 'saas/screen/page/Page2/Page2'
import { saas } from 'saas/store/SaasController'
import { home } from 'store/home'
import { route } from 'store/route'

export const CompanyPage = observer(() => {
  const company_id = route.unit(1)
  const company = home.companies.companies.find(co => co.company_id === company_id)

  useEffect(() => {
    if (!company) return
    void saas.initCompany(company)
  }, [company])

  if (!company) return <NotFoundPage />
  if (!home.company.present) return <LoadingPage />

  return <Page2>
    <Title />
    <Vertical gap={24}>
      <Typo size={13}>
        {i18n('collection.CollectionsDefinition')}
      </Typo>
      <View />
    </Vertical>
  </Page2>
})
