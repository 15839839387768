import { FieldStore } from 'form/store/FieldStore'
import { i18n } from 'i18n'
import { makeAutoObservable, observable } from 'mobx'
import { StringStore } from 'store/base/StringStore'
import { StandProduct } from 'type/product/StandProduct'
import { fixText } from 'util/form'

function validateAlias(value: string): string {
  if (!value) return ''
  if (!/^[a-z0-9-]+$/i.test(value)) return i18n('stand.AllowedCharactersLatinLettersNumbersHyphen')
  if (value.length < 3) return i18n('stand.MinimumLength')
  if (value.length > 50) return i18n('stand.MaximumLength')
  return ''
}

export class AliasStore {
  readonly alias = new FieldStore(new StringStore(), { fix: fixText })
  private _json: StandProduct
  private _error = ''

  constructor(json: StandProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this.applyJson(json)
  }

  set json(value: StandProduct) {
    this._json = value
  }

  get error(): string {
    return this._error
  }

  set error(value: string) {
    this._error = value
  }

  get changed(): boolean {
    const prev = this._json.alias || ''
    return this.alias.value !== prev
  }

  check() {
    this._error = validateAlias(this.alias.value)
    return !this._error
  }

  private applyJson(json: StandProduct) {
    this.alias.value = json.alias || ''
  }
}
