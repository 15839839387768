import { Button } from 'component/Button'
import { IconButton } from 'component/Button/IconButton'
import { Link } from 'component/Link'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { can } from 'store/can'
import { home } from 'store/home'
import { ui } from 'store/home/ui/HomeUiStore'
import { compact } from 'util/array'
import { formatDateTime, parseDate } from 'util/date'
import { disk, emdash } from 'util/typo'

const ADD = false

export const Title = observer(() => {
  const w320 = ui.window.width < 320
  const { company_id } = home
  const collection = home.collection.it
  const { name, json } = collection.data
  const { created_at, updated_at } = json
  const created = parseDate(created_at)
  const updated = parseDate(updated_at)
  const canEdit = can.EditCollectionInformation(company_id)
  const canAdd = can.AddAndEditProducts(company_id)

  const items: DropdownItem[] = compact([
    canEdit && {
      key: 'edit',
      icon: <Icon size={16} name="edit" />,
      label: i18n('collection.EditCollection'),
      action() {
        void modal.openEditCollectionModal(collection)
      },
    },
    canAdd && {
      key: 'upload',
      icon: <Icon size={16} name="upload_file" />,
      label: i18n('catalog.UploadCatalog'),
      action() {
        void modal.openUploadCollectionModal(collection)
      },
    },
  ])

  const more = !!items.length
  const allowed = canAdd && !!items.length

  return <Space gap={16} opposite wrap>
    <Vertical oh>
      <Link path={`/company/${company_id}`} small>
        <Space gap={8} oh>
          <Icon size={16} name="arrow_back" />
          <Typo size={13} ellipsis>{i18n('collection.ToListOfCollections')}</Typo>
        </Space>
      </Link>
      <Gap height={12} />
      <Typo size={23} line={28} bold wrap>{name}</Typo>
      <Gap height={4} />
      <Typo size={11} secondary>
        <Space gap={4} wrap>
          <Space gap={[4, 4]} wrap oh>
            <Typo ellipsis>{i18n('label.CreatedAt')}</Typo>
            <Typo>{formatDateTime(created) ?? emdash}</Typo>
          </Space>
          {w320 || <Typo>{disk}</Typo>}
          <Space gap={[4, 4]} wrap oh>
            <Typo ellipsis>{i18n('label.LastUpdate')}</Typo>
            <Typo>{formatDateTime(updated) ?? emdash}</Typo>
          </Space>
        </Space>
      </Typo>
    </Vertical>
    {allowed && <Space gap={8} wrap>
      {ADD && canAdd && <Button primary action={collection.createItem.action}>
        <Icon size={16} name="add_circle" />
        {w320 || i18n('item.AddItem')}
      </Button>}
      {more && <Dropdown items={items} placement={'bottomRight'}>
        <IconButton large>
          <Icon name="more_horiz" />
        </IconButton>
      </Dropdown>}
    </Space>}
  </Space>
})
