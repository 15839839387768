import { Generation } from 'type/Generations'
import { getNextKey } from 'util/id'

export class GenerationStore {
  readonly key = getNextKey()
  readonly json: Generation

  constructor(json: Generation) {
    this.json = json
  }
}
