import { Checkbox } from 'component/Checkbox'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const TemplateCheckbox = observer(() => {
  const { allowSelfieTemplate } = tryon.it.edit.selfies

  const onChange = useLatestCallback((value: boolean) => {
    tryon.it.edit.selfies.allowSelfieTemplate = value
  })

  return <Checkbox checked={allowSelfieTemplate} onChange={onChange}>
    <Vertical gap={4}>
      <Typo size={13} medium>{i18n('tryon.AllowDefaultSelfies')}</Typo>
      <Typo size={11} secondary>{i18n('tryon.UsersCanChooseOneOfTheDefaultOptions')}</Typo>
    </Vertical>
  </Checkbox>
})
