import { app_name } from 'app/config/constants'
import { AppSvg } from 'app/config/icon'
import { getAppTitle, getDomainTitle } from 'app/config/title'
import { ErrorPage } from 'feature/page/ErrorPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ConnectButton } from 'page/login/ConnectButton'
import { LoginStore } from 'page/login/LoginStore'
import { useMemo } from 'react'
import css from './Login.module.scss'

export const Login = observer(() => {
  const store = useMemo(() => new LoginStore(), [])
  const saas = app_name === 'SAAS'

  const { error, connections } = store
  const year = new Date().getFullYear()

  if (error) return <ErrorPage />
  if (!connections) return <LoadingPage />

  return (
    <div className={css.container}>
      <div className={css.aside}>
        <div />
      </div>
      <div className={css.content}>
        <div className={css.inner}>
          <div className={css.logo}>
            {saas && <div className={css.image}>
              <AppSvg />
            </div>}
            <h1>{saas ? 'SAAS' : getAppTitle()}</h1>
          </div>
          <div className={css.connections}>
            {connections.map((connection, index) => {
              const { name } = connection
              return <ConnectButton key={name} index={index} connection={connection} />
            })}
            <a className={css.buttonForget} href="https://dresscode.ai/support" target="_blank">
              {i18n('login.CannotSignIn')}
            </a>
          </div>
          <div className={css.divider} />
          <div className={css.privacy}>
            {i18n('login.SignInAgreement', { a: <a href="" /> })}
          </div>
          <div className={css.copyright}>
            &copy; {getDomainTitle()}, {year}
          </div>
        </div>
      </div>
    </div>
  )
})
