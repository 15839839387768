import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { GroupsTable } from 'saas/page/groups/components/GroupsTable'
import { Title } from 'saas/page/groups/components/Title'

export const SaasGroupsView = observer(() => {
  return (
    <Vertical>
      <Title />
      <Vertical gap={24}>
        <Typo size={13}>{i18n('group.ProductGroupsDefinition')}</Typo>
        <GroupsTable />
      </Vertical>
    </Vertical>
  )
})
