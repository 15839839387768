import { Affix as AffixAntd } from 'antd'
import { ReactNode } from 'react'

type Props = {
  bottom?: number
  children: ReactNode
  top?: number
}

export const Affix = ({ bottom, children, top }: Props) => {
  return <AffixAntd offsetTop={top} offsetBottom={bottom}>
    <div>{children}</div>
  </AffixAntd>
}
