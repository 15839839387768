import { OverlayScrollbars } from 'overlayscrollbars'

let os: OverlayScrollbars

function update() {
  const state = os.state()
  const root = document.documentElement
  const scroll = root.clientHeight < root.scrollHeight
  const y = scroll ? 'scroll' : 'hidden'
  const force = state.overflowStyle.y !== y
  if (force) os.update(true)
  setTimeout(update, force ? 1000 : 100)
}

export function scroll(): void {
  os = OverlayScrollbars({
    target: document.body,
    cancel: { nativeScrollbarsOverlaid: true },
  }, {
    scrollbars: { theme: '' },
    update: { elementEvents: [['.os-transitionend', 'transitionend']] },
  }, {})

  setTimeout(update, 1000)
}
