import { Checkbox } from 'component/Checkbox'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tsum } from 'saas/page/tsum/store/TsumDbStore'

export const HeaderRow = observer(() => {
  const { items, selected } = tsum.it
  const size = items.length
  const all = size > 0 && selected === size
  const weak = selected > 0 && selected < size

  const onSelectAll = useLatestCallback((value: boolean) => {
    tsum.it.all = value
  })

  return <tr>
    <th>
      <Checkbox checked={all} indeterminate={weak} disabled={!size} onChange={onSelectAll} />
    </th>
    <th>
      {i18n('tsum.Images')}
    </th>
    <th>
      {i18n('tsum.Details')}
    </th>
  </tr>
})
