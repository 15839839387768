import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Switch } from 'component/Switch/Switch'
import { Typo } from 'component/Typo'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { SearchContent } from 'saas/page/generations/co/SearchContent'
import { TimeoutInput } from 'saas/page/generations/co/TimeoutInput'
import { generations } from 'saas/page/generations/store/GenerationsStore'

export const SearchCard = observer(() => {
  const store = generations.it
  const { busy } = store

  const onAuto = useLatestCallback((value: boolean) => {
    store.autoupdate = value
  })

  const onUpdate = useLatestCallback(() => {
    generations.it.update()
  })

  useEffectOnce(() => {
    function listener() {
      if (!store.more || store.busy) return

      const root = document.documentElement
      const bottom = root.scrollTop + root.clientHeight === root.scrollHeight

      if (bottom) void store.next()
    }

    document.addEventListener('scroll', listener)
    return () => document.removeEventListener('scroll', listener)
  })

  return <Card vertical pad={16} gap={16}>
    <Space opposite>
      <Space>
      </Space>
      <Space gap={16} wrap>
        <Space gap={8}>
          <Switch checked={store.autoupdate} onChange={onAuto} />
          <Typo size={13}>
            {i18n('search.Autoupdate')}
          </Typo>
        </Space>
        <TimeoutInput />
        <Button primary disabled={busy} action={onUpdate} icon={<Icon size={16} name="sync" />}>
          {i18n('search.Update')}
        </Button>
      </Space>
    </Space>
    <Divider secondary />
    <div>
      <SearchContent />
    </div>
  </Card>
})
