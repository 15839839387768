// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductCard-productCard-72d4{height:100%}.ProductCard-bottom-72d4{border-top:1px solid var(--ui-border-color-secondary)}.ProductCard-typeStand-72d4{background-color:rgba(23,167,162,.0392156863);--card-border-color: #17A7A23D}.ProductCard-typeStand-72d4:hover{--card-border-color: var(--ui-product-type-stand) !important}.ProductCard-typeStand-72d4:hover .ProductCard-standLink-72d4{display:block}.ProductCard-typeTryOn-72d4{background-color:rgba(154,59,186,.0392156863);--card-border-color: #9A3BBA3D}.ProductCard-typeTryOn-72d4:hover{--card-border-color: var(--ui-product-type-tryOn) !important}.ProductCard-typeBot-72d4{background-color:rgba(23,156,222,.0392156863);--card-border-color: #179CDE3D}.ProductCard-typeBot-72d4:hover{--card-border-color: var(--ui-product-type-bot) !important}.ProductCard-copyButton-72d4{display:none;margin:-1px 0}.ProductCard-apiKey-72d4{color:var(--ui-text-color-secondary)}.ProductCard-apiKey-72d4:hover{color:var(--ui-text-color)}.ProductCard-apiKey-72d4:hover .ProductCard-copyButton-72d4{display:block}.ProductCard-link-72d4{font-size:11px;line-height:1.2;color:var(--ui-primary-color)}.ProductCard-params-72d4{margin-top:auto}.ProductCard-image-72d4 img{display:block;width:100%;height:100%;object-fit:contain}`, "",{"version":3,"sources":["webpack://./src/saas/page/products/components/ProductCard.module.scss"],"names":[],"mappings":"AAAA,8BACE,WAAA,CAGF,yBACE,qDAAA,CAGF,4BACE,6CAAA,CACA,8BAAA,CAGF,kCACE,4DAAA,CAEA,8DACE,aAAA,CAIJ,4BACE,6CAAA,CACA,8BAAA,CAGF,kCACE,4DAAA,CAGF,0BACE,6CAAA,CACA,8BAAA,CAGF,gCACE,0DAAA,CAGF,6BACE,YAAA,CACA,aAAA,CAGF,yBACE,oCAAA,CAGF,+BACE,0BAAA,CAEA,4DACE,aAAA,CAIJ,uBACE,cAAA,CACA,eAAA,CACA,6BAAA,CAGF,yBACE,eAAA,CAKA,4BACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productCard": `ProductCard-productCard-72d4`,
	"bottom": `ProductCard-bottom-72d4`,
	"typeStand": `ProductCard-typeStand-72d4`,
	"standLink": `ProductCard-standLink-72d4`,
	"typeTryOn": `ProductCard-typeTryOn-72d4`,
	"typeBot": `ProductCard-typeBot-72d4`,
	"copyButton": `ProductCard-copyButton-72d4`,
	"apiKey": `ProductCard-apiKey-72d4`,
	"link": `ProductCard-link-72d4`,
	"params": `ProductCard-params-72d4`,
	"image": `ProductCard-image-72d4`
};
module.exports = ___CSS_LOADER_EXPORT___;
