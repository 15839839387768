import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Switch } from 'component/Switch/Switch'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'
import css from './Banner.module.scss'

type Props = {
  type: 'mobile' | 'desktop'
}

export const Banner = observer(({ type }: Props) => {
  const { edit } = stand.it
  const desk = type === 'desktop'
  const enabled = desk ? edit.desktopBannerEnabled : edit.mobileBannerEnabled
  const image = desk ? edit.desktopBanner : edit.mobileBanner
  const label = desk ? i18n('stand.DesktopBanner') : i18n('stand.MobileBanner')
  const { busy, src } = image

  const onEnable = useLatestCallback((value: boolean) => {
    if (desk) edit.desktopBannerEnabled = value
    else edit.mobileBannerEnabled = value
  })

  const onFile = useLatestCallback((file: File) => {
    image.file = file
  })

  const onDelete = useLatestCallback(() => {
    if (desk) edit.desktopBannerEnabled = false
    else edit.mobileBannerEnabled = false
    image.value = undefined
  })

  return <Card vertical gap={12} pad={12}>
    <Space gap={8} opposite>
      <Space gap={8}>
        <Switch checked={enabled} onChange={onEnable} />
        <Typo size={13} semi>
          {label}
        </Typo>
        {busy && <Spinner size={16} />}
      </Space>
      <IconButton action={onDelete}>
        <Icon size={16} name="delete" />
      </IconButton>
    </Space>

    <Space spread oh>
      <Upload active={!!src} accept="image" onFile={onFile}>
        {!src && <Space height={96} wide center>
          <Space gap={8}>
            <Card width={32} height={32} round={40} panel center>
              <Icon size={16} name="upload" secondary />
            </Card>
            <Vertical gap={4} left>
              <Typo size={11} first>{desk ? i18n('stand.DesktopBannerUpload') : i18n('stand.MobileBannerUpload')}</Typo>
              <Typo size={11} secondary>JPG, PNG</Typo>
            </Vertical>
          </Space>
        </Space>}
        {src && <img className={clsx(css.image, busy && css.pale)} src={src} />}
      </Upload>
    </Space>
  </Card>
})
