import { CopyIconButton } from 'component/Button/CopyIconButton'
import { IconButton } from 'component/Button/IconButton'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { can } from 'store/can'
import { formatShortApiKey } from 'type/ApiKey'
import { compact } from 'util/array'
import { formatDate } from 'util/date'
import { emdash } from 'util/typo'
import css from './KeyBox.module.scss'

type Props = {
  store: ApiKeyStore
}

export const KeyBox = observer(({ store }: Props) => {
  const { company_id } = companyEdit.it
  const { name, value, created_at, readonly } = store
  const good = !!value && !readonly

  const actions: DropdownItem[] = compact([
    {
      key: 'edit',
      label: i18n('common.Edit'),
      action() {
        companyEdit.it.openKeyEditModal(store)
      },
    },
    {
      key: 'delete',
      label: i18n('common.Delete'),
      action() {
        companyEdit.it.keyDeleting = store
      },
    },
  ])

  return <Space gap={[8, 16]} pad={4} wide wrap className={css.keyBox}>
    <Space height={20} minWidth={120} flex="1">
      <Typo size={13} ellipsis mono secondary={!name || !good}>
        {name || emdash}
      </Typo>
    </Space>
    <Space minWidth={80} margin={[0, 0, -2, 0]}>
      <Typo size={11} secondary>
        {formatDate(created_at) ?? emdash}
      </Typo>
    </Space>
    <Space gap={8} wrap>
      <Typo size={13} mono balance>
        {formatShortApiKey(value)}
      </Typo>
      <Space>
        <CopyIconButton data={value} disabled={!value} />
        {good && can.ManageApiKeys(company_id) && <Dropdown items={actions} placement={'bottomRight'}>
          <IconButton>
            <Icon name="more_horiz" size={16} />
          </IconButton>
        </Dropdown>}
      </Space>
    </Space>
  </Space>
})
