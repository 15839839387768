// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageHeader-pageHeader-9874{border-bottom:1px solid #e4e4e4;margin-bottom:24px;padding:13px 0 26px}.PageHeader-backIcon-9874{transition:transform .15s ease-in-out}.PageHeader-back-9874{cursor:pointer;transition:opacity .15s ease-in-out}.PageHeader-back-9874:hover{opacity:.8}.PageHeader-back-9874:hover .PageHeader-backIcon-9874{transform:translateX(-4px)}`, "",{"version":3,"sources":["webpack://./src/component/PageHeader/PageHeader.module.scss"],"names":[],"mappings":"AAAA,4BACE,+BAAA,CACA,kBAAA,CACA,mBAAA,CAGF,0BACE,qCAAA,CAGF,sBACE,cAAA,CACA,mCAAA,CAEA,4BACE,UAAA,CAEA,sDACE,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `PageHeader-pageHeader-9874`,
	"backIcon": `PageHeader-backIcon-9874`,
	"back": `PageHeader-back-9874`
};
module.exports = ___CSS_LOADER_EXPORT___;
