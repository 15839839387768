import { i18n } from 'i18n'
import { FeedbackResponse, TotalsResponse } from 'type/Statistics'
import { by } from 'util/sort'

export const NONE_COLOR = '#1E1E1E14'
export const LIKE_COLOR = '#36BF91'
export const DISLIKE_COLOR = '#F22C4D'

export const EMPTY_SERIES: PieSerie[] = [
  {
    label: '',
    color: NONE_COLOR,
    count: 0,
    percent: 100,
  },
]

export type StatData = {
  label: string
  count: number
  percent: number
}

type ColorStatData = StatData & {
  color: string
}

export type FeedbackData = ColorStatData
export type PieSerie = ColorStatData

function getBadColor(index: number, length: number): string {
  if (length < 2) return DISLIKE_COLOR

  const percent = 20 + 80 * index / (length - 1)
  const alpha = 255 * percent / 100
  const hex = alpha.toString(16).padStart(2, '0')
  return DISLIKE_COLOR + hex
}

function buildBadData(totals: TotalsResponse, feedback: FeedbackResponse): ColorStatData[] {
  const likes = totals.likes || 0
  const dislikes = totals.dislikes || 0
  const total = likes + dislikes
  const known = feedback.abuse.reduce((sum, it) => sum + (it.count || 0), 0)

  const items: StatData[] = feedback.abuse.map(it => createFeedbackRaw(it.reason, it.count, total))
  const other: StatData = createFeedbackRaw(i18n('chart.UnknownReason'), Math.max(0, dislikes - known), total)

  const raw = [other, ...items].filter(it => it.count > 0).sort(by(it => it.count))
  const result = raw.map((it, index) => ({
    ...it,
    color: getBadColor(index, raw.length),
  }))
  return result
}

function createFeedbackRaw(label: string, count: number, total: number): StatData {
  const percent = total > 0 ? 100 * count / total : 0
  return { label, count, percent }
}

export function buildFeedbacks(totals: TotalsResponse, feedback: FeedbackResponse): FeedbackData[] {
  const bad = buildBadData(totals, feedback)
  return bad.reverse()
}

export function buildSeries(totals: TotalsResponse, feedback: FeedbackResponse): PieSerie[] {
  const bad = buildBadData(totals, feedback)

  const likes = totals.likes || 0
  const dislikes = totals.dislikes || 0
  const total = likes + dislikes

  const good: PieSerie = {
    label: i18n('chart.Likes'),
    color: LIKE_COLOR,
    count: likes,
    percent: total > 0 ? 100 * likes / total : 0,
  }

  const series = [good, ...bad]
  return series.filter(it => it.count > 0)
}

export function buildLikes(totals: TotalsResponse, like: boolean): StatData {
  const label = like ? i18n('chart.Likes') : i18n('chart.Dislikes')
  const likes = totals.likes ?? 0
  const dislikes = totals.dislikes ?? 0
  const count = like ? likes : dislikes
  const total = likes + dislikes
  const percent = Math.round(total > 0 ? 100 * count / total : 0)
  return { label, count, percent }
}
