// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardContainer-cardContainer-ebfb{display:grid;gap:12px}@media all and (min-width: 600px){.CardContainer-cardContainer-ebfb{grid-template-columns:repeat(2, 1fr);gap:16px}}@media all and (min-width: 900px){.CardContainer-cardContainer-ebfb{grid-template-columns:repeat(3, 1fr)}}@media all and (min-width: 1200px){.CardContainer-cardContainer-ebfb{grid-template-columns:repeat(4, 1fr)}}@media all and (min-width: 1500px){.CardContainer-cardContainer-ebfb{grid-template-columns:repeat(5, 1fr)}}`, "",{"version":3,"sources":["webpack://./src/component/CardContainer/CardContainer.module.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,QAAA,CAEA,kCAJF,kCAKI,oCAAA,CACA,QAAA,CAAA,CAGF,kCATF,kCAUI,oCAAA,CAAA,CAGF,mCAbF,kCAcI,oCAAA,CAAA,CAGF,mCAjBF,kCAkBI,oCAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `CardContainer-cardContainer-ebfb`
};
module.exports = ___CSS_LOADER_EXPORT___;
