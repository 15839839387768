import { Field } from 'component/Field'
import { Option, Select } from 'component/Select/Select'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import type { BaseSelectRef } from 'rc-select/lib/BaseSelect'
import { bot } from 'saas/store/product/ProductSaasController'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { getProductTypeTitle, STAND } from 'type/product/ProductType'

function getError(product_id: string | undefined): string | undefined {
  if (!product_id) return
  const product = productsSaas.it.products.find(
    (it) => it.product_id === product_id
  )
  if (!product) return i18n('product.ProductNotFound')
  const { type, published } = product.json
  if (type !== STAND)
    return i18n('product.ProductType') + ' ' + getProductTypeTitle(type)
  if (!published) return i18n('product.NotPublished')
}

export const StandSelect = observer(() => {
  const store = bot.it.edit
  const value = store.window
  const products = productsSaas.it.products.filter(
    (it) => it.json.type === STAND && it.json.published
  )
  const empty = products.length ? undefined : i18n('product.NoProducts')
  const error = getError(value) || empty
  const disabled = !!empty && !value
  const bad = store.changed && !!error

  const options: Option[] = [
    ...products.map((product) => ({
      value: product.product_id,
      label: product.json.name,
    })),
  ]

  const onChange = useLatestCallback((value: string | undefined) => {
    store.window = value || ''
  })

  const onRef = useLatestCallback((element: BaseSelectRef | undefined) => {
    store.standRef = element
  })

  return (
    <Vertical gap={6}>
      <Field label={i18n('product.QrCatalog')}>
        <Select
          value={value}
          options={options}
          disabled={disabled}
          error={bad}
          wide
          onChange={onChange}
          onRef={onRef}
          showAction={['focus']}
        />
      </Field>
      {error && (
        <Typo size={11} error>
          {error}
        </Typo>
      )}
    </Vertical>
  )
})
