import { SearchCustomSelect, SelectOption } from 'component/Select/SearchCustomSelect'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { statistics } from 'saas/store/stat/StatisticsStore'
import { ApiKey } from 'type/ApiKey'

export const ApiKeySelect = observer(() => {
  const { key, keys } = statistics
  const disabled = !keys.length

  const options: SelectOption<string, ApiKey>[] = keys.map((key, index) => ({
    key: index,
    value: key.key_name,
    data: key,
  }))

  const onChange = useLatestCallback((value?: string) => {
    statistics.key = value
  })

  const renderOption = useLatestCallback((value: string) => {
    const key = keys.find(key => key.key_name === value)
    if (!key) return value
    return <Space pad={[0, 8]} height={38}>
      <Vertical oh>
        <Typo size={13} line={1.2} medium ellipsis>{key.key_name}</Typo>
        <Typo size={10} line={1.2} medium secondary ellipsis>{key.access_key}</Typo>
      </Vertical>
    </Space>
  })

  return <SearchCustomSelect single value={key} options={options} onChange={onChange}
    height={50} disabled={disabled} placeholder={i18n('statistics.AnyApiKey')}
    renderOption={renderOption} renderLabel={renderOption} />
})
