import { SearchSelect, SelectOption } from 'component/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tsum } from 'saas/page/tsum/store/TsumDbStore'

export const SeasonSelect = observer(() => {
  const { seasons, season } = tsum.it

  const options = seasons.map<SelectOption>(season => ({
    value: season,
  }))

  const onChange = useLatestCallback((value: string) => {
    tsum.it.season = value
  })

  return <SearchSelect options={options} value={season} onChange={onChange}
    placeholder={i18n('tsum.Season')} />
})
