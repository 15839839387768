import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ItemRow } from 'saas/page/statistics/items/ItemRow'
import { tops } from 'saas/store/stat/TopItemsStore'
import css from './TopItems.module.scss'

export const TopItems = observer(() => {
  const { ready, items } = tops
  const hide = !ready
  const empty = !items.length

  return <Card gap={16} pad={16} vertical>
    <Vertical gap={8}>
      <Typo size={15} semi>
        {i18n('statistics.TopTenItems')}
      </Typo>
      <Typo size={13} secondary>
        {i18n('statistics.ByNumberOfGenerations')}
      </Typo>
    </Vertical>
    <div className={clsx(css.view, hide && css.hide)}>
      {!ready && <Space height={200} center>
        <Typo size={13} secondary>
          {i18n('statistics.Loading')}
        </Typo>
      </Space>}
      {ready && empty && <Space height={200} center>
        <Typo size={13} secondary>
          {i18n('statistics.NoItems')}
        </Typo>
      </Space>}
      {ready && !empty && <table className={css.table}>
        <thead>
        <tr>
          <td>{i18n('statistics.Item')}</td>
          <td>{i18n('statistics.WidgetOpened')}</td>
          <td>{i18n('statistics.Generations')}</td>
          <td>
            <Vertical>
              <Typo size={13} semi>
                {i18n('statistics.ConversionToView')}
              </Typo>
              <Typo size={11} pre secondary>
                {i18n('statistics.ConversionToViewDescription')}
              </Typo>
            </Vertical>
          </td>
          <td>
            <Vertical>
              <Typo size={13} semi>
                {i18n('statistics.ConversionToBuy')}
              </Typo>
              <Typo size={11} pre secondary>
                {i18n('statistics.ConversionToBuyDescription')}
              </Typo>
            </Vertical>
          </td>
        </tr>
        </thead>
        <tbody>
        {items.map(item => <ItemRow key={item.id} item={item} />)}
        </tbody>
      </table>}
    </div>
  </Card>
})
