import { Modal } from 'component/Modal'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { InviteUsersView } from 'modal/InviteUsersModal/InviteUsersView'
import { closeInviteUsers } from 'page/invite/store/InviteUsersStore'

export const InviteUsersModal = observer(() => {
  const onCancel = useLatestCallback(() => {
    closeInviteUsers()
  })

  return <Modal stable width={400} title={i18n('invite.InviteUsers')} onCancel={onCancel}>
    <InviteUsersView />
  </Modal>
})
