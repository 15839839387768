import { app_domain, app_name } from 'app/config/constants'
import { lang } from 'app/controller/language'
import { i18n } from 'i18n'
import { when } from 'mobx'

export function getDomainTitle(): string {
  const dresscode = app_domain === 'dresscode'
  return dresscode ? 'DRESSCODE AI' : 'SHOWOFF'
}

export function getAppLink(): string {
  const dresscode = app_domain === 'dresscode'
  return dresscode ? 'https://www.dresscode.ai' : 'https://www.showoff.app'
}

export function getAppTitle(): string {
  const { ready } = lang
  const dresscode = app_domain === 'dresscode'

  switch (app_name) {
    case 'SAAS':
      if (dresscode) return ready ? i18n('app.DressCodeSaas') : 'DRESSCODE SAAS'
      return ready ? i18n('app.ShowOffSaas') : 'SHOWOFF SAAS'
    case 'MANAGE':
      return ready ? i18n('app.CatalogDirectory') : 'Catalog Directory'
    case 'CREATE':
      return ready ? i18n('app.CreatorStudio') : 'Creator Studio'
    default:
      console.error('unknown app', app_name)
      return ''
  }
}

export function initTitle() {
  window.document.title = getAppTitle()
  when(() => lang.ready).then(() => window.document.title = getAppTitle())
}
