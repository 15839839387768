// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductTypeTag-status-15ad{height:24px;padding:0 6px;gap:6px;border-radius:4px;font-size:11px;width:fit-content;background-color:var(--ui-background-color-hover);color:var(--ui-product-type-text-color);display:flex;align-items:center;justify-content:center}.ProductTypeTag-bot-15ad{color:var(--ui-product-type-text-color);background:var(--ui-product-type-bot)}.ProductTypeTag-stand-15ad{color:var(--ui-product-type-text-color);background:var(--ui-product-type-stand)}.ProductTypeTag-tryOn-15ad{color:var(--ui-product-type-text-color);background:var(--ui-product-type-tryOn)}.ProductTypeTag-divider-15ad{height:100%;opacity:.12}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/common/ProductTypeTag/ProductTypeTag.module.scss"],"names":[],"mappings":"AAAA,4BACE,WAAA,CACA,aAAA,CACA,OAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,iDAAA,CACA,uCAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,yBACE,uCAAA,CACA,qCAAA,CAGF,2BACE,uCAAA,CACA,uCAAA,CAGF,2BACE,uCAAA,CACA,uCAAA,CAGF,6BACE,WAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `ProductTypeTag-status-15ad`,
	"bot": `ProductTypeTag-bot-15ad`,
	"stand": `ProductTypeTag-stand-15ad`,
	"tryOn": `ProductTypeTag-tryOn-15ad`,
	"divider": `ProductTypeTag-divider-15ad`
};
module.exports = ___CSS_LOADER_EXPORT___;
