import { filters, FiltersStore } from 'saas/page/generations/store/FiltersStore'
import { generations, GenerationsStore } from 'saas/page/generations/store/GenerationsStore'
import { generationsHistory } from 'saas/shared/generations/hisory/GenerationsHistoryStore'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { PageController } from 'store/base/page/PageController'
import { CollectionStore } from 'store/collection'
import { home } from 'store/home'
import { FilterCollection, FilterKey } from 'type/Generations'

type Params = {
  company_id: string
}

type Data = {
  collections: FilterCollection[]
  keys: FilterKey[]
}

function extractCollection(store: CollectionStore): FilterCollection {
  const { collection_id, collection_name } = store.data.json
  return { collection_id, collection_name }
}

function extractKey(store: ApiKeyStore): FilterKey {
  const key_name = store.name
  const access_key = store.value ?? ''
  return { key_name, access_key }
}

export class GenerationsPageController implements PageController<Params, Data> {
  async load({ company_id }: Params): Promise<Data | undefined> {
    const company = home.selectCompany(company_id)
    if (!company) return

    const _companies = companiesSaas.load()
    const _collections = company.loadCollections()

    await _companies
    const companySaas = companiesSaas.company(company_id)
    if (!companySaas) return

    const _keys = companySaas.load()

    await _collections
    await _keys

    const collections = company.collections.it.collections.map(extractCollection)
    const keys = companySaas.keys.map(extractKey)

    return { collections, keys }
  }

  select({ company_id }: Params, { collections, keys }: Data): void {
    filters.open(new FiltersStore(company_id, collections, keys))
    generations.open(new GenerationsStore(company_id))
    generations.it.update()
  }

  close(): void {
    generations.close()
    filters.close()
    generationsHistory.close()
  }
}

export const generationsPageController = new GenerationsPageController()
