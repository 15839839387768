import { CarouselRef } from 'antd/es/carousel'
import { makeAutoObservable, runInAction } from 'mobx'
import { GenerationStore } from 'saas/page/generations/store/GenerationStore'
import { api } from 'store/api'
import { mx } from 'store/base/mx'
import { me } from 'store/me'
import { minAsync } from 'util/async'

type Params = {
  company_id: string
  item_id: string
  item_name: string | undefined
  brand_name: string | undefined
}

export class GenerationsHistoryStore {
  readonly company_id: string
  readonly item_id: string
  readonly item_name: string | undefined
  readonly brand_name: string | undefined

  private page = 1
  private limit = 10

  private _busy = false
  private _error = false
  private _generations: GenerationStore[] = []
  private _more = true

  private _ref: CarouselRef | undefined
  private _index = 0

  constructor(params: Params) {
    makeAutoObservable(this)
    this.company_id = params.company_id
    this.item_id = params.item_id
    this.item_name = params.item_name
    this.brand_name = params.brand_name
    void this.load()
  }

  get busy(): boolean {
    return this._busy
  }

  get error(): boolean {
    return this._error
  }

  get generations(): GenerationStore[] {
    return this._generations
  }

  get more(): boolean {
    return this._more
  }

  get ref(): CarouselRef | undefined {
    return this._ref
  }

  set ref(value: CarouselRef | null | undefined) {
    this._ref = value ?? undefined
  }

  get index(): number {
    return this._index
  }

  set index(index: number) {
    const len = this.generations.length
    index = Math.max(0, Math.min(len, index))
    this._index = index
    if (index > len - 3) void this.load()
  }

  go(index: number) {
    this.index = index
    this.ref?.goTo(this.index)
  }

  readonly load = minAsync(async () => {
    if (!this._more) return

    runInAction(() => {
      this._busy = true
      this._error = false
    })

    const { company_id, item_id, page, limit } = this
    const { user_id } = me.user
    const from = '2000-01-01'
    const to = '2200-01-01'

    const request = { user_id, company_id, from, to, item_id, page, limit }

    try {
      const response = await api.searchGenerations(request)
      const generations = response.generations.map(it => new GenerationStore(it))
      const more = generations.length === request.limit

      runInAction(() => {
        this._busy = false
        this._generations.push(...generations)
        this._more = more
        ++this.page
      })
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => {
        this._busy = false
        this._error = true
        this._more = false
      })
    }
  })
}

export const generationsHistory = mx.ref<GenerationsHistoryStore>()
