import { Card } from 'component/Card'
import { Divider } from 'component/Space/Divider'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ProductsList } from 'saas/page/products/components/ProductsList'
import { Title } from 'saas/page/products/components/Title'
import { ProductSelectType } from './ProductSelectType'
import { Gap } from 'component/Space/Gap'
import { Space } from 'component/Space'
import { ProductSelectStatus } from './ProductSelectStatus'
import { ui } from 'store/home/ui/HomeUiStore'
import css from './SaasProductsView.module.scss'

export const SaasProductsView = observer(() => {
  const { mobile } = ui.window

  return (
    <Vertical gap={16} className={css.productsView}>
      <Title />
      <Divider secondary />
      <Card wide pad={16} vertical>
        <Space
          gap={16}
          wide={mobile}
          opposite
          center={mobile}
          vertical={mobile}
        >
          <ProductSelectType />
          <ProductSelectStatus />
        </Space>
        <Gap height={16} />
        <Divider secondary />
        <Gap height={16} />
        <ProductsList />
      </Card>
    </Vertical>
  )
})
