import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { Pagination } from 'saas/page/tsum/co/Pagination'
import { ItemsTable } from 'saas/page/tsum/co/table/ItemsTable'
import { tsum } from 'saas/page/tsum/store/TsumDbStore'
import { renderLoadingState } from 'util/render'

export const ItemsCard = observer(() => {
  const { state } = tsum.it
  const stub = renderLoadingState(state)

  return <Card vertical gap={16} pad={16}>
    <ItemsTable />
    <Pagination />
    {stub && <Space pin={[0, 0, 0, 0]}>
      {stub}
    </Space>}
  </Card>
})
