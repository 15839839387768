import { getWindowHost } from 'app/config/window'
import { Button } from 'component/Button'
import { CopyButton } from 'component/Button/CopyButton'
import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { FormField } from 'form/FormField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'

export const AliasBox = observer(() => {
  const { product_id } = stand.it
  const { published } = stand.it.json
  const { alias, error, changed } = stand.it.alias
  const slug = product_id.substring(0, 8)
  const host = getWindowHost()
  const link = host + '/' + (alias.value || slug)

  const onChange = useLatestCallback(async (value: string) => {
    alias.onChange(value)
    stand.it.alias.error = ''
  })

  const onApply = useLatestCallback(async () => {
    await stand.it.applyAlias()
  })

  return <Vertical wide>
    <Typo size={12} secondary>
      {i18n('stand.ComeUpWithLinkForYourShopStandOrLeaveItDefault')}
    </Typo>
    <Gap height={12} />
    <FormField field={alias} label={host + '/'}>
      <Input value={alias.value} placeholder={slug} disabled={!published}
        onChange={onChange} suffix={<CopyButton data={link} />} />
    </FormField>
    {error && <Gap height={4} />}
    {error && <Typo size={11} error>{error}</Typo>}
    {changed && <Gap height={12} />}
    {changed && <Space>
      <Button primary action={onApply}>
        {i18n('stand.ApplyLink')}
      </Button>
    </Space>}
  </Vertical>
})
