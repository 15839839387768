import { clsx } from 'clsx'
import { observer } from 'mobx-react-lite'
import { EmptyRow } from 'saas/page/tsum/co/table/EmptyRow'
import { HeaderRow } from 'saas/page/tsum/co/table/HeaderRow'
import { ItemRow } from 'saas/page/tsum/co/table/ItemRow'
import css from 'saas/page/tsum/co/table/ItemsTable.module.scss'
import { tsum } from 'saas/page/tsum/store/TsumDbStore'

export const ItemsTable = observer(() => {
  const { state, items } = tsum.it
  const busy = state !== 'ready'

  return <table className={clsx(css.table, busy && css.busy)}>
    <thead>
    <HeaderRow />
    </thead>
    <tbody>
    {!items.length && <EmptyRow />}
    {items.map((item, index) => <ItemRow key={index} item={item} />)}
    </tbody>
  </table>
})
