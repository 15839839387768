import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { observer } from 'mobx-react-lite'
import { AliasBox } from 'saas/page/product/stand/Details/AliasBox'
import { Links } from 'saas/page/product/stand/Details/Links'
import { Status } from 'saas/page/product/stand/Details/Status'

export const Details = observer(() => {
  return <Space gap={16} top wide wrap oh>
    <Status />
    <Divider tertiary />
    <Links />
    <Divider tertiary />
    <AliasBox />
  </Space>
})
