import { tsum, TsumDbStore } from 'saas/page/tsum/store/TsumDbStore'
import { PageController } from 'store/base/page/PageController'
import { home } from 'store/home'

type Params = object

type Data = {
  filters: TsumDbStore
}

export class TsumDbPageController implements PageController<Params, Data> {
  async load(): Promise<Data | undefined> {
    const company = home.selectCompany('tsum_cs')
    if (!company) return

    const filters = await TsumDbStore.fromBackend()
    return { filters }
  }

  select(_: Params, data: Data): void {
    tsum.open(data.filters)
  }

  close(): void {
    tsum.close()
  }
}

export const tsumDbPageController = new TsumDbPageController()
