import { computed, makeAutoObservable, reaction } from 'mobx'
import { chart } from 'saas/store/stat/ChartStore'
import { feedback } from 'saas/store/stat/FeedbackStore'
import { statistics } from 'saas/store/stat/StatisticsStore'
import { tops } from 'saas/store/stat/TopItemsStore'
import { totals } from 'saas/store/stat/TotalsStore'
import { me } from 'store/me'
import { StatRequest } from 'type/Statistics'
import { skipAsync } from 'util/async'

export class StatisticsController {
  company_id?: string

  constructor() {
    makeAutoObservable<this, 'request'>(this, {
      // deep compare request
      request: computed.struct,
    })
    // delay needed to merge changes of start and end
    reaction(() => this.request, this.update, { delay: 10 })
  }

  init() {
    // to be used
  }

  private get request(): StatRequest | undefined {
    if (!me.present) return undefined

    const { user_id } = me.user
    const { company_id, interval, key } = statistics
    if (!company_id) return undefined

    statistics.store()

    const from = interval.start.format('YYYY-MM-DD')
    const to = interval.end.add(1, 'day').format('YYYY-MM-DD')
    const by_hours = interval.by_hours
    const api_key = statistics.keys.find(it => it.key_name === key)?.access_key
    return { user_id, company_id, from, to, api_key, by_hours }
  }

  private readonly update = skipAsync(async (request: StatRequest | undefined) => {
    if (!request) return

    const _chart = chart.update(request)
    const _totals = totals.update(request)
    const _tops = tops.update(request)
    const _feedback = feedback.update(request)
    await _chart
    await _totals
    await _tops
    await _feedback
  })
}

export const statisticsController = new StatisticsController()
