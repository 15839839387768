// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fixed-relative-d61e{opacity:.3}.Fixed-fixed-d61e{position:fixed;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/component/Portal/Fixed.module.scss"],"names":[],"mappings":"AAAA,qBAEE,UAAA,CAGF,kBACE,cAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relative": `Fixed-relative-d61e`,
	"fixed": `Fixed-fixed-d61e`
};
module.exports = ___CSS_LOADER_EXPORT___;
