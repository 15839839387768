export const watermarkPositions = [
  'top-left',
  'top-center',
  'top-right',
  'center-right',
  'bottom-right',
  'bottom-center',
  'bottom-left',
  'center-left',
  'center-center',
] as const
