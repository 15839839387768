import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { ui } from 'store/home/ui/HomeUiStore'

export const Title = observer(() => {
  const { mobile } = ui.window

  const pad = useMemo(() => {
    if (mobile) return [16, 16, 0, 16] as [number, number, number, number]
    return [0, 0, 0, 16] as [number, number, number, number]
  }, [mobile])

  return (
    <Space gap={mobile ? 0 : 16} opposite pad={pad}>
      <Typo size={18} bold>
        {i18n('product.AllProducts')}
      </Typo>
    </Space>
  )
})
