import { app_name } from 'app/config/constants'
import { LoadingPage } from 'feature/page/LoadingPage'
import { observer } from 'mobx-react-lite'
import { Auth } from 'page/auth'
import { Login } from 'page/login'
import { CreatorRouting } from 'routing/CreatorRouting'
import { ManagerRouting } from 'routing/ManagerRouting'
import { SaasScreen } from 'saas/screen/SaasScreen'
import { me } from 'store/me'
import { AUTH, LOGIN, route } from 'store/route'

export const Routing = observer(() => {
  if (!me.present && !route.open) return <LoadingPage />

  if (route.pathname === LOGIN) return <Login />
  if (route.pathname === AUTH) return <Auth />

  if (app_name === 'MANAGE') return <ManagerRouting />
  if (app_name === 'CREATE') return <CreatorRouting />

  return <SaasScreen />
})
