import { Button } from 'component/Button'
import { SelectOption } from 'component/Select'
import { Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { FormField } from 'form/FormField'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { inviteUsers } from 'page/invite/store/InviteUsersStore'
import { UserFormStore } from 'page/invite/store/UserFormStore'
import { useMemo } from 'react'
import { getMemberRoleLabel, memberRoles } from 'type/MemberRole'
import css from './UserRow.module.scss'

type Props = {
  user: UserFormStore
}

export const UserRow = observer(({ user }: Props) => {
  const { key, name, email, role } = user

  const roles: SelectOption[] = useMemo(() => memberRoles.map(role => ({
    value: role,
    label: getMemberRoleLabel(role),
  })), [])

  const onRemove = useLatestCallback(async () => {
    await inviteUsers.it.removeUser(key)
  })

  return <Space gap={12}>
    <Space width={240}>
      <TextField small field={name} label={i18n('label.UserName')} />
    </Space>
    <Space width={240}>
      <TextField small field={email} label={i18n('label.Email')} />
    </Space>
    <Space width={160}>
      <FormField field={role} label={i18n('member.Role')}>
        <Select small value={role.value} options={roles} error={role.error} onChange={role.onChange} />
      </FormField>
    </Space>
    <Button action={onRemove} className={css.remove} filled>
      <Icon size={16} name="delete" />
    </Button>
  </Space>
})
