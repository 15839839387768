import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Glass, Sticker } from 'component/Glass'
import { Image } from 'component/Image'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { modal } from 'modal'
import { ImageFile } from 'store/base/ImageFile'

type Props = {
  image: ImageFile
}

export function ImagePreview({ image }: Props) {
  const { images, sources } = modal.editCollectionModal.it

  const onDelete = useLatestCallback(() => {
    images.data.remove(image)
    images.touch = true
  })

  return <Card width={80} pic secondary>
    <Glass fill>
      <Image src={image.url} sources={sources} />
      <Sticker pin={[null, null, 4, 4]} hidden>
        <IconButton small action={onDelete}>
          <Icon name="delete" />
        </IconButton>
      </Sticker>
    </Glass>
  </Card>
}
