import { Segmented } from 'component/Segmented'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { Data } from 'saas/store/product/tryon/TryOnEditStore'

export const UserDataSelect = observer(() => {
  const { data } = tryon.it.edit

  const options = [{
    value: '',
    label: i18n('widget.DataDisabled'),
  }, {
    value: 'email',
    label: i18n('widget.DataEmails'),
  }, {
    value: 'phone',
    label: i18n('widget.DataPhones'),
    disabled: true
  }]

  const onChange = useLatestCallback((data: Data) => {
    tryon.it.edit.data = data
  })

  return <Segmented options={options} value={data} onChange={onChange} />
})
