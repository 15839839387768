import dayjs, { Dayjs } from 'dayjs'

export const INTERVAL_NAMES = ['today', 'week', 'month', 'quarter', 'all']
export type IntervalName = typeof INTERVAL_NAMES[number]

export function today() {
  return dayjs().startOf('day')
}

export function week() {
  return today().subtract(1, 'week').add(1, 'day')
}

export function month() {
  return today().subtract(1, 'month').add(1, 'day')
}

export function quarter() {
  return today().subtract(3, 'month').add(1, 'day')
}

export function all() {
  return dayjs('2000-01-01')
}

export function getIntervalStart(name: string | undefined): Dayjs | undefined {
  if (!name) return undefined
  if (name === 'today') return today()
  if (name === 'week') return week()
  if (name === 'month') return month()
  if (name === 'quarter') return quarter()
  if (name === 'all') return all()
  return undefined
}

export function getIntervalName(start: Dayjs, end: Dayjs): IntervalName | undefined {
  if (!today().isSame(end)) return undefined
  if (today().isSame(start)) return 'today'
  if (week().isSame(start)) return 'week'
  if (month().isSame(start)) return 'month'
  if (quarter().isSame(start)) return 'quarter'
  if (all().isSame(start)) return 'all'
  return undefined
}
