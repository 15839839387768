import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { ui } from 'store/home/ui/HomeUiStore'

export const Title = observer(() => {
  const { mobile } = ui.window
  const icon = <Icon size={16} name="add_circle" />

  const onCreate = useLatestCallback(async () => {
    await modal.openCreateCollectionModal()
  })

  return (
    <Space gap={24} opposite>
      <Typo size={23} bold>
        {i18n('collection.Collections')}
      </Typo>
      <Button primary square={mobile} action={onCreate}>
        {icon}
        {!mobile && i18n('collection.AddCollection')}
      </Button>
    </Space>
  )
})
