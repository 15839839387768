import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { LanguageSelect } from 'saas/page/product/common/LanguageSelect/LanguageSelect'
import { TitleEdit } from 'saas/page/product/common/Title/TitleEdit'
import { PositionSelect } from 'saas/page/product/tryon/PositionSelect/PositionSelect'
import { SelfiesPanel } from 'saas/page/product/tryon/SelfiesPanel/SelfiesPanel'
import { BackgroundColorInput } from 'saas/page/product/tryon/SettingsCard/BackgroundColorInput'
import { DelayInput } from 'saas/page/product/tryon/SettingsCard/DelayInput'
import { LikesCheckbox } from 'saas/page/product/tryon/SettingsCard/LikesCheckbox'
import { PrimaryColorInput } from 'saas/page/product/tryon/SettingsCard/PrimaryColorInput'
import { TextColorInput } from 'saas/page/product/tryon/SettingsCard/TextColorInput'
import { TextsTab } from 'saas/page/product/tryon/SettingsCard/TextsTab'
import { ThemeSelect } from 'saas/page/product/tryon/SettingsCard/ThemeSelect'
import { TimeoutInput } from 'saas/page/product/tryon/SettingsCard/TimeoutInput'
import { TsumBrandCheckbox } from 'saas/page/product/tryon/SettingsCard/TsumBrandCheckbox'
import { UserDataSelect } from 'saas/page/product/tryon/SettingsCard/UserDataSelect'
import { tryon } from 'saas/store/product/ProductSaasController'
import { ui } from 'store/home/ui/HomeUiStore'
import { CollapseButtonColorInput } from './CollapseButtonColorInput'
import { CollapsePosition } from './CollapsePosition'
import { CollapseButtonTextColorInput } from './CollapseButtonTextColorInput'
import { CollapseButtonType } from './CollapseButtonType'

export const SettingsCard = observer(() => {
  const tsum = tryon.it.company_id === 'tsum_cs'
  const { small } = ui.window

  return (
    <Vertical oh>
      <Space gap={8}>
        <Icon size={24} name="tune" secondary />
        <Typo size={15} semi>
          {i18n('label.Settings')}
        </Typo>
      </Space>
      <Gap height={12} />
      <TitleEdit />
      <Gap height={12} />
      <Typo size={13} semi>
        {i18n('product.ChooseLanguage')}
      </Typo>
      <Gap height={8} />
      <LanguageSelect />
      <Gap height={12} />
      <Typo size={13} semi>
        {i18n('product.WidgetStartPosition')}
      </Typo>
      <Gap height={8} />
      <PositionSelect />
      <Gap height={16} />
      <Typo size={13} semi>
        {i18n('product.UserDataCollection')}
      </Typo>
      <Gap height={8} />
      <UserDataSelect />
      <Gap height={12} />
      <Space gap={12} columns parallel wrap>
        <DelayInput />
        <TimeoutInput />
      </Space>
      <Gap height={16} />
      <Typo size={13} semi>
        {i18n('tryon.Additional')}
      </Typo>
      <Gap height={8} />
      <LikesCheckbox />
      <Gap height={8} />
      <SelfiesPanel />
      <Gap height={24} />
      <Divider secondary />
      <Gap height={24} />
      <Space gap={8}>
        <Icon size={24} name="palette" secondary />
        <Typo size={15} semi>
          {i18n('product.Appearance')}
        </Typo>
      </Space>
      <Gap height={12} />
      {tsum && <TsumBrandCheckbox />}
      {tsum && <Gap height={24} />}
      <ThemeSelect />
      <Gap height={12} />
      <Typo size={13} semi>
        {i18n('widget.Expanded')}
      </Typo>
      <Gap height={12} />
      <Space gap={12} vertical={small} columns wrap>
        <PrimaryColorInput />
        <TextColorInput />
        <BackgroundColorInput />
      </Space>
      <Gap height={12} />
      <Typo size={13} semi>
        {i18n('widget.Collapsed')}
      </Typo>
      <Gap height={12} />
      <Space gap={12} vertical={small} columns wrap>
        <CollapseButtonColorInput />
        <CollapseButtonTextColorInput />
      </Space>
      <Gap height={12} />
      <Typo size={13} semi>
        {i18n('widget.TextCollapseButtonType')}
      </Typo>
      <Gap height={12} />
      <CollapseButtonType />
      <Gap height={12} />
      <Typo size={13} semi>
        {i18n('widget.CollapsedPosition')}
      </Typo>
      <Gap height={12} />
      <CollapsePosition />
      <Gap height={16} />
      <Typo size={13} semi>
        {i18n('widget.InterfaceTexts')}
      </Typo>
      <Gap height={12} />
      <TextsTab />
    </Vertical>
  )
})
