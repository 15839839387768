import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { LegendItem } from 'saas/page/statistics/chart/LegendItem'
import { chart } from 'saas/store/stat/ChartStore'
import css from './Legend.module.scss'

export const Legend = observer(() => {
  return <Space className={css.grid} gap={[16, 24]} pad={[12, 16]} wrap>
    {chart.all.map(serie => <LegendItem key={serie.id} serie={serie} />)}
  </Space>
})
