import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable, observable } from 'mobx'
import { ThemeColors } from 'saas/page/product/tryon/constants/colors'
import { SelfiesStore } from 'saas/store/product/tryon/SelfiesStore'
import { StringStore } from 'store/base/StringStore'
import { TryOnCollapseButtonType, TryOnCollapsePosition, TryOnPosition, TryOnProduct } from 'type/product/TryOnProduct'
import { fixText } from 'util/form'
import { equal, updateObject } from 'util/object'

export type Data = 'email' | 'phone' | ''

export class TryOnEditStore {
  private _json: TryOnProduct

  private readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(), { required: true, fix: fixText })

  private _lang: string | undefined
  private _position: TryOnPosition = 'right'
  private _theme: string | undefined
  // color
  private _primary: string | undefined
  private _text: string | undefined
  private _background: string | undefined
  private _collapse_button: string | undefined
  private _collapse_button_text: string | undefined
  // user data
  private _data: Data = ''
  private _title: string | undefined
  private _description: string | undefined
  private _agreement: string | undefined
  private _delay: number | undefined
  private _timeout: number | undefined
  private _thanks: string | undefined
  // selfies
  readonly selfies = new SelfiesStore()
  // likes
  private _likes = false
  // tsum
  private _tsum = false
  // collapse
  private _collapse_position: TryOnCollapsePosition = TryOnCollapsePosition.LeftBottom
  private _collapse_button_type: TryOnCollapseButtonType = TryOnCollapseButtonType.Text

  constructor(json: TryOnProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json

    if (!json.collapse_position) {
      json.collapse_position = TryOnCollapsePosition.LeftBottom
    }

    if (!json.position) {
      json.position = 'right'
    }

    if (!json.selfies) {
      json.selfies = {}
    }

    if (!json.email) {
      json.email = {
        on: false,
      }
    }

    if (!json.color) {
      json.color = {}
    }

    this.applyJson(json)
  }

  get json(): TryOnProduct {
    return this._json
  }

  set json(value: TryOnProduct) {
    this._json = value
  }

  get lang(): string | undefined {
    return this._lang
  }

  set lang(value: string | undefined) {
    this._lang = value
  }

  get position(): TryOnPosition {
    return this._position
  }

  set position(value: TryOnPosition) {
    this._position = value
  }

  get theme(): string | undefined {
    return this._theme
  }

  set theme(value: string | undefined) {
    this._theme = value

    // reset colors from theme constants
    this._primary = ThemeColors[value as keyof typeof ThemeColors].primary
    this._text = ThemeColors[value as keyof typeof ThemeColors].text
    this._background = ThemeColors[value as keyof typeof ThemeColors].background
  }

  get primary(): string | undefined {
    return this._primary
  }

  set primary(value: string | undefined) {
    this._primary = value
  }

  get text(): string | undefined {
    return this._text
  }

  set text(value: string | undefined) {
    this._text = value
  }

  get background(): string | undefined {
    return this._background
  }

  set background(value: string | undefined) {
    this._background = value
  }

  get collapse_button(): string | undefined {
    return this._collapse_button
  }

  set collapse_button(value: string | undefined) {
    this._collapse_button = value
  }

  get collapse_button_text(): string | undefined {
    return this._collapse_button_text
  }

  set collapse_button_text(value: string | undefined) {
    this._collapse_button_text = value
  }

  get collapse_position(): TryOnCollapsePosition {
    return this._collapse_position
  }

  set collapse_position(value: TryOnCollapsePosition) {
    this._collapse_position = value
  }

  get collapse_button_type(): TryOnCollapseButtonType {
    return this._collapse_button_type
  }

  set collapse_button_type(value: TryOnCollapseButtonType) {
    this._collapse_button_type = value
  }

  get data(): Data {
    return this._data
  }

  set data(value: Data) {
    this._data = value
  }

  get title(): string | undefined {
    return this._title
  }

  set title(value: string | undefined) {
    this._title = value
  }

  get description(): string | undefined {
    return this._description
  }

  set description(value: string | undefined) {
    this._description = value
  }

  get agreement(): string | undefined {
    return this._agreement
  }

  set agreement(value: string | undefined) {
    this._agreement = value
  }

  get delay(): number | undefined {
    return this._delay
  }

  set delay(value: number | undefined) {
    this._delay = value == null ? undefined : Math.abs(value)
  }

  get timeout(): number | undefined {
    return this._timeout
  }

  set timeout(value: number | undefined) {
    this._timeout = value == null ? undefined : Math.abs(value)
  }

  get thanks(): string | undefined {
    return this._thanks
  }

  set thanks(value: string | undefined) {
    this._thanks = value
  }

  get likes(): boolean {
    return this._likes
  }

  set likes(value: boolean) {
    this._likes = value
  }

  get tsum(): boolean {
    return this._tsum
  }

  set tsum(value: boolean) {
    this._tsum = value
  }

  get updates(): Partial<TryOnProduct> {
    const {
      lang, position, theme, primary, text, background,
      data, title, description, agreement, delay, timeout, thanks, collapse_button, collapse_button_text, collapse_position, collapse_button_type,
    } = this

    const name = this.name.value || ''
    const color = { primary, text, background, collapse_button, collapse_button_text }
    const on = !!data
    const type = data || undefined

    const email = { on, type, title, description, agreement, delay, timeout, thanks }
    const selfies = this.selfies.updates
    const likes = this.likes ? { on: true } : undefined
    const tsum = this.tsum || undefined

    return { name, lang, position, theme, color, email, selfies, likes, tsum, collapse_position, collapse_button_type }
  }

  get changed(): boolean {
    const updated = updateObject(this.json, this.updates)
    return !equal(this.json, updated)
  }

  get canSave(): boolean {
    return !this.form.error && this.selfies.canSave && this.changed
  }

  check() {
    return this.form.check() && this.canSave
  }

  private applyJson(json: TryOnProduct) {
    const on = json.email?.on
    const type = json.email?.type

    this.name.value = json.name || ''
    this._lang = json.lang
    this._position = json.position || 'right'
    this._theme = json.theme
    this._primary = json.color?.primary
    this._text = json.color?.text
    this._background = json.color?.background
    this._collapse_button = json.color?.collapse_button
    this._collapse_button_text = json.color?.collapse_button_text
    this._collapse_button_type = json.collapse_button_type || TryOnCollapseButtonType.Text
    this._data = on ? type === 'phone' ? 'phone' : 'email' : ''
    this._title = json.email?.title
    this._description = json.email?.description
    this._agreement = json.email?.agreement
    this._delay = json.email?.delay
    this._timeout = json.email?.timeout
    this._thanks = json.email?.thanks
    this.selfies.apply(json)
    this._likes = !!json.likes?.on
    this._tsum = !!json.tsum
    this._collapse_position = json.collapse_position || TryOnCollapsePosition.LeftBottom
  }
}
