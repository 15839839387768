import { notice } from 'app/notice'
import clsx from 'clsx'
import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Text } from 'component/Typo/Text'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { ProductTypeTag } from 'saas/page/product/common/ProductTypeTag/ProductTypeTag'
import { LinkButton } from 'saas/page/product/stand/LinkButton/LinkButton'
import { groupsSaas } from 'saas/store/groups/GroupsCache'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { route } from 'store/route'
import { formatShortApiKey } from 'type/ApiKey'
import { BotProduct } from 'type/product/BotProduct'
import { Product } from 'type/product/Product'
import { ProductTypeList, STAND } from 'type/product/ProductType'
import { StandProduct } from 'type/product/StandProduct'
import { TryOnProduct } from 'type/product/TryOnProduct'
import { copyText } from 'util/browser'
import css from './ProductCard.module.scss'

type Props = {
  product: ProductSaasStore
}

function getProductImage(json: Product): string | undefined {
  if (json.type === STAND) return json.logo || json.icon
  return undefined
}

const productStyleMap = {
  [ProductTypeList.TRY_ON]: css.typeTryOn,
  [ProductTypeList.STAND]: css.typeStand,
  [ProductTypeList.BOT]: css.typeBot,
}

export const ProductCard = observer(({ product }: Props) => {
  const { company_id } = productsSaas.it.config
  const { groups } = groupsSaas.it
  const { products, keys } = productsSaas.it

  const image = getProductImage(product.json)
  const { product_id, type, name } = product.json
  const path = `/company/${company_id}/product/${type}/`
  const groupPath = `/company/${company_id}/group/`

  const isTryOn = useMemo(() => {
    return type === ProductTypeList.TRY_ON
  }, [type])

  const isStand = useMemo(() => {
    return type === ProductTypeList.STAND
  }, [type])

  const isBot = useMemo(() => {
    return type === ProductTypeList.BOT
  }, [type])

  const onManage = useLatestCallback(async () => {
    route.push(`${path}${product_id}`)
  })

  const apiKey = useMemo(() => {
    if (isTryOn) {
      return keys.find(
        (it) => it.value === (product.json as TryOnProduct).access
      )?.name
    }
  }, [isTryOn, keys, product.json])

  const productGroup = useMemo(() => {
    if (isStand) {
      const group = groups.find((g) => {
        return g.group_id === (product.json as StandProduct).group
      })
      return group
    }
  }, [isStand, groups, product.json])

  const botProduct = useMemo(() => {
    if (isBot) {
      const botProduct = products.find(
        (p) =>
          p.json.product_id === (product.json as BotProduct).window_product_id
      )
      return botProduct
    }
  }, [isBot, product.json, products])

  const botGroup = useMemo(() => {
    if (isBot && botProduct) {
      const group = groups.find((g) => {
        return g.group_id === (botProduct?.json as StandProduct).group
      })
      return group
    }
  }, [isBot, botProduct, groups])

  const standWidget = useMemo(() => {
    if (isStand) {
      const standProduct = products.find(
        (p) => p.json.product_id === (product.json as StandProduct).widget
      )
      return standProduct
    }
  }, [isStand, product, products])

  const botWidget = useMemo(() => {
    if (isBot && botProduct) {
      const botWidget = products.find(
        (p) => p.json.product_id === (botProduct?.json as StandProduct).widget
      )
      return botWidget
    }
  }, [isBot, botProduct, products])

  const onCopy = useLatestCallback(async (e: React.MouseEvent) => {
    e.stopPropagation()
    if (apiKey) {
      await copyText((product.json as TryOnProduct).access)
      notice.success(i18n('message.Copied'))
    }
  })

  return (
    <Card
      pad={12}
      vertical
      tertiary
      action={onManage}
      className={clsx(css.productCard, productStyleMap[type])}
    >
      <Space gap={12} top opposite height={'100%'}>
        <Vertical height='100%' gap={8} opposite>
          <Text size={15} semi rows={3}>
            {name}
          </Text>
          <Vertical gap={4} className={css.params}>
            {isTryOn && (
              <Vertical gap={4}>
                <Space gap={4}>
                  <Typo size={11} secondary>
                    {i18n('products.card.APIkey')}:
                  </Typo>
                  <Typo size={11}>{apiKey}</Typo>
                </Space>
                {apiKey && (
                  <Space
                    maxWidth="200px"
                    gap={4}
                    className={css.apiKey}
                    onClick={onCopy}
                  >
                    <Typo size={11}>
                      {formatShortApiKey((product.json as TryOnProduct).access)}
                    </Typo>
                    <Icon
                      className={css.copyButton}
                      name="content_copy"
                      secondary
                    />
                  </Space>
                )}
              </Vertical>
            )}
            {isStand && (
              <Space gap={4}>
                <Typo size={11} secondary>
                  {i18n('products.card.Widget')}:
                </Typo>
                {standWidget && (
                  <Link
                    className={css.link}
                    href={`${path}${standWidget.json.product_id}`}
                  >
                    <Typo size={11} primary>
                      {standWidget.json.name}
                    </Typo>
                  </Link>
                )}
                {!standWidget && (
                  <Typo size={11}>{i18n('widget.Default')}</Typo>
                )}
              </Space>
            )}
            {isStand && (
              <Space gap={4}>
                <Typo size={11} secondary>
                  {i18n('products.card.Group')}:
                </Typo>
                {productGroup && (
                  <Link
                    className={css.link}
                    href={`${groupPath}${productGroup.group_id}`}
                  >
                    <Typo size={11} primary>
                      {productGroup.json?.group_name}
                    </Typo>
                  </Link>
                )}
              </Space>
            )}
            {isBot && (
              <Space gap={4}>
                <Typo size={11} secondary>
                  {i18n('products.card.Widget')}:
                </Typo>
                {botProduct && botWidget && (
                  <Link
                    className={css.link}
                    href={`${path}${botProduct.json.product_id}`}
                  >
                    <Typo size={11} primary>
                      {botWidget.json.name}
                    </Typo>
                  </Link>
                )}
              </Space>
            )}
            {isBot && (
              <Space gap={4}>
                <Typo size={11} secondary>
                  {i18n('products.card.Group')}:
                </Typo>
                {botGroup && (
                  <Link
                    className={css.link}
                    href={`${groupPath}${botGroup.json.group_id}`}
                  >
                    <Typo size={11} primary>
                      {botGroup.json.group_name}
                    </Typo>
                  </Link>
                )}
              </Space>
            )}
          </Vertical>
          <Space gap={8}>
            <ProductTypeTag type={type} product={product} />
            {isStand && (
              <LinkButton product={product} className={css.standLink} />
            )}
          </Space>
        </Vertical>
        {image && (
          <Card minWidth={92} width={92} height={92} className={css.image}>
            <Image src={image} />
          </Card>
        )}
      </Space>
    </Card>
  )
})
