// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopItems-view-fef0{opacity:1;transition:opacity 200ms ease-in-out}.TopItems-hide-fef0{opacity:.1;transition-duration:1000ms}.TopItems-table-fef0{width:100%;border-collapse:collapse}.TopItems-table-fef0 td:nth-child(2){width:100px}.TopItems-table-fef0 td:nth-child(3){width:100px}.TopItems-table-fef0 td:nth-child(4){width:100px}.TopItems-table-fef0 td:nth-child(5){width:100px}.TopItems-table-fef0 thead td{white-space:nowrap;vertical-align:middle;padding:12px 8px;font-size:13px;font-weight:600;color:var(--ui-text-color);background:rgba(30,30,30,.04);position:relative}.TopItems-table-fef0 thead td+td:before{content:"";position:absolute;width:1px;left:0;top:8px;bottom:8px;background:#e4e4e4}.TopItems-table-fef0 thead td:first-child{border-radius:8px 0 0 0}.TopItems-table-fef0 thead td:last-child{border-radius:0 8px 0 0}.TopItems-table-fef0 tbody td{vertical-align:center;padding:8px 8px;border-bottom:1px solid var(--ui-border-color-tertiary)}`, "",{"version":3,"sources":["webpack://./src/saas/page/statistics/items/TopItems.module.scss"],"names":[],"mappings":"AAAA,oBACE,SAAA,CACA,oCAAA,CAGF,oBACE,UAAA,CACA,0BAAA,CAGF,qBACE,UAAA,CACA,wBAAA,CAIA,qCACE,WAAA,CAGF,qCACE,WAAA,CAGF,qCACE,WAAA,CAGF,qCACE,WAAA,CAKF,8BACE,kBAAA,CACA,qBAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,0BAAA,CACA,6BAAA,CACA,iBAAA,CAGE,wCACE,UAAA,CACA,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,kBAAA,CAKJ,0CACE,uBAAA,CAGF,yCACE,uBAAA,CAMJ,8BACE,qBAAA,CACA,eAAA,CACA,uDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": `TopItems-view-fef0`,
	"hide": `TopItems-hide-fef0`,
	"table": `TopItems-table-fef0`
};
module.exports = ___CSS_LOADER_EXPORT___;
