import { Card } from 'component/Card'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { try_on_preview } from 'feature/widget/TryOnPreviewStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { ItemSelect } from 'saas/page/product/tryon/Preview/ItemSelect'
import { tryon } from 'saas/store/product/ProductSaasController'

export const Preview = observer(() => {
  const [container, setContainer] = useState<HTMLElement | null>(null)

  const onRef = useLatestCallback((container: HTMLElement | null) => {
    setContainer(container)
  })

  const onPreview = useLatestCallback(async () => {
    const { optionsPreview } = tryon.it
    if (container) void try_on_preview.preview(optionsPreview, container)
  })

  return (
    <Vertical gap={12} wide>
      <Space gap={16} opposite wrap>
        <Space gap={8}>
          <Icon size={24} name="bolt" secondary />
          <Typo size={15} semi>
            {i18n('widget.Preview')}
          </Typo>
        </Space>
        <Link action={onPreview}>{i18n('widget.Update')}</Link>
      </Space>
      <ItemSelect onPreview={onPreview} />
      <Card pad={16} round={12} panel>
        <Card ref={onRef} round={6} wide height={720} overflow pale />
      </Card>
    </Vertical>
  )
})
