// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popover-popover-f5aa{box-shadow:var(--ant-box-shadow-secondary);border-radius:var(--ui-border-radius-half);overflow:hidden}.Popover-popover-f5aa .ant-popover-inner{max-width:calc(100vw - 48px);overflow:auto !important}.Popover-raw-f5aa .ant-popover-inner{background-color:rgba(0,0,0,0);border-radius:unset;box-shadow:none}.Popover-fast-f5aa{animation-duration:0ms !important}.Popover-child-f5aa{display:flex;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/component/Popover/Popover.module.scss"],"names":[],"mappings":"AAAA,sBACE,0CAAA,CACA,0CAAA,CACA,eAAA,CAEA,yCACE,4BAAA,CACA,wBAAA,CAKF,qCACE,8BAAA,CACA,mBAAA,CACA,eAAA,CAIJ,mBACE,iCAAA,CAGF,oBACE,YAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `Popover-popover-f5aa`,
	"raw": `Popover-raw-f5aa`,
	"fast": `Popover-fast-f5aa`,
	"child": `Popover-child-f5aa`
};
module.exports = ___CSS_LOADER_EXPORT___;
