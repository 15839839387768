import { i18n } from 'i18n'

export const TSUM_SORTINGS = ['created_at_desc', 'created_at_asc', 'updated_at_desc', 'updated_at_asc'] as const
export type TsumSort = typeof TSUM_SORTINGS[number]

export type TsumFilters = {
  seasons: string[]
  colors: string[]
  genders: string[]
}

export type TsumSearchRequest = {
  page: number
  limit: number
  sort?: TsumSort
  season?: string
  color?: string
  gender?: string
}

export type TsumItem = {
  brand?: string
  category?: string
  color?: string
  gender?: string
  images: string[]
  name?: string
  product_id: string
  product_url?: string
  season?: string
  sizes: number
}

export function getTsumSortLabel(sort: TsumSort): string {
  switch (sort) {
    case 'created_at_desc':
      return i18n('sort.CreatedAtDesc')
    case 'created_at_asc':
      return i18n('sort.CreatedAtAsc')
    case 'updated_at_desc':
      return i18n('sort.UpdatedAtDesc')
    case 'updated_at_asc':
      return i18n('sort.UpdatedAtAsc')
    default:
      return `${sort}`.toUpperCase() || 'NONE'
  }
}
