import { BotProduct } from 'type/product/BotProduct'
import { Product } from 'type/product/Product'
import { ProductsConfig, UnknownProduct } from 'type/product/ProductsConfig'
import { BOT, ProductType, STAND, TRY_ON } from 'type/product/ProductType'
import { StandProduct } from 'type/product/StandProduct'
import { TryOnProduct } from 'type/product/TryOnProduct'
import { notNil } from 'util/null'
import { generateUuid } from 'util/uuid'

export function extractProduct(json: UnknownProduct): Product | undefined {
  const { company_id, product_id, type } = json
  if (!company_id || !product_id || !type) {
    console.error('bad product', json)
    return undefined
  }
  switch (type) {
    case TRY_ON:
      return json as TryOnProduct
    case STAND:
      return json as StandProduct
    case BOT:
      return json as BotProduct
    default:
      console.error('unknown product', json)
      return undefined
  }
}

export function extractProducts(products: ProductsConfig): Product[] {
  if (!products.length) return []
  return products.map(extractProduct).filter(notNil)
}

export function addProduct(products: ProductsConfig, product: Product): ProductsConfig {
  return [product, ...products]
}

export function updateProduct(products: ProductsConfig, product: Product): ProductsConfig {
  const index = products.findIndex(p => p.product_id === product.product_id)
  if (index < 0) {
    console.error('no product', product.product_id)
    return products
  }
  products = [...products]
  products[index] = product
  return products
}

export function deleteProduct(products: ProductsConfig, product: Product): ProductsConfig {
  const index = products.findIndex(p => p.product_id === product.product_id)
  if (index < 0) {
    console.error('no product', product.product_id)
    return products
  }
  products = [...products]
  products.splice(index, 1)
  return products
}

export const buildProductDraft = (company_id: string, type: ProductType, product_id?: string): Product => {
  const id = product_id ?? generateUuid()
  const created_at = ''
  const updated_at = ''
  const name = ''
  if (!type) throw new Error('no type')
  return { company_id, product_id: id, type, created_at, updated_at, name, _draft: true }
}
