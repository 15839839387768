// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Legend-grid-9716{overflow:hidden}.Legend-grid-9716>*{min-width:160px}`, "",{"version":3,"sources":["webpack://./src/saas/page/statistics/chart/Legend.module.scss"],"names":[],"mappings":"AAAA,kBACE,eAAA,CAEA,oBACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `Legend-grid-9716`
};
module.exports = ___CSS_LOADER_EXPORT___;
