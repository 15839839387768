import { makeAutoObservable } from 'mobx'
import { UserFormStore } from 'page/invite/store/UserFormStore'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { api } from 'store/api'
import { mx } from 'store/base/mx'
import { can } from 'store/can'
import { CompanyStore } from 'store/company/CompanyStore'
import { home } from 'store/home'
import { me } from 'store/me'
import { InviteRequest } from 'type/User'
import { notNil } from 'util/null'

type Step = 'company' | 'users' | 'final' | 'success' | 'error'

type Company = CompanyStore | undefined

export enum InviteFailType {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  FAILED = 'FAILED',
}

export class InviteUsersStore {
  private _step: Step = 'company'
  private _company: Company
  private readonly _all: boolean
  private _users = [new UserFormStore()]
  private _fails: UserFormStore[] = []
  private _failType: InviteFailType = InviteFailType.FAILED

  constructor(company: Company, all?: boolean) {
    this._company = company
    this._all = all ?? false
    if (company) this._step = 'users'
    makeAutoObservable(this)
  }

  get all(): boolean {
    return this._all
  }

  get step(): Step {
    return this._step
  }

  get company(): Company {
    return this._company
  }

  set company(value: Company) {
    this._company = value
  }

  get users(): UserFormStore[] {
    return this._users
  }

  get companies() {
    return home.companies.companies.filter(company => can.InviteUsers(company.company_id))
  }

  get fails(): UserFormStore[] {
    return this._fails
  }

  get failType(): InviteFailType {
    return this._failType
  }

  get hasUsersDone(): boolean {
    return this._users.every(user => !user.form.error)
  }

  get canInvite(): boolean {
    return this.company != null && this.hasUsersDone
  }

  readonly onNext = async () => {
    this.step = this.getNextStep()
  }

  readonly onBack = async () => {
    this.step = this.getBackStep()
  }

  readonly onAddUser = async () => {
    this.users.push(new UserFormStore())
  }

  async removeUser(key: string) {
    if (this.users.length < 2) {
      const found = this.users.find(user => user.key === key)
      if (found) {
        found.email.value = ''
        found.name.value = ''
      }
    } else {
      this._users = this.users.filter(user => user.key !== key)
    }
  }

  readonly onInvite = async () => {
    if (!this.check()) return

    const request = this.buildRequest()
    const responses = await api.invite(request)

    const company = companiesSaas.companies.find(c => c.company_id === request.company_id)
    if (company) void company.reload()

    const fails = responses.map((invite, index) => invite ? null : this._users[index]).filter(notNil)
    const alreadyExists = responses.map((invite, index) => invite?.is_new_user === false ? this._users[index] : null).filter(notNil)

    if (alreadyExists.length > 0) {
      this.fails = alreadyExists
      this.failType = InviteFailType.ALREADY_EXISTS
      this.step = 'error'
      return
    }

    if (fails.length) {
      this.fails = fails
      this.step = 'error'
    } else {
      this.step = 'success'
    }
  }

  private set step(value: Step) {
    this._step = value
  }

  private set fails(value: UserFormStore[]) {
    this._fails = value
  }

  private set failType(type: InviteFailType) {
    this._failType = type
  }

  private getNextStep(): Step {
    if (this.step === 'company') {
      if (this._company) return 'users'
    } else if (this.step === 'users') {
      if (this.check()) return 'final'
    }
    return this.step
  }

  private check() {
    return this._users.map(user => user.form.check()).every(check => check)
  }

  private getBackStep(): Step {
    if (this.step === 'users') return 'company'
    else if (this.step === 'final') return 'users'
    return this.step
  }

  private buildRequest(): InviteRequest {
    const user_id = me.user.user_id
    const company_id = this._company?.company_id
    const users = this._users.map(user => ({
      name: user.name.value,
      email: user.email.value,
      role: user.role.value,
    }))
    if (!company_id) throw new Error('no company')
    return { user_id, company_id, users }
  }
}

export const inviteUsers = mx.ref<InviteUsersStore>()

export function openInviteUsers(company: Company, all?: boolean) {
  inviteUsers.it = new InviteUsersStore(company, all)
}

export function closeInviteUsers() {
  inviteUsers.close()
}
