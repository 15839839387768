import { SearchSelect, SelectOption } from 'component/Select'
import { FormField } from 'form/FormField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { useMemo } from 'react'
import countries from './countries.json'

function buildOptions(): SelectOption[] {
  const all = { value: 'ALL', label: i18n('label.AllCountries') }
  const options = countries.map(country => ({ value: country, label: country }))
  return [all, ...options]
}

export const CountriesSelect = observer(() => {
  const { countries } = modal.editCollectionModal.it
  const options = useMemo(buildOptions, [])

  const onChange = useLatestCallback((value: string[]) => {
    const all = value.includes('ALL') && !countries.value.includes('ALL')
    if (all) countries.value = ['ALL']
    else countries.value = value.filter(item => item !== 'ALL')
  })

  return <FormField field={countries} label={i18n('label.Countries')}>
    <SearchSelect multiple onChange={onChange} value={countries.value} options={options}
      placeholder={i18n('placeholder.Countries')} />
  </FormField>
})
