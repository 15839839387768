import { parseJson } from 'util/json'

export class HttpError extends Error {
  readonly status: number
  readonly text: string

  constructor(status: number, text: string) {
    const message = [status, text].join(' ')
    super(message)
    this.status = status
    this.text = text
  }

  get record(): Record<string, unknown> {
    const json = parseJson(this.text)
    const record = json && !Array.isArray(json) && typeof json === 'object'
    return record ? json as Record<string, unknown> : {}
  }
}
