function getEnv(): 'development' | 'staging' | 'production' {
  if (process.env.NODE_ENV === 'development') return 'development'
  if (location.hostname.includes('stage')) return 'staging'
  return 'production'
}

function getApp(): 'SAAS' | 'MANAGE' | 'CREATE' {
  const app = process.env.DEVELOPMENT_APP
  if (app && ['SAAS', 'MANAGE', 'CREATE'].includes(app)) return app
  if (app) console.error('unknown app', app)

  const env = getEnv()
  if (location.hostname.startsWith('admin')) return 'SAAS'
  if (location.hostname.startsWith('saas')) return 'SAAS'
  if (location.hostname.startsWith('catalog')) return 'MANAGE'
  if (location.hostname.startsWith('create')) return 'CREATE'
  if (env === 'development') return 'SAAS'

  console.error('unknown host', location.hostname)
  return 'SAAS'
}

function getDomain(): 'dresscode' | 'showoff' {
  const domain = process.env.DEVELOPMENT_DOMAIN
  if (domain && ['dresscode', 'showoff'].includes(domain)) return domain
  if (domain) console.error('unknown domain', domain)

  const dresscode = location.hostname.endsWith('.dresscode.ai')
  return dresscode ? 'dresscode' : 'showoff'
}

function getLanguage(): 'en' | 'ru' {
  const query = new URLSearchParams(location.search)
  if (query.has('en')) return 'en'
  if (query.has('ru')) return 'ru'

  const lang = process.env.DEVELOPMENT_LANG
  if (lang && ['en', 'ru'].includes(lang)) return lang
  if (lang) console.error('unknown lang', lang)

  const ru = app_domain === 'dresscode'
  return ru ? 'ru' : 'en'
}

const env = getEnv()

export const development = env === 'development'
export const staging = env === 'staging'
export const production = env === 'production'
export const app_version = process.env.RELEASE_NAME || 'unknown'
export const app_name = getApp()
export const app_domain = getDomain()
export const language = getLanguage()
export const tryon_host = production ? 'try-on.showoff.app' : 'try-on-stage.show-off.org'
