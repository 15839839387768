// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaasProductsView-productsView-7659{margin:-16px}@media all and (min-width: 760px){.SaasProductsView-productsView-7659{margin:0}}`, "",{"version":3,"sources":["webpack://./src/saas/page/products/components/SaasProductsView.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CAEA,kCAHF,oCAII,QAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productsView": `SaasProductsView-productsView-7659`
};
module.exports = ___CSS_LOADER_EXPORT___;
