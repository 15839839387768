import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { TopItem } from 'type/Statistics'
import { formatNumber, formatPercent } from 'util/number'
import { emdash } from 'util/typo'

type ItemRowProps = {
  item: TopItem
}

export const ItemRow = ({ item }: ItemRowProps) => {
  const { brand, image, name } = item
  const views = item.views ?? 0
  const buys = item.click_buy ?? 0
  const gen = item.generations ?? 0
  const opens = item.opens ?? 0
  const views_con = opens > 0 ? (views / opens) * 100 : 0
  const buys_con = opens > 0 ? (buys / opens) * 100 : 0

  return <tr>
    <td>
      <Space gap={12}>
        <Card height={72} small pic>
          {image && <Image src={image} sources={[image]} />}
        </Card>
        <Vertical gap={4}>
          <Typo size={13} medium>{name ?? emdash}</Typo>
          <Typo size={13} secondary>{brand ?? emdash}</Typo>
        </Vertical>
      </Space>
    </td>
    <td>
      <Typo size={13} medium>
        {formatNumber(opens)}
      </Typo>
    </td>
    <td>
      <Typo size={13} medium>
        {formatNumber(gen)}
      </Typo>
    </td>
    <td>
      <Typo size={13} medium>
        {formatPercent(views_con)}
      </Typo>
    </td>
    <td>
      <Typo size={13} medium>
        {formatPercent(buys_con)}
      </Typo>
    </td>
  </tr>
}
