import { Field } from 'component/Field'
import { Option, Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { try_on_preview } from 'feature/widget/TryOnPreviewStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useCallback, useRef, useState } from 'react'
import { api } from 'store/api'
import { home } from 'store/home'
import { me } from 'store/me'
import { Item } from 'type/Item'
import css from './ItemSelect.module.scss'
import { useEffectOnce } from 'hook/useEffectOnce'

type Props = {
  onPreview: () => void
}

function renderNotFoundContent(loading: boolean, empty: boolean) {
  if (loading)
    return (
      <Space height={64} center>
        <Spinner />
      </Space>
    )

  if (empty)
    return (
      <Space height={64} center>
        <Typo size={11} secondary>
          {i18n('search.NothingFound')}
        </Typo>
      </Space>
    )

  return null
}

export const ItemSelect = observer(({ onPreview }: Props) => {
  const sequence = useRef(0)
  const [options, setOptions] = useState<Option<Item>[]>([])
  const [loading, setLoading] = useState(false)
  const value = try_on_preview.item_id

  useEffectOnce(() => {
    const setDefaultOption = async () => {
      const options = await search('')
      setOptions(options)
      if (options.length > 0) {
        if (value) {
          const option = options.find((option) => option.value === value)
          if (option) {
            try_on_preview.item_id = option.value!
          }
        } else {
          const [first] = options
          try_on_preview.item_id = first?.value || ''
        }
        onPreview()
      }
    }
    setDefaultOption()
  })

  const search = useLatestCallback(async (text: string, limit: number = 8) => {
    const response = await api.search({
      user_id: me.user.user_id,
      company_id: home.company_id!,
      limit,
      query: text,
      sort_by: [{ updated_at: 'desc' }],
    })
    const options = response.items.map<Option<Item>>((item) => {
      return { value: item.item_id, label: item.product_name, data: item }
    })
    return options
  })

  const [update] = useState(() => {
    async function update(id: number, text: string) {
      if (id !== sequence.current) return
      const options = await search(text)
      if (id !== sequence.current) return
      setOptions(options)
      setLoading(false)
    }

    return debounce(update, 500)
  })

  const onSearch = useLatestCallback((text: string) => {
    const id = ++sequence.current
    setOptions([])
    setLoading(true)
    void update(id, text)
  })

  const onFocus = useLatestCallback(() => {
    onSearch('')
  })

  const onBlur = useLatestCallback(() => {
    ++sequence.current
    setOptions([])
    setLoading(false)
  })

  const onChange = useCallback(
    (value: string) => {
      try_on_preview.item_id = value || ''
      onPreview()
    },
    [onPreview]
  )

  const content = renderNotFoundContent(loading, !options.length)

  return (
    <Field label={i18n('item.Item')}>
      <Select
        className={css.select}
        wide
        options={options}
        placeholder={options.length > 0 ? '' : i18n('search.NothingFound')}
        value={value}
        showSearch
        filterOption={false}
        notFoundContent={content}
        onSearch={onSearch}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
    </Field>
  )
})
