import { Tooltip } from 'component/Popover/Tooltip'
import { Space } from 'component/Space'
import { Switch } from 'component/Switch/Switch'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { StatusTag } from 'saas/page/product/common/StatusTag/StatusTag'
import { stand } from 'saas/store/product/ProductSaasController'

export const Status = observer(() => {
  const { draft, busy, json } = stand.it
  const { published } = json
  const no_widget = !published && !json.widget
  const error = draft ? i18n('product.SaveBeforePublishing') :
    no_widget ? i18n('stand.CantPublishStandWithoutWidget') : ''
  const disabled = busy || !!error

  const onPublish = useLatestCallback((publish: boolean) => {
    void stand.it.publish(publish)
  })

  return <Space gap={12} maxWidth={480} wide wrap>
    <Tooltip title={error}>
      <Switch checked={!!published} onChange={onPublish} disabled={disabled} />
    </Tooltip>
    <Space spring>
      <Typo size={13} semi>
        {i18n('product.PublishProduct')}
      </Typo>
    </Space>
    <StatusTag />
  </Space>
})
