import TelegramSvg from 'asset/icon-telegram-simple.svg'
import clsx from 'clsx'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { BOT, ProductType, ProductTypeList } from 'type/product/ProductType'
import css from './ProductTypeTag.module.scss'

type Props = {
  type: ProductType
  product?: ProductSaasStore
}

const typeMap = {
  [ProductTypeList.BOT]: {
    icon: <TelegramSvg width={12} height={12} />,
    title: i18n('product.TelegramBot'),
    style: css.bot,
  },
  [ProductTypeList.STAND]: {
    icon: <Icon size={12} name="ads_click" fill />,
    title: i18n('product.QrCatalog'),
    style: css.stand,
  },
  [ProductTypeList.TRY_ON]: {
    icon: <Icon size={12} name="widgets" fill />,
    title: i18n('product.TryOnWidget'),
    style: css.tryOn,
  },
}

function text(product: ProductSaasStore) {
  const { type, published } = product.json
  const bot = type === BOT

  if (!published) return null
  
  return <>
    <Divider className={css.divider} vertical />
    <Typo>{bot ? i18n('bot.Connected') : i18n('product.Published')}</Typo>
  </>
}

export const ProductTypeTag = ({ type, product }: Props) => {
  product ??= productSaas.it
  const { icon, style } = typeMap[type]

  return (
    <Space className={clsx(css.status, style)} center>
      {icon}
      {text(product)}
    </Space>
  )
}
