// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FiltersCard-filters-d66c{display:grid;align-items:start;grid-gap:20px;grid-template-columns:repeat(auto-fit, minmax(min(100%, 320px), 1fr));width:100%}`, "",{"version":3,"sources":["webpack://./src/saas/page/generations/co/FiltersCard.module.scss"],"names":[],"mappings":"AAAA,0BACE,YAAA,CACA,iBAAA,CACA,aAAA,CACA,qEAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": `FiltersCard-filters-d66c`
};
module.exports = ___CSS_LOADER_EXPORT___;
