import { Segmented, SegmentOption } from 'component/Segmented'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { TryOnPosition } from 'type/product/TryOnProduct'

export const PositionSelect = observer(() => {
  const edit = tryon.it.edit
  const { position } = edit

  const options: SegmentOption<TryOnPosition>[] = [{
    value: 'left',
    label: i18n('tryon.PositionLeft'),
  }, {
    value: 'center',
    label: i18n('tryon.PositionCenter'),
  }, {
    value: 'right',
    label: i18n('tryon.PositionRight'),
  }]

  const onChange = useLatestCallback((value: TryOnPosition) => {
    edit.position = value
  })

  return <Segmented options={options} value={position ?? ''} onChange={onChange} />
})
