// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardHeader-header-5d8d{position:relative;margin-top:-16px;margin-bottom:24px}.DashboardHeader-line-5d8d{position:absolute;inset:auto 0 0 0;border-bottom:1px solid #e4e4e4}.DashboardHeader-tab-5d8d{height:100px;border-bottom:2px solid rgba(0,0,0,0)}.DashboardHeader-tab-5d8d.DashboardHeader-active-5d8d{border-bottom-color:currentColor}`, "",{"version":3,"sources":["webpack://./src/saas/screen/page/header/dashboard/DashboardHeader.module.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CACA,gBAAA,CACA,kBAAA,CAGF,2BACE,iBAAA,CACA,gBAAA,CACA,+BAAA,CAGF,0BACE,YAAA,CACA,qCAAA,CAEA,sDACE,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `DashboardHeader-header-5d8d`,
	"line": `DashboardHeader-line-5d8d`,
	"tab": `DashboardHeader-tab-5d8d`,
	"active": `DashboardHeader-active-5d8d`
};
module.exports = ___CSS_LOADER_EXPORT___;
