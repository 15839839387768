// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductSelectType-selectType-7df4{max-width:calc(100dvw - 16px);margin-right:-16px;margin-left:0;overflow:auto}`, "",{"version":3,"sources":["webpack://./src/saas/page/products/components/ProductSelectType.module.scss"],"names":[],"mappings":"AAAA,mCACE,6BAAA,CACA,kBAAA,CACA,aAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectType": `ProductSelectType-selectType-7df4`
};
module.exports = ___CSS_LOADER_EXPORT___;
