import { Field } from 'component/Field'
import { InputColor } from 'component/InputColor'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const TextColorInput = observer(() => {
  const { text, theme } = tryon.it.edit
  const isDarkTheme = theme === 'dark'
  const value = text || (isDarkTheme ? '#FFFFFFA3' : '#000000')

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.text = value.trim()
  })

  return (
    <Field label={i18n('widget.TextColor')}>
      <InputColor
        mono
        lazy
        value={value}
        placeholder="#ffffff"
        onChange={onChange}
      />
    </Field>
  )
})
