import { Button } from 'component/Button'
import { Radio } from 'component/Radio'
import { Space } from 'component/Space'
import { TextArea } from 'component/TextArea'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { space } from 'util/typo'

export const DuplicatesStep = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const store = mod.data.it.duplicates
  const { decision, lines, text, items } = store
  const rows = Math.min(12, lines + 1)

  const onSkip = useLatestCallback((checked: boolean) => store.decision = checked ? 'skip' : undefined)
  const onFirst = useLatestCallback((checked: boolean) => store.decision = checked ? 'first' : undefined)
  const onLast = useLatestCallback((checked: boolean) => store.decision = checked ? 'last' : undefined)

  return <Vertical gap={24}>
    <Typo size={16} error>
      {i18n('catalog.Duplicates')}
    </Typo>

    <Vertical gap={12}>
      <Typo size={14}>
        {i18n('catalog.FoundCountDuplicates', { count: items.length })}
        {space}
        {i18n('catalog.InCountLines', { count: lines })}
      </Typo>

      <TextArea mono rows={rows} readonly value={text} />

      <Vertical gap={4} wrap>
        <Radio allowUncheck checked={decision === 'first'} onChange={onFirst}>
          {i18n('catalog.UseFirstRows')}
        </Radio>
        <Radio allowUncheck checked={decision === 'last'} onChange={onLast}>
          {i18n('catalog.UseLastRows')}
        </Radio>
        <Radio allowUncheck checked={decision === 'skip'} onChange={onSkip}>
          {i18n('catalog.SkipAll')}
        </Radio>
      </Vertical>
    </Vertical>

    <Space gap={12} right>
      <Button action={mod.onBack}>
        {i18n('common.GoBack')}
      </Button>
      <Button primary disabled={!decision} action={mod.onNext}>
        {i18n('common.GoNext')}
      </Button>
    </Space>
  </Vertical>
})
