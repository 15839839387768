// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KeyBox-keyBox-778b{border-radius:8px}.KeyBox-keyBox-778b:nth-child(odd){background:#fbfbfb}`, "",{"version":3,"sources":["webpack://./src/saas/page/company/manage/api/KeyBox.module.scss"],"names":[],"mappings":"AAAA,oBACE,iBAAA,CAEA,mCACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keyBox": `KeyBox-keyBox-778b`
};
module.exports = ___CSS_LOADER_EXPORT___;
