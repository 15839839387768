import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Scroll } from 'component/Scroll'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import css from 'page/invite/ErrorStep.module.scss'
import { closeInviteUsers, InviteFailType, inviteUsers } from 'page/invite/store/InviteUsersStore'

export const ErrorStep = observer(() => {
  const { fails, failType } = inviteUsers.it

  const onClose = useLatestCallback(() => {
    closeInviteUsers()
  })

  const failMessage = () => {
    switch (failType) {
      case InviteFailType.ALREADY_EXISTS:
        return i18n('invite.SomeEmailsAlreadySent')
      case InviteFailType.FAILED:
      default:
        return i18n('invite.SomeInvitationsFailed')
    }
  }

  return (
    <Vertical gap={24}>
      <Typo size={16} secondary error>
        {i18n('common.Error')}
      </Typo>

      <Typo size={12}>{failMessage()}</Typo>

      {fails.length && (
        <Card round={8} pad={8} secondary maxWidth={400}>
          <Scroll className={css.users}>
            <Vertical gap={8}>
              {fails.map((user) => (
                <Space key={user.key} gap={12}>
                  <Typo size={12} ellipsis>
                    {user.name.value}
                  </Typo>
                  <Typo size={12} ellipsis secondary>
                    {user.email.value}
                  </Typo>
                </Space>
              ))}
            </Vertical>
          </Scroll>
        </Card>
      )}

      <Space gap={12} right>
        <Button action={onClose}>{i18n('common.Close')}</Button>
      </Space>
    </Vertical>
  )
})
