import { makeAutoObservable, observable, runInAction } from 'mobx'
import { buildFeedbacks, buildLikes, buildSeries, FeedbackData, PieSerie, StatData } from 'saas/store/stat/Feedback'
import { api } from 'store/api'
import { StatRequest } from 'type/Statistics'
import { skipAsync } from 'util/async'

export class FeedbackStore {
  ready = false
  error = false
  feedbacks: FeedbackData[] = []
  series: PieSerie[] = []
  likes: StatData | undefined
  dislikes: StatData | undefined

  constructor() {
    makeAutoObservable(this, { series: observable.ref })
  }

  reset() {
    this.ready = false
    this.error = false
    this.series = []
  }

  readonly update = skipAsync(async (request: StatRequest) => {
    runInAction(() => {
      this.ready = false
      this.error = false
    })
    try {
      const feedback = await api.getFeedback(request)
      const totals = await api.getTotals(request)
      runInAction(() => {
        this.feedbacks = buildFeedbacks(totals, feedback)
        this.series = buildSeries(totals, feedback)
        this.likes = buildLikes(totals, true)
        this.dislikes = buildLikes(totals, false)
        this.ready = true
      })
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => this.error = true)
    }
  })

}

export const feedback = new FeedbackStore()
