import { Button } from 'component/Button'
import { Popover, usePopoverControl } from 'component/Popover'
import { Space } from 'component/Space'
import { ItemTag } from 'component/Tag'
import { Typo } from 'component/Typo'
import { FormField } from 'form/FormField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ModesPopover } from 'modal/EditCollectionModal/ModesSelect/ModesPopover'
import { MouseEvent, useState } from 'react'
import { modal } from 'modal'

export const ModesSelect = observer(() => {
  const { modes, modesCatalog, onCheckMode } = modal.editCollectionModal.it
  const { empty } = modes
  const [focus, setFocus] = useState(false)
  const control = usePopoverControl()
  const { open, setOpen } = control

  const onOpen = useLatestCallback(() => {
    setOpen(!open)
  })

  const onRemove = useLatestCallback((_event: unknown, value: string) => {
    onCheckMode(false, value)
  })

  const onMouseDown = useLatestCallback((event: MouseEvent) => {
    event.stopPropagation()
  })

  const onClear = useLatestCallback(() => {
    setOpen(false)
    modes.value = []
  })

  return <Popover content={() => <ModesPopover />} control={control}>
    <FormField field={modes} label={i18n('label.Modes')}>
      <Button onMouseDown={onOpen} error={!focus && modes.error} onFocus={setFocus}>
        <Space opposite gap={16} pad={[0, 4, 0, 0]}>
          <Space gap={4} wrap>
            {modes.value.map(value => <ItemTag key={value} data={value} onClose={onRemove}>
              {modesCatalog.getLabel(value)}
            </ItemTag>)}
            {empty && <Typo size={14} tertiary>
              {i18n('placeholder.Modes')}
            </Typo>}
          </Space>
          {empty ? <Icon name="keyboard_arrow_down" size={16} tertiary /> :
            <Icon name="cancel" size={16} tertiary onClick={onClear} onMouseDown={onMouseDown} />}
        </Space>
      </Button>
    </FormField>
  </Popover>
})
