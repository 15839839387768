import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Scroll } from 'component/Scroll'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { Chart } from 'saas/page/statistics/chart/Chart'
import { Legend } from 'saas/page/statistics/chart/Legend'
import { chart } from 'saas/store/stat/ChartStore'
import css from './ChartView.module.scss'

function renderContent(): ReactElement | undefined {
  const { error, ready, series, all } = chart
  if (error) return <Typo size={12} error>
    {i18n('common.Error')}
  </Typo>
  if (!ready) return <Typo size={12} secondary>
    {i18n('chart.Loading')}
  </Typo>
  if (!series.length) return <Typo size={12} secondary>
    {all.length ? i18n('chart.NothingSelected') : i18n('chart.NoData')}
  </Typo>
  return undefined
}

export const ChartView = observer(() => {
  const { ready } = chart
  const hide = !ready
  const content = renderContent()

  return <Card vertical pad={16} wide>
    <Typo size={13} semi>
      {i18n('statistics.GeneralIndicators')}
    </Typo>
    <Gap height={16} />
    {content ? <Vertical wide height={480} center>
      {content}
    </Vertical> : <Scroll className={clsx(css.view, hide && css.hide)}>
      <Chart />
    </Scroll>}
    <Legend />
  </Card>
})
