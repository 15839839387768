import { observer } from 'mobx-react-lite'
import { GenerationsView } from 'saas/page/generations/co/GenerationsView'
import { Page } from 'saas/screen/Page'
import { DashboardHeader } from 'saas/screen/page/header/dashboard/DashboardHeader'
import { GenerationsHistoryModal } from 'saas/shared/generations/hisory/GenerationsHistoryModal'
import { generationsHistory } from 'saas/shared/generations/hisory/GenerationsHistoryStore'

export const GenerationsPage = observer(() => {
  return <Page>
    <DashboardHeader />
    <GenerationsView />
    {generationsHistory.present && <GenerationsHistoryModal />}
  </Page>
})
