import { Segmented } from 'component/Segmented'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ApiKeyTab, companyEdit } from 'saas/store/CompanyEditStore'
import { emdash } from 'util/typo'

function createOption(tab: ApiKeyTab) {
  const label = tab === 'custom' ? i18n('key.CustomTab') : tab === 'system' ? i18n('key.SystemTab') : emdash
  return { value: tab, label }
}

export const TabSelect = observer(() => {
  const { keyTab, keyTabs } = companyEdit.it

  const options = keyTabs.map(createOption)

  const onChange = useLatestCallback((tab: ApiKeyTab) => {
    companyEdit.it.keyTab = tab
  })

  if (keyTabs.length < 2) return null

  return <Segmented options={options} value={keyTab} onChange={onChange} />
})
