import { renderApp } from 'app/App'
import { initTitle } from 'app/config/title'
import { configureDayjs } from 'app/controller/dayjs'
import { lang } from 'app/controller/language'
import { scroll } from 'app/controller/scroll'
import { security } from 'app/controller/security'
import { initSentry } from 'app/controller/sentry'

export function startup(): void {
  initSentry()
  configureDayjs()
  initTitle()
  void lang.start()
  security.start()
  renderApp()
  scroll()
}
