import { Image } from 'component/Image'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { GenerationStore } from 'saas/page/generations/store/GenerationStore'
import { formatDateTime } from 'util/date'

type Props = {
  generation: GenerationStore
}

export const InferenceCard = observer(({ generation }: Props) => {
  const { created_at, images } = generation.json
  const like_mode = images.map(image => image.like_mode)[0] ?? 0
  const like = like_mode === 1
  const dislike = like_mode > 1
  const sources = images.map(image => image.url)
  const source = sources[0]

  return <Vertical>
    <Space round={6} oh>
      {source && <Image src={source} sources={sources} />}
      {(like || dislike) && <Space pin={[null, null, 8, 8]}>
        {like && <Icon size={16} name="thumb_up" fill />}
        {dislike && <Icon size={16} name="thumb_down" fill />}
      </Space>}
    </Space>
    <Gap height={12} />
    <Typo line={13} size={13}>
      {formatDateTime(created_at)}
    </Typo>
  </Vertical>
})
