import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { TryOnCollapsePosition } from 'type/product/TryOnProduct'
import { Radio } from 'component/Radio'
import { Vertical } from 'component/Vertical'

export const CollapsePosition = observer(() => {
  const { collapse_position } = tryon.it.edit
  const value = collapse_position ?? TryOnCollapsePosition.LeftBottom

  const onChangeCollapseButton = useLatestCallback(
    (value: TryOnCollapsePosition) => {
      tryon.it.edit.collapse_position = value
    }
  )

  return (
    <Vertical gap={4}>
      <Radio
        checked={value === TryOnCollapsePosition.LeftBottom}
        onChange={() =>
          onChangeCollapseButton(TryOnCollapsePosition.LeftBottom)
        }
      >
        {i18n('widget.CollapsedPositionLeftBottom')}
      </Radio>
      <Radio
        checked={value === TryOnCollapsePosition.RightBottom}
        onChange={() =>
          onChangeCollapseButton(TryOnCollapsePosition.RightBottom)
        }
      >
        {i18n('widget.CollapsedPositionRightBottom')}
      </Radio>
    </Vertical>
  )
})
