import { SearchCustomSelect, SelectOption } from 'component/Select/SearchCustomSelect'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { filters } from 'saas/page/generations/store/FiltersStore'
import { FilterKey } from 'type/Generations'
import { matchSearch } from 'util/search'

type Opt = SelectOption<string, FilterKey>

export const ApiKeySelect = observer(() => {
  const { key, keys } = filters.it

  const options: Opt[] = keys.map((key, index) => ({
    key: index,
    value: key.access_key,
    data: key,
  }))

  const renderOption = useLatestCallback((value: string, option?: Opt) => {
    const key = option?.data
    if (!key) return value

    return <Vertical gap={4} pad={[4, 0]}>
      <Typo size={13} medium ellipsis>{key.key_name}</Typo>
      <Typo size={10} secondary ellipsis>{key.access_key}</Typo>
    </Vertical>
  })

  const renderLabel = useLatestCallback((value: string, option?: Opt) => {
    const key = option?.data
    return key ? key.key_name : value
  })

  const filterOption = useLatestCallback((search: string, option?: Opt) => {
    if (!option) return false
    const { key_name, access_key } = option.data
    return matchSearch(search, [key_name, access_key])
  })

  const onChange = useLatestCallback((value?: string) => {
    filters.it.key = value ?? ''
  })

  return <Vertical gap={8}>
    <Typo size={13} semi>
      {i18n('company.ApiKey')}
    </Typo>
    <SearchCustomSelect single value={key} options={options} onChange={onChange}
      height={40} placeholder={i18n('statistics.AnyApiKey')}
      renderOption={renderOption} renderLabel={renderLabel} filterOption={filterOption} />
  </Vertical>
})
