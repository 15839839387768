import { makeAutoObservable } from 'mobx'
import { IntervalStore } from 'saas/shared/interval/IntervalStore'
import { loadFilters, saveFilters } from 'saas/store/stat/storage'
import { ApiKey } from 'type/ApiKey'
import { CollectionId } from 'type/Search'

export class StatisticsStore {
  company_id?: string

  readonly interval = new IntervalStore()

  keys: ApiKey[] = []
  collections: CollectionId[] = []
  brands: string[] = []

  private _key: string | undefined
  private _collection: CollectionId | undefined
  private _brand: string | undefined
  private _gender: string | undefined

  constructor() {
    makeAutoObservable(this)
  }

  get key(): string | undefined {
    return this._key
  }

  set key(value: string | undefined) {
    this._key = value
  }

  get collection(): CollectionId | undefined {
    return this._collection
  }

  set collection(value: CollectionId | undefined) {
    this._collection = value
  }

  get brand(): string | undefined {
    return this._brand
  }

  set brand(value: string | undefined) {
    this._brand = value
  }

  get gender(): string | undefined {
    return this._gender
  }

  set gender(value: string | undefined) {
    this._gender = value
  }

  reset(company_id: string | undefined) {
    const filters = loadFilters(company_id)

    this.company_id = company_id
    this.interval.start = filters.start
    this.interval.end = filters.end
    this.keys = []
    this.collections = []
    this.brands = []
    this._key = filters.key
    this._collection = undefined
    this._brand = undefined
    this._gender = undefined
  }

  init(keys: ApiKey[], collections: CollectionId[], brands: string[]) {
    this.keys = keys
    this.collections = collections
    this.brands = brands
  }

  store() {
    const company_id = this.company_id
    const key = this.key
    const start = this.interval.start
    const end = this.interval.end
    saveFilters(company_id, { key, start, end })
  }
}

export const statistics = new StatisticsStore()
