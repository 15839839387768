import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ProductCard } from 'page/internal/products/ProductCard'
import { GenerationsHistoryModal } from 'saas/shared/generations/hisory/GenerationsHistoryModal'
import { generationsHistory } from 'saas/shared/generations/hisory/GenerationsHistoryStore'
import { managerSearchStore } from 'store/manager/search'
import css from './ItemsList.module.scss'

export const ItemsList = observer(() => {
  const { busy, result } = managerSearchStore
  const filtered = !!result?.filtered
  const items = result?.items ?? []
  const empty = items.length === 0

  if (busy) return <Vertical height={600} center>
    <Spinner />
  </Vertical>

  if (empty && !filtered) return <Vertical height={600} center>
    <Typo size={13} secondary>
      {i18n('item.NoItems')}
    </Typo>
  </Vertical>

  if (empty) return <Vertical height={600} gap={24} center>
    <Card width={64} height={64} round={32} center panel>
      <Icon size={24} name="search" />
    </Card>
    <Vertical gap={4} center>
      <Typo size={13}>
        {i18n('search.NothingFound')}
      </Typo>
      <Typo size={13} secondary>
        {i18n('search.TryChangingSearchParametersAndFilters')}
      </Typo>
    </Vertical>
  </Vertical>

  return <Vertical gap={16}>
    <Space className={clsx(css.list, busy && css.busy)} gap={16} top wrap>
      {items.map(item => <ProductCard key={item.key} item={item} />)}
    </Space>
    {generationsHistory.present && <GenerationsHistoryModal />}
  </Vertical>
})
