import { groupDeleteModal } from 'saas/store/groups/GroupDeleteModalStore'
import { filterConfigs } from 'saas/store/groups/GroupFilterConfigsCache'
import { groupsSaas } from 'saas/store/groups/GroupsCache'
import { PageController } from 'store/base/page/PageController'
import { home } from 'store/home'

type Params = {
  company_id: string
}

type Data = object

export class SaasGroupsPageController implements PageController<Params, Data> {
  async load({ company_id }: Params): Promise<Data | undefined> {
    const company = home.selectCompany(company_id)
    if (!company) return
    const _groups = groupsSaas.add(company_id).load()
    const _configs = filterConfigs.add(company_id).load()
    await _configs
    await _groups
    return {}
  }

  select({ company_id }: Params): void {
    groupsSaas.select(company_id)
    filterConfigs.select(company_id)
  }

  close(): void {
    groupDeleteModal.close()
    filterConfigs.close()
    groupsSaas.close()
  }
}

export const saasGroupsPageController = new SaasGroupsPageController()
