import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { KeyBox } from 'saas/page/company/manage/api/KeyBox'
import { TabSelect } from 'saas/page/company/manage/api/TabSelect'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'
import { ui } from 'store/home/ui/HomeUiStore'

export const KeySection = observer(() => {
  const large = ui.window.width > 1440
  const { company_id, tabKeys } = companyEdit.it

  const onAdd = useLatestCallback(async () => {
    companyEdit.it.openKeyEditModal()
  })

  return (
    <Vertical gap={16} wide>
      <Space gap={16} opposite wrap>
        <Space gap={16} flex="1 1 auto" opposite wrap>
          <Space gap={8}>
            <Icon size={24} name="api" tertiary />
            <Typo size={18} semi>
              {i18n('company.ApiKeys')}
            </Typo>
          </Space>
          {!large && <TabSelect />}
        </Space>
        {can.ManageApiKeys(company_id) && <Button primary action={onAdd}
          icon={<Icon size={16} name="add_circle" />}>
          {i18n('company.CreateApiKey')}
        </Button>}
      </Space>
      {large && <TabSelect />}
      <Vertical gap={8} wide>
        {!tabKeys.length && (
          <Typo size={12} secondary>
            {i18n('company.NoApiKeys')}
          </Typo>
        )}
        {tabKeys.map((key) => (
          <KeyBox key={key.key} store={key} />
        ))}
      </Vertical>
    </Vertical>
  )
})
