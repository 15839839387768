import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Glass, Sticker } from 'component/Glass'
import { Image, Preview } from 'component/Image'
import { usePreviewControl } from 'component/Image/PreviewControl'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { useMemo } from 'react'
import { IdentityStore } from 'store/profile/IdentityStore'
import { compact } from 'util/array'

type Props = {
  identity: IdentityStore
}

export const IdentityCard = observer(({ identity }: Props) => {
  const store = modal.testItemModal.it
  const { identity_id, face1_url, face2_url, body1_url } = identity.json
  const active = identity_id === store.identity_id
  const sources = useMemo(() => compact([face1_url, face2_url, body1_url]), [face1_url, face2_url, body1_url])
  const image = sources[0] as string | undefined
  const control = usePreviewControl()

  const onClick = useLatestCallback(() => {
    store.identity_id = identity_id
  })

  const onPreview = useLatestCallback(() => {
    control.setPreview(image)
  })

  return <Card width={100} pic active={active} action={onClick}>
    {image && <Glass fill>
      <Image src={image} />
      <Preview control={control} sources={sources} />
      <Sticker pin={[4, 4, null, null]} hidden vertical>
        <IconButton small action={onPreview}>
          <Icon name="open_in_full" />
        </IconButton>
      </Sticker>
    </Glass>}
  </Card>
})
