import { TryOnProduct } from 'type/product/TryOnProduct'
import { omit } from 'util/object'
import { isEmptyString } from 'util/string'

// keep in sync with showoff-try-on server/src/api/config/options.ts

function buildEmail(product: TryOnProduct) {
  const { on, type, delay, timeout, title, description, agreement, thanks } = product.email ?? {}
  const email = !on ? undefined : omit({
    on,
    type,
    delay,
    timeout,
    title,
    description,
    agreement,
    thanks,
  }, isEmptyString)
  return email
}

function getCustomSelfies(male: boolean, product: TryOnProduct): string[] | undefined {
  const selfies = product.selfies
  const custom = selfies?.custom ? male ? selfies?.male : selfies?.female : undefined
  if (custom && custom.length > 2) return custom
  return undefined
}

function buildSelfies(product: TryOnProduct) {
  const on = !!product.selfies?.templates
  if (!on) return undefined

  const male = getCustomSelfies(true, product)
  const female = getCustomSelfies(false, product)
  return omit({ on, male, female }, isEmptyString)
}

export function options(product: TryOnProduct): object {
  const company = product.company_id
  const { lang, access, color, theme, position } = product
  const email = buildEmail(product)
  const selfies = buildSelfies(product)
  const options = omit({ company, access, lang, email, selfies, color, theme, position }, isEmptyString)
  return options
}
