import { security } from 'app/controller/security'
import { notice } from 'app/notice'
import { Button } from 'component/Button'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { PasswordFormStore } from 'modal/ProfileModal/store/PasswordFormStore'
import { me } from 'store/me'

export const MainTab = observer(() => {
  const store = modal.profileModal.it
  const { password } = me.user
  const { form, name } = store

  const onCancel = useLatestCallback(() => {
    modal.profileModal.close()
  })

  const onLogout = useLatestCallback(async () => {
    security.logout()
  })

  const onSave = useLatestCallback(async () => {
    if (!form.check()) return
    notice.success(i18n('message.Saved'))
  })

  const onChangePassword = useLatestCallback(async () => {
    store.password.open(new PasswordFormStore())
  })

  return <Vertical gap={24}>
    <Vertical gap={12}>
      <TextField field={name} label={i18n('label.UserName')} />
      {password && <Space>
        <Link primary action={onChangePassword}>
          {i18n('user.ChangePassword')}
        </Link>
      </Space>}
    </Vertical>
    <Space gap={12} opposite>
      <Button text action={onLogout}>
        <Icon size={16} name="logout" />
        {i18n('label.Logout')}
      </Button>
      <Space gap={12}>
        <Button filled action={onCancel}>{i18n('common.Cancel')}</Button>
        <Button primary disabled action={onSave}>{i18n('common.Save')}</Button>
      </Space>
    </Space>
  </Vertical>
})
