import { notice } from "app/notice"
import { FormStore } from "form/store/FormStore"
import { i18n } from "i18n"
import { makeAutoObservable } from "mobx"
import { companiesSaas } from "saas/store/CompaniesSaasStore"
import { api } from "store/api"
import { ImageFile } from "store/base/ImageFile"
import { ObjectStore } from "store/base/ObjectStore"
import { StringStore } from "store/base/StringStore"
import { home } from "store/home"
import { me } from "store/me"
import { route } from "store/route"
import { CreateCompanyRequest } from "type/Company"
import { minAsync } from "util/async"

export class CreateCompanyModalStore {
  readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(''), { required: true })
  readonly description = this.form.field(new StringStore(''))
  readonly link = this.form.field(new StringStore(''))
  readonly logo = this.form.field(new ObjectStore<ImageFile>())

  constructor() {
    makeAutoObservable(this)
  }

  get canCreate() {
    return !this.form.error
  }

  create = minAsync(async () => {
    if (!this.form.check()) return
    const request = this.buildRequest()
    const response = await api.createCompany(request)
    const { company_id } = response
    home.companies.addCompany(response)
    companiesSaas.addCompany(response)
    notice.success(i18n('company.CompanyCreated'))
    route.push(`/company/${company_id}/manage`)
  })

  private buildRequest(): CreateCompanyRequest {
    const { user_id } = me.user
    return {
      user_id,
      company_name: this.name.value,
      description: this.description.value,
      link: this.link.value,
      logo: this.logo.value?.file,
    }
  }
}
