import { Tooltip } from 'component/Popover/Tooltip'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { LanguageSelect } from 'saas/page/product/common/LanguageSelect/LanguageSelect'
import { TitleEdit } from 'saas/page/product/common/Title/TitleEdit'
import { AppearanceSettings } from 'saas/page/product/stand/SettingsCard/AppearanceSettings'
import { Banner } from 'saas/page/product/stand/SettingsCard/Banner'
import { GroupSelect } from 'saas/page/product/stand/SettingsCard/GroupSelect'
import { MetaSettings } from 'saas/page/product/stand/SettingsCard/MetaSettings'
import { UiControls } from 'saas/page/product/stand/SettingsCard/UiControls'
import { WidgetSelect } from 'saas/page/product/stand/WidgetSelect/WidgetSelect'

export const SettingsCard = observer(() => {
  return <Vertical wide>
    <Space gap={8}>
      <Icon size={24} name="tune" secondary />
      <Typo size={15} semi>{i18n('label.Settings')}</Typo>
    </Space>
    <Gap height={12} />
    <TitleEdit />
    <Gap height={12} />
    <Typo size={13} medium>{i18n('product.ChooseLanguage')}</Typo>
    <Gap height={8} />
    <LanguageSelect />
    <Gap height={12} />
    <GroupSelect />
    <Gap height={12} />
    <UiControls />
    <Gap height={12} />
    <Banner type="desktop" />
    <Gap height={12} />
    <Banner type="mobile" />
    <Gap height={24} />
    <Divider secondary />
    <Gap height={24} />

    <Space gap={8}>
      <Icon size={24} name="palette" secondary />
      <Typo size={15} semi>{i18n('product.Appearance')}</Typo>
    </Space>
    <Gap height={12} />
    <AppearanceSettings />
    <Gap height={24} />
    <Divider secondary />
    <Gap height={24} />

    <Space gap={8}>
      <Icon size={24} name="code" secondary />
      <Typo size={15} semi>{i18n('stand.Metadata')}</Typo>
      <Tooltip top title={i18n('stand.ShortTextAndImageThatAppearWhenPageSharedOnSocialMedia')}>
        <Icon size={16} name="info" secondary />
      </Tooltip>
    </Space>
    <Gap height={12} />
    <MetaSettings />
    <Gap height={24} />
    <Divider secondary />
    <Gap height={24} />

    <Space gap={8}>
      <Icon size={24} name="code" secondary />
      <Typo size={15} semi>{i18n('product.TryOnWidget')}</Typo>
    </Space>
    <Gap height={12} />
    <WidgetSelect />
  </Vertical>
})
