export const SHOW_MANUAL = false

export type ClientConfig = {
  ws: string
  gradio?: string
  mosaica?: string
  mosaica_api?: string
  bot_feed?: boolean
  tsum_db?: boolean
}
