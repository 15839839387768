// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyCard-companyLogo-a65a{display:flex;flex-direction:column;justify-content:flex-start;align-items:center;min-width:48px;width:48px;height:48px}.CompanyCard-companyLogo-a65a>*{border-radius:var(--ui-border-radius-half);background:var(--ui-background-color-weak);max-width:100%;max-height:100%;overflow:hidden}.CompanyCard-blank-a65a{width:120px;height:120px;background-color:var(--ui-background-color-weak);border-radius:var(--ui-border-radius)}.CompanyCard-buttons-a65a{margin-top:12px;padding-top:12px;border-top:1px solid var(--ui-border-color-tertiary)}.CompanyCard-companyName-a65a{width:calc(100% - 60px)}`, "",{"version":3,"sources":["webpack://./src/saas/page/companies/CompanyCard.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,kBAAA,CAEA,cAAA,CACA,UAAA,CACA,WAAA,CAEA,gCACE,0CAAA,CACA,0CAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CAIJ,wBACE,WAAA,CACA,YAAA,CACA,gDAAA,CACA,qCAAA,CAGF,0BACE,eAAA,CACA,gBAAA,CACA,oDAAA,CAGF,8BACE,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companyLogo": `CompanyCard-companyLogo-a65a`,
	"blank": `CompanyCard-blank-a65a`,
	"buttons": `CompanyCard-buttons-a65a`,
	"companyName": `CompanyCard-companyName-a65a`
};
module.exports = ___CSS_LOADER_EXPORT___;
