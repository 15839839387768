import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'

export const DeleteSourceModal = observer(() => {
  const store = modal.deleteSourceModal.it

  const onCancel = useLatestCallback(() => {
    modal.deleteSourceModal.close()
  })

  const onDelete = useLatestCallback(async () => {
    modal.deleteSourceModal.close()
    await store.deleteSource()
  })

  return <Modal title={i18n('source.DeleteSource')} onCancel={onCancel}>
    <Vertical gap={16}>
      <Typo size={14}>
        {i18n('source.YouAreAboutToDeleteSource')}
      </Typo>
    </Vertical>
    <Space spread gap={12}>
      <Button primary action={onDelete}>{i18n('common.Delete')}</Button>
      <Button action={onCancel}>{i18n('common.Cancel')}</Button>
    </Space>
  </Modal>
})
