import { Checkbox } from 'component/Checkbox'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { Details } from 'saas/page/tsum/co/table/Details'
import { Images } from 'saas/page/tsum/co/table/Images'
import { ItemStore } from 'saas/page/tsum/store/ItemStore'

type Props = {
  item: ItemStore
}

export const ItemRow = observer(({ item }: Props) => {
  const { selected } = item

  const onSelect = useLatestCallback((value: boolean) => {
    item.selected = value
  })

  return <tr>
    <td>
      <Space width={24} height={40} center>
        <Checkbox checked={selected} onChange={onSelect} />
      </Space>
    </td>
    <td>
      <Images item={item} />
    </td>
    <td>
      <Details item={item} />
    </td>
  </tr>
})
