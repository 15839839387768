import { app_domain, production } from 'app/config/constants'

export function getWindowHost() {
  if (production) {
    const dresscode = app_domain === 'dresscode'
    return dresscode ? 'https://window.dresscode.ai' : 'https://window.showoff.app'
  } else {
    return 'https://window-stage.show-off.org'
  }
}
