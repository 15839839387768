import { Segmented } from 'component/Segmented'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import TelegramSvg from 'asset/icon-telegram-simple.svg'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { Space } from 'antd'
import css from './ProductSelectType.module.scss'
import { i18n } from 'i18n'

export const ProductSelectType = observer(() => {
  const options = [
    {
      value: 'all',
      label: i18n('products.option.AllProducts'),
      icon: <Icon size={16} name="apps" fill />,
    },
    {
      value: 'try_on',
      label: i18n('products.option.TryOn'),
      icon: <Icon size={16} name="widgets" fill />,
    },
    {
      value: 'stand',
      label: i18n('products.option.Landings'),
      icon: <Icon size={16} name="ads_click" fill />,
    },
    {
      value: 'bot',
      label: i18n('products.option.Bots'),
      icon: <TelegramSvg width={16} height={16} />,
    },
    {
      value: 'api',
      label: i18n('products.option.Api'),
      disabled: true,
    },
  ]

  const handleChange = (value: string) => {
    productsSaas.it.filters = {
      ...productsSaas.it.filters,
      type: value,
    }
  }

  return (
    <Space className={css.selectType}>
      <Segmented
        fit
        options={options}
        value={productsSaas.it.filters.type || options[0]!.value}
        onChange={handleChange}
      />
    </Space>
  )
})
