import { makeAutoObservable } from 'mobx'
import { matchPath } from 'util/route'

export const LOGIN = '/login'
export const AUTH = '/auth'

function fixPath(pathname?: string): string {
  return pathname?.trim() || '/'
}

class RouteController {
  private _pathname: string = location.pathname
  private _state: object = {}
  private _confirmation: string = ''

  constructor() {
    makeAutoObservable(this)
    window.addEventListener('popstate', this.pop)
  }

  unit(index: number): string | undefined {
    return this.directories[index]
  }

  is(pattern: string): boolean {
    return matchPath(this.pathname, pattern)
  }

  login(url: string) {
    location.assign(url)
  }

  start(creator: boolean) {
    if (creator) {
      this.replace('/')
      for (let n = 1; n <= 3; ++n) history.pushState({}, '', '/')
    } else {
      if (this.open) this.replace('/')
    }
  }

  logout() {
    const returnTo = location.origin + LOGIN
    location.assign(`/api/auth/logout?returnTo=${returnTo}`)
  }

  get pathname(): string {
    return this._pathname
  }

  get state(): unknown {
    return this._state
  }

  get open(): boolean {
    const path = this._pathname
    return [LOGIN, AUTH].includes(path)
  }

  replace(pathname: string, state: object = {}) {
    history.replaceState(state, '', fixPath(pathname))
    this._pathname = location.pathname
    this._state = state
  }

  push(pathname: string, state: object = {}) {
    if (this._confirmation) {
      if (confirm(this.confirmation)) {
        this.confirmation = ''
      } else {
        return
      }
    }

    history.pushState(state, '', fixPath(pathname))
    this._pathname = location.pathname
    this._state = state
  }

  go(pathname: string, state: object = {}) {
    if (this.pathname === fixPath(pathname)) {
      this.replace(pathname, state)
    } else {
      this.push(pathname, state)
    }
  }

  set confirmation(confirmation: string) {
    this._confirmation = confirmation
  }

  get confirmation(): string {
    return this._confirmation
  }

  private get directories(): string[] {
    return this.pathname.split('/').filter(d => !!d)
  }

  private readonly pop = (event: PopStateEvent): void => {
    const { pathname } = location
    this._pathname = pathname
    this._state = event.state || {}
  }
}

export const route = new RouteController()
