import { observer } from 'mobx-react-lite'
import { GroupDeleteModal } from 'saas/page/group/components/GroupDeleteModal'
import { SaasGroupsView } from 'saas/page/groups/components/SaasGroupsView'
import { saasGroupsPageController } from 'saas/page/groups/SaasGroupsPageController'
import { Page } from 'saas/screen/Page'
import { groupDeleteModal } from 'saas/store/groups/GroupDeleteModalStore'
import { usePageController } from 'store/base/page/usePageController'
import { route } from 'store/route'

export const SaasGroupsPage = observer(() => {
  const company_id = route.unit(1)

  const stub = usePageController(saasGroupsPageController, { company_id })
  if (stub) return stub

  return <Page>
    <SaasGroupsView />
    {groupDeleteModal.present && <GroupDeleteModal />}
  </Page>
})
