import { Segmented as SegmentedAntd } from 'antd'
import { SegmentedOptions } from 'antd/es/segmented'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import css from './Segmented.module.scss'

type S = string

export type SegmentOption<Value extends S = S> = {
  value: Value
  label?: string
  icon?: ReactNode
  disabled?: boolean
}

type Props<Value extends S = S> = {
  className?: string
  disabled?: boolean
  onChange(value: Value): void
  options: SegmentOption<Value>[]
  value: Value
  fit?: boolean
}

export function Segmented<Value extends S = S>(props: Props<Value>) {
  const { disabled, onChange, value, fit } = props
  const className = clsx(props.className, css.segmented, fit && css.fit)
  const options = props.options as SegmentedOptions<Value>

  return (
    <SegmentedAntd
      className={className}
      disabled={disabled}
      onChange={onChange}
      options={options}
      value={value}
    />
  )
}
