import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { can } from 'store/can'
import { home } from 'store/home'
import { route } from 'store/route'
import { formatDate, parseDate } from 'util/date'
import { emdash } from 'util/typo'
import css from './CompanyCard.module.scss'

type Props = {
  company: CompanySaasStore
}

export const CompanyCard = observer(({ company }: Props) => {
  const { company_id, company_name, logo_url, created_at, updated_at } =
    company.json
  const co = home.companies.findCompany(company_id)
  const created = parseDate(created_at)
  const updated = parseDate(updated_at)
  const allow = can.ViewCompanyInformation(company_id)
  const manage = allow ? `/company/${company_id}/manage` : undefined

  const onManage = useLatestCallback(async () => {
    if (manage) route.push(manage)
  })

  const onInvite = useLatestCallback(async () => {
    if (co) modal.openInviteUsersModal(co)
  })

  return (
    <Card pad={12} wide action={onManage}>
      <Vertical gap={0} wide>
        <Vertical gap={12}>
          <Space gap={12} wide top>
            <div className={css.companyName}>
              <Typo size={12} secondary tertiary={!company_id} ellipsis>
                {company_id || emdash}
              </Typo>
              <Typo size={16} bold tertiary={!company_name} ellipsis>
                {company_name || emdash}
              </Typo>
            </div>
            <div className={css.companyLogo}>
              {logo_url && (
                <Image compact src={logo_url} sources={[logo_url]} />
              )}
              {!logo_url && <div className={css.blank} />}
            </div>
          </Space>

          <Vertical>
            <Space gap={4} wrap>
              <Typo size={12} secondary ellipsis>
                {i18n('label.CreatedAt')}:
              </Typo>
              <Typo size={12}>{formatDate(created) ?? emdash}</Typo>
            </Space>

            <Space gap={4} wrap>
              <Typo size={12} secondary ellipsis>
                {i18n('label.UpdatedAt')}:
              </Typo>
              <Typo size={12}>{formatDate(updated) ?? emdash}</Typo>
            </Space>
          </Vertical>
        </Vertical>

        <Vertical gap={8} className={css.buttons}>
          {can.InviteUsers(company_id) && (
            <Button link compact wide action={onInvite}>
              <Space gap={4}>
                <Icon size={16} name="add_circle" />
                {i18n('invite.Invite')}
              </Space>
            </Button>
          )}
          {manage && (
            <Link path={manage}>
              <Button wide>
                <Space gap={4}>
                  <Icon size={16} name="tune" />
                  <div>{i18n('company.Manage')}</div>
                </Space>
              </Button>
            </Link>
          )}
        </Vertical>
      </Vertical>
    </Card>
  )
})
