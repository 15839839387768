import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { generations } from 'saas/page/generations/store/GenerationsStore'

const MINUTE = 60_000

function formatTimeout(timeout: number) {
  if (timeout < MINUTE) return (timeout / 1000).toFixed() + ' ' + i18n('text.SecondsShort')
  else return (timeout / MINUTE).toFixed() + ' ' + i18n('text.MinutesShort')
}

export const TimeoutInput = observer(() => {
  const { timeout } = generations.it
  const text = formatTimeout(timeout)

  const onRemove = useLatestCallback(() => {
    const delta = timeout > MINUTE ? MINUTE : 10_000
    generations.it.timeout = timeout - delta
  })

  const onAdd = useLatestCallback(() => {
    const delta = timeout < MINUTE ? 10_000 : MINUTE
    generations.it.timeout = timeout + delta
  })

  return <Card panel gap={4} round={8}>
    <IconButton large action={onRemove}>
      <Icon size={16} name="remove" />
    </IconButton>
    <Typo size={13}>
      {text}

    </Typo>
    <IconButton large action={onAdd}>
      <Icon size={16} name="add" />
    </IconButton>
  </Card>
})
