import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { TryOnCollapseButtonType } from 'type/product/TryOnProduct'
import { Space } from 'component/Space'
import { Radio } from 'component/Radio'
import { ui } from 'store/home/ui/HomeUiStore'
import css from './CollapseButtonType.module.scss'
import clsx from 'clsx'
import IconHanger from 'asset/icon-hanger.svg'

export const CollapseButtonType = observer(() => {
  const { small } = ui.window
  const { collapse_button_type } = tryon.it.edit
  const value = collapse_button_type || TryOnCollapseButtonType.Text

  const onChangeCollapseButtonType = useLatestCallback(
    (value: TryOnCollapseButtonType) => {
      tryon.it.edit.collapse_button_type = value
    }
  )

  return (
    <Space gap={12} vertical={small} columns wrap wide>
      <Radio
        className={clsx(
          css.radio,
          value === TryOnCollapseButtonType.Text && css.active
        )}
        checked={value === TryOnCollapseButtonType.Text}
        onChange={() =>
          onChangeCollapseButtonType(TryOnCollapseButtonType.Text)
        }
      >
        <Space gap={12} opposite wide>
          {i18n('widget.TextCollapseButtonTypeText')}
          <div className={css.buttonRectangle}>
            {i18n('widget.TextCollapseButtonRectangleTitle')}
          </div>
        </Space>
      </Radio>
      <Radio
        className={clsx(
          css.radio,
          value === TryOnCollapseButtonType.Icon && css.active
        )}
        checked={value === TryOnCollapseButtonType.Icon}
        onChange={() =>
          onChangeCollapseButtonType(TryOnCollapseButtonType.Icon)
        }
      >
        <Space gap={12} opposite wide>
          {i18n('widget.TextCollapseButtonTypeIcon')}
          <div className={css.buttonRound}>
            <div className={css.count}>25</div>
            <IconHanger />
          </div>
        </Space>
      </Radio>
    </Space>
  )
})
