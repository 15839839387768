import { makeAutoObservable } from 'mobx'
import { ApiKey, CreateApiKeyRequest, CreateApiKeyResponse, UpdateApiKeyResponse } from 'type/ApiKey'
import { parseDate } from 'util/date'
import { getNextKey } from 'util/id'

export class ApiKeyStore {
  readonly key = getNextKey()
  name!: string
  origins!: string[]
  priority!: number
  value?: string
  created_at?: Date
  readonly!: boolean
  private _show = false

  static fromJson(json: ApiKey): ApiKeyStore {
    const store = new ApiKeyStore()
    store.applyJson(json)
    return store
  }

  static fromStub(request: CreateApiKeyRequest): ApiKeyStore {
    const store = new ApiKeyStore()
    store.applyStub(request)
    return store
  }

  private constructor() {
    makeAutoObservable(this)
  }

  get show(): boolean {
    return this._show
  }

  set show(value: boolean) {
    this._show = value
  }

  applyCreateResponse(json: CreateApiKeyResponse) {
    this.value = json.access_key
  }

  applyUpdateResponse(response: UpdateApiKeyResponse) {
    const json = Array.isArray(response) ? response[0] : response
    if (json) this.applyJson(json)
  }

  applyForm(name: string, origins: string[], priority: number) {
    this.name = name
    this.origins = origins
    this.priority = priority
  }

  private applyJson(json: ApiKey) {
    this.name = json.key_name
    this.value = json.access_key
    this.created_at = parseDate(json.created_at)
    this.origins = json.origins?.split(',').filter(o => !!o) ?? []
    this.readonly = !!json.read_only
    this.priority = json.priority
  }

  private applyStub(request: CreateApiKeyRequest) {
    const { name, origins, priority } = request
    this.name = name
    this.origins = origins.split(',').filter(o => !!o)
    this.created_at = new Date()
    this.readonly = false
    this.priority = priority || 0
  }
}
