// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchContent-grid-676e{display:grid;align-items:start;grid-gap:0;grid-template-columns:repeat(auto-fit, minmax(min(100%, 240px), 1fr));width:fit-content;max-width:100%}.SearchContent-grid-676e>*{max-width:360px}`, "",{"version":3,"sources":["webpack://./src/saas/page/generations/co/SearchContent.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,iBAAA,CACA,UAAA,CACA,qEAAA,CACA,iBAAA,CACA,cAAA,CAEA,2BACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `SearchContent-grid-676e`
};
module.exports = ___CSS_LOADER_EXPORT___;
