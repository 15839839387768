import { Card } from 'component/Card'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { useMemo } from 'react'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { route } from 'store/route'
import { ProductType, ProductTypeList } from 'type/product/ProductType'
import { generateUuid } from 'util/uuid'
import css from './ProductCard.module.scss'

type Props = {
  type: ProductType
}

export const ProductAddButton = ({ type }: Props) => {
  const { company_id } = productsSaas.it.config
  const path = `/company/${company_id}/product/${type}/`

  const handleAdd = useLatestCallback(() => {
    const product_id = generateUuid()
    route.push(`${path}${product_id}`)
  })

  const title = useMemo(() => {
    if (type === ProductTypeList.BOT) {
      return i18n('products.button.AddBot')
    }
    if (type === ProductTypeList.STAND) {
      return i18n('products.button.AddLanding')
    }
    if (type === ProductTypeList.TRY_ON) {
      return i18n('products.button.AddWidget')
    }
  }, [type])

  return (
    <Card
      pad={12}
      className={css.productCard}
      vertical
      tertiary
      action={handleAdd}
      center
      gap={8}
    >
      <Icon name="add_circle" />
      <Typo size={13} medium>
        {title}
      </Typo>
    </Card>
  )
}
