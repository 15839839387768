import { makeAutoObservable } from 'mobx'
import { TsumItem } from 'type/Tsum'
import { filter } from 'util/iterable'

type SelectionItem = {
  selected?: boolean
  combine?: boolean
  item: Readonly<TsumItem>
}

function buildCsvLine(value: SelectionItem): string {
  const { combine, item } = value
  const { product_id, product_url } = item
  return [product_id, product_url, undefined, combine]
    .map(value => value == null ? '' : value)
    .map(value => value.toString().replace(/'s+/g, ' ').trim().replace(/"/g, '\''))
    .join(',')
}

export class SelectionStore {
  private readonly selected = new Set<string>()
  private readonly items = new Map<string, SelectionItem>()

  constructor() {
    makeAutoObservable(this)
    this.load()
  }

  get count(): number {
    return this.selected.size
  }

  update(item: TsumItem) {
    const value = this.items.get(item.product_id)
    if (value) value.item = item
  }

  clear() {
    this.selected.clear()
    this.items.clear()
    this.save()
  }

  select(item: TsumItem, value: boolean) {
    if (value) {
      this.selected.add(item.product_id)
      this.ensure(item).selected = value
    } else {
      this.selected.delete(item.product_id)
      this.items.delete(item.product_id)
    }
    this.save()
  }

  combine(item: TsumItem, value: boolean) {
    this.ensure(item).combine = value
    this.save()
  }

  value(id: string): SelectionItem | undefined {
    return this.items.get(id)
  }

  csv(): string {
    const header = 'product_id,product_url,content_product_id,combine'
    const lines = []
    for (const value of this.items.values()) {
      if (value.selected) lines.push(buildCsvLine(value))
    }
    return header + '\n' + lines.join('\n')
  }

  private ensure(item: TsumItem): SelectionItem {
    const id = item.product_id
    if (!this.items.has(id)) this.items.set(id, { item })
    const observe = this.items.get(id)
    if (!observe) throw new Error('no observe')
    return observe
  }

  private load() {
    try {
      const text = sessionStorage.getItem('tsum.selection.items')
      if (!text) return
      const values = JSON.parse(text)
      if (!Array.isArray(values)) return
      for (const value of values as SelectionItem[]) {
        if (!value.selected) continue
        this.selected.add(value.item.product_id)
        this.items.set(value.item.product_id, value)
      }
    } catch {
      return
    }
  }

  private save() {
    const values = filter(this.items.values(), value => !!value.selected)
    sessionStorage.setItem('tsum.selection.items', JSON.stringify(values))
  }
}

export const selection = new SelectionStore()
