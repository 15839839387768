import { SearchSelect, SelectOption } from 'component/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tsum } from 'saas/page/tsum/store/TsumDbStore'

export const GenderSelect = observer(() => {
  const { genders, gender } = tsum.it

  const options = genders.map<SelectOption>(gender => ({
    value: gender,
  }))

  const onChange = useLatestCallback((value: string) => {
    tsum.it.gender = value
  })

  return <SearchSelect options={options} value={gender} onChange={onChange}
    placeholder={i18n('tsum.Gender')} />
})
