import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ColorSelect } from 'saas/page/tsum/co/ColorSelect'
import { GenderSelect } from 'saas/page/tsum/co/GenderSelect'
import { SeasonSelect } from 'saas/page/tsum/co/SeasonSelect'
import { selection } from 'saas/page/tsum/store/SelectionStore'

function downloadBlob(content: string, filename: string, type: string) {
  const blob = new Blob([content], { type })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.setAttribute('download', filename)
  a.click()
}

export const TopCard = observer(() => {
  const { count } = selection

  const onClear = useLatestCallback(() => {
    selection.clear()
  })

  const onExport = useLatestCallback(() => {
    const csv = selection.csv()
    const date = new Date().toISOString().replace(/[T:]+/g, '-').substring(0, 16)
    downloadBlob(csv, `export-${date}.csv`, 'text/csv;charset=utf-8;')
  })

  return <Card gap={16} pad={16} wrap opposite>
    <Space gap={12}>
      <SeasonSelect />
      <GenderSelect />
      <ColorSelect />
    </Space>
    <Space gap={12}>
      <Space gap={4}>
        <Typo size={13}>
          {i18n('tsum.SelectedItems')}
        </Typo>
        <Typo size={13} semi>
          {count.toFixed()}
        </Typo>
      </Space>
      <Button text disabled={!count} action={onClear}>
        {i18n('tsum.Clear')}
      </Button>
      <Button primary disabled={!count} action={onExport}>
        {i18n('tsum.ExportToCsv')}
      </Button>
    </Space>
  </Card>
})
