import { ForbiddenPage } from 'feature/page/ForbiddenPage/ForbiddenPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { observer } from 'mobx-react-lite'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { CollectionPage } from 'saas/page/collection/CollectionPage'
import { CompaniesPage } from 'saas/page/companies/CompaniesPage'
import { CompanyPage } from 'saas/page/company/CompanyPage'
import { CompanyManagePage } from 'saas/page/company/manage/CompanyManagePage'
import { DocPage } from 'saas/page/doc/DocPage'
import { DocsPage } from 'saas/page/docs/DocsPage'
import { GenerationsPage } from 'saas/page/generations/GenerationsPage'
import { SaasGroupPage } from 'saas/page/group/SaasGroupPage'
import { SaasGroupsPage } from 'saas/page/groups/SaasGroupsPage'
import { ProductPage } from 'saas/page/item/ProductPage'
import { SaasProductPage } from 'saas/page/product/SaasProductPage'
import { SaasProductsPage } from 'saas/page/products/SaasProductsPage'
import { SearchPage } from 'saas/page/search/SearchPage'
import { StatisticsPage } from 'saas/page/statistics/StatisticsPage'
import { TsumDbPage } from 'saas/page/tsum/TsumDbPage'
import { saas } from 'saas/store/SaasController'
import { can } from 'store/can'
import { cfg } from 'store/cfg'
import { route } from 'store/route'

function products() {
  return can.ManageProducts(route.unit(1))
}

function groups() {
  return can.ManageGroups(route.unit(1))
}

function tsum() {
  return can.ManageTsumDb()
}

export const SaasPageRouting = observer(() => {
  const { pathname } = route

  if (!saas.ready) return <LoadingPage />

  if (pathname === '/') return <StatisticsPage />
  if (pathname === '/companies') return <CompaniesPage />
  if (route.is('/company/*/manage')) return <CompanyManagePage />
  if (route.is('/company/*/products'))
    return products() ? <SaasProductsPage /> : <ForbiddenPage />
  if (route.is('/company/*/product/*/*'))
    return products() ? <SaasProductPage /> : <ForbiddenPage />
  if (route.is('/company/*/groups'))
    return groups() ? <SaasGroupsPage /> : <ForbiddenPage />
  if (route.is('/company/*/group/*'))
    return groups() ? <SaasGroupPage /> : <ForbiddenPage />
  if (pathname === '/search') return <SearchPage />
  if (pathname === '/generations') return <GenerationsPage />
  if (route.is('/company/*')) return <CompanyPage />
  if (route.is('/collection/*')) return <CollectionPage />
  if (route.is('/item/*')) return <ProductPage />
  if (route.is('/docs')) return <DocsPage />
  if (route.is('/docs/*')) return <DocPage />
  if (cfg.cfg.tsum_db && route.is('/tsum/db'))
    return tsum() ? <TsumDbPage /> : <ForbiddenPage />

  return <NotFoundPage />
})
