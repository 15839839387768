import { observer } from 'mobx-react-lite'
import { SaasProductsView } from 'saas/page/products/components/SaasProductsView'
import { saasProductsPageController } from 'saas/page/products/SaasProductsPageController'
import { Page } from 'saas/screen/Page'
import { usePageController } from 'store/base/page/usePageController'
import { route } from 'store/route'

export const SaasProductsPage = observer(() => {
  const company_id = route.unit(1)

  const stub = usePageController(saasProductsPageController, { company_id })
  if (stub) return stub

  return (
    <Page>
      <SaasProductsView />
    </Page>
  )
})
