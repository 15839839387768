import { makeAutoObservable, observable, runInAction } from 'mobx'
import { ItemStore } from 'saas/page/tsum/store/ItemStore'
import { selection } from 'saas/page/tsum/store/SelectionStore'
import { api } from 'store/api'
import { mx } from 'store/base/mx'
import { LoadingState } from 'type/Store'
import { TsumFilters, TsumSearchRequest, TsumSort } from 'type/Tsum'
import { skipAsync } from 'util/async'

export class TsumDbStore {
  state: LoadingState = 'none'

  readonly seasons: string[]
  readonly colors: string[]
  readonly genders: string[]

  private _season = ''
  private _color = ''
  private _gender = ''

  private _page = 1
  private _limit = 10
  private _sort: TsumSort = 'created_at_desc'
  more = false

  items: ItemStore[] = []

  static async fromBackend(): Promise<TsumDbStore> {
    const filters = await api.getTsumFilters()
    return new TsumDbStore(filters)
  }

  private constructor(filters: TsumFilters) {
    makeAutoObservable(this, {
      seasons: observable.ref,
      colors: observable.ref,
      genders: observable.ref,
      items: observable.ref,
    })
    this.seasons = filters.seasons
    this.colors = filters.colors
    this.genders = filters.genders
    void this.search()
  }

  get season(): string {
    return this._season
  }

  set season(value: string) {
    this._season = value
    this.change()
  }

  get color(): string {
    return this._color
  }

  set color(value: string) {
    this._color = value
    this.change()
  }

  get gender(): string {
    return this._gender
  }

  set gender(value: string) {
    this._gender = value
    this.change()
  }

  get page(): number {
    return this._page
  }

  set page(value: number) {
    this._page = Math.max(1, value)
    void this.search()
  }

  get limit(): number {
    return this._limit
  }

  set limit(value: number) {
    this._limit = Math.max(1, Math.min(1000, value))
    this.change()
  }

  get sort(): TsumSort {
    return this._sort
  }

  set sort(value: TsumSort) {
    this._sort = value
    this.change()
  }

  get selected(): number {
    return this.items.filter(item => item.selected).length
  }

  set all(value: boolean) {
    tsum.it.items.forEach(item => selection.select(item.json, value))
  }

  private change() {
    this._page = 1
    void this.search()
  }

  private search = skipAsync(async () => {
    runInAction(() => {
      this.state = 'none'
      this.more = false
    })
    try {
      const request = this.buildRequest()
      const items = await api.searchTsumItems(request)
      runInAction(() => {
        this.state = 'ready'
        this.items = items.map(item => new ItemStore(item))
        this.more = items.length >= request.limit
      })
    } catch (e) {
      console.error('items loading failed', e)
      runInAction(() => {
        this.state = 'error'
      })
    }
  })

  private buildRequest(): TsumSearchRequest {
    const { page, limit, sort } = this
    const season = this.season || undefined
    const color = this.color || undefined
    const gender = this.gender || undefined
    return { page, limit, sort, season, color, gender }
  }
}

export const tsum = mx.ref<TsumDbStore>()
