import { notice } from 'app/notice'
import { FormStore } from 'form/store/FormStore'
import { i18n } from 'i18n'
import { makeAutoObservable } from 'mobx'
import { GroupFilterStore } from 'saas/store/groups/GroupFilterStore'
import { GroupStore } from 'saas/store/groups/GroupStore'
import { api } from 'store/api'
import { ArrayStore } from 'store/base/ArrayStore'
import { mx } from 'store/base/mx'
import { ObjectStore } from 'store/base/ObjectStore'
import { StringStore } from 'store/base/StringStore'
import { me } from 'store/me'
import { ItemGroup, ItemGroupFilterConfig, UpdateItemGroupRequest } from 'type/ItemGroup'
import { FilterConditionType, ItemGroupFilter } from 'type/ItemGroupFilter'
import { notNil } from 'util/null'
import { equal, omit } from 'util/object'

export class GroupFormStore {
  private _busy = false
  private _group: GroupStore
  private form = new FormStore()
  readonly name = this.form.field(new StringStore(), { required: true })
  readonly description = this.form.field(new StringStore())
  readonly condition = this.form.field(new ObjectStore<FilterConditionType>())
  readonly filters = this.form.field(new ArrayStore<GroupFilterStore>(), { required: true })

  constructor(group: GroupStore) {
    makeAutoObservable(this)
    this._group = group
    this.reset()
  }

  get updates(): ItemGroup {
    const json = this._group.json
    const group_name = this.name.value
    const group_descr = this.description.value
    const filters: ItemGroupFilter[] = this.filters.value.map(it => it.toJson()).filter(notNil)
    const conditionType = this.condition.value
    const sortOption = json.filter.sortOption
    const filter: ItemGroupFilterConfig = omit({ filters, conditionType, sortOption })
    return { ...json, group_name, group_descr, filter }
  }

  get changed(): boolean {
    const json = this.group.json
    const filters = this.filters.value.length !== this.updates.filter.filters.length
    return filters || !equal(json, this.updates)
  }

  get canReset(): boolean {
    return !this.busy && this.changed
  }

  get canSave(): boolean {
    return !this.busy && this.changed && !this.form.error
  }

  get group(): GroupStore {
    return this._group
  }

  reset() {
    const { group_name, group_descr, filter } = this.group.json
    const { filters, conditionType } = filter
    this.name.value = group_name ?? ''
    this.description.value = group_descr ?? ''
    this.condition.value = conditionType
    this.filters.value = filters.map(it => new GroupFilterStore(it))
  }

  private check(): boolean {
    // need to touch all forms
    this.form.check()
    this.filters.value.forEach(it => it.form.check())
    const error = this.form.error || this.filters.value.some(it => it.form.error)
    return !error
  }

  async save(): Promise<void> {
    this.busy = true
    try {
      if (!this.check()) return
      const request = this.buildUpdateRequest()
      const json = await api.updateGroup(request)
      this.group.json = json
      notice.success(i18n('message.Saved'))
    } finally {
      this.busy = false
    }
  }

  async recalc(): Promise<void> {
    this.busy = true
    try {
      if (!this.check()) return
      const request = this.buildRecalcRequest()
      const json = await api.updateGroup(request)
      this.group.json = json
      notice.success(i18n('group.Recalculated'))
    } finally {
      this.busy = false
    }
  }

  get busy(): boolean {
    return this._busy
  }

  private set busy(value: boolean) {
    this._busy = value
  }

  private buildUpdateRequest(): UpdateItemGroupRequest {
    const recalc = 1
    const { user_id } = me.user
    const { company_id, group_id, group_name, group_descr, filter } = this.updates
    return { user_id, company_id, group_id, group_name, group_descr, filter, recalc }
  }

  private buildRecalcRequest(): UpdateItemGroupRequest {
    const recalc = 1
    const { user_id } = me.user
    const json = this.group.json
    return { ...json, user_id, recalc }
  }
}

export const groupFormSaas = mx.ref<GroupFormStore>()
