import { Space } from 'component/Space'
import { Upload } from 'component/Upload'
import { FormField } from 'form/FormField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ImagePreview } from 'modal/EditCollectionModal/ImagePreview'
import { modal } from 'modal'
import { ImageFile } from 'store/base/ImageFile'

export const ImagesUpload = observer(() => {
  const { images } = modal.editCollectionModal.it

  const onFile = useLatestCallback((file: File) => {
    images.data.add(ImageFile.fromFile(file))
    images.touch = true
  })

  return <FormField field={images} label={i18n('label.CollectionImages')}>
    <Space gap={4} wrap>
      {images.value.map(image => <ImagePreview key={image.id} image={image} />)}
      <Upload accept="image" multiple width={80} pic onFile={onFile}>
        <Icon name="photo_camera" size={20} />
      </Upload>
    </Space>
  </FormField>
})
