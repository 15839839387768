// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InferenceCarousel-carousel-68e8{width:240px;margin:0}.InferenceCarousel-carousel-68e8.antd.ant-carousel .slick-slider{height:100%}.InferenceCarousel-carousel-68e8.antd.ant-carousel .slick-list{height:100%;overflow:visible}.InferenceCarousel-carousel-68e8.antd.ant-carousel .slick-track{height:100%}.InferenceCarousel-carousel-68e8.antd.ant-carousel .slick-slide{position:relative;height:100%}.InferenceCarousel-carousel-68e8.antd.ant-carousel .slick-slide>div{height:100%;pointer-events:auto}.InferenceCarousel-item-68e8{display:flex !important;width:240px;height:100%;justify-content:center;align-items:center;padding-right:12px}`, "",{"version":3,"sources":["webpack://./src/saas/shared/generations/hisory/InferenceCarousel.module.scss"],"names":[],"mappings":"AAAA,iCACE,WAAA,CACA,QAAA,CAKE,iEACE,WAAA,CAGF,+DACE,WAAA,CACA,gBAAA,CAGF,gEACE,WAAA,CAGF,gEACE,iBAAA,CACA,WAAA,CAGF,oEACE,WAAA,CACA,mBAAA,CAQN,6BACE,uBAAA,CACA,WAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": `InferenceCarousel-carousel-68e8`,
	"item": `InferenceCarousel-item-68e8`
};
module.exports = ___CSS_LOADER_EXPORT___;
