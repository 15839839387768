import { BaseProduct } from 'type/product/BaseProduct'
import { TRY_ON } from 'type/product/ProductType'

export type TryOnPosition = 'left' | 'right' | 'center'

export enum TryOnCollapsePosition {
  LeftBottom = 'left-bottom',
  RightBottom = 'right-bottom',
}

export enum TryOnCollapseButtonType {
  Text = 'text',
  Icon = 'icon',
}

export type TryOnColor = {
  primary?: string
  text?: string
  background?: string
  collapse_button?: string
  collapse_button_text?: string
}

export type TryOnUserData = {
  on?: boolean
  type?: 'email' | 'phone'
  delay?: number
  timeout?: number
  title?: string
  description?: string
  agreement?: string
  thanks?: string
}

export type TryOnSelfiesData = {
  templates?: boolean
  custom?: boolean
  male?: string[]
  female?: string[]
}

export type TryOnLikesData = {
  on?: boolean
}

export type TryOnProduct = BaseProduct & {
  type: typeof TRY_ON
  lang?: 'ru' | 'en' | string
  position?: TryOnPosition
  access?: string
  theme?: 'dark' | 'light' | string
  color?: TryOnColor
  email?: TryOnUserData
  selfies?: TryOnSelfiesData
  likes?: TryOnLikesData
  tsum?: boolean
  collapse_position?: TryOnCollapsePosition
  collapse_button_type?: TryOnCollapseButtonType
}
