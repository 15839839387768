// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkButton-link-8146{display:none}.LinkButton-button-8146{height:24px;border:1px dashed;background-color:inherit;border-color:var(--ui-product-type-stand);color:var(--ui-product-type-stand)}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/stand/LinkButton/LinkButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,wBACE,WAAA,CACA,iBAAA,CACA,wBAAA,CACA,yCAAA,CACA,kCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `LinkButton-link-8146`,
	"button": `LinkButton-button-8146`
};
module.exports = ___CSS_LOADER_EXPORT___;
