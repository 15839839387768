import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Vertical } from 'component/Vertical'
import { CountText } from 'feature/pagination/CountText'
import { LimitDropdown } from 'feature/pagination/LimitDropdown'
import { PageInput } from 'feature/pagination/PageInput'
import { SortDropdown } from 'feature/pagination/SortDropdown'
import { getSortFieldLabel, SORT_FIELDS, SortField } from 'feature/pagination/SortField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { ui } from 'store/home/ui/HomeUiStore'

type Store = {
  count?: number
  page: number
  readonly pages: number
  limit: number
  sort: SortField
}

type Props = {
  top?: boolean
  store: Store
}

export const PaginationPanel = observer(({ top, store }: Props) => {
  const { mobile } = ui.window

  const onPage = useLatestCallback((value: number) => store.page = value)
  const onLimit = useLatestCallback((value: number) => store.limit = value)
  const onSort = useLatestCallback((value: SortField) => store.sort = value)

  const sortings = SORT_FIELDS.map(sort => ({
    value: sort,
    label: getSortFieldLabel(sort),
  }))

  if (!top && mobile) {
    return <Vertical gap={16} center>
      <PageInput page={store.page} pages={store.pages} onChange={onPage} scroll />
      <Divider />
      <Space gap={12} wide opposite wrap>
        <LimitDropdown value={store.limit} onChange={onLimit} />
        <SortDropdown value={store.sort} items={sortings} onChange={onSort} />
      </Space>
    </Vertical>
  }

  return <Space gap={12} opposite wrap>
    <LimitDropdown value={store.limit} onChange={onLimit} />
    {top && <CountText count={store.count} />}
    {!top && <PageInput page={store.page} pages={store.pages} onChange={onPage} scroll />}
    <SortDropdown value={store.sort} items={sortings} onChange={onSort} />
  </Space>
})
