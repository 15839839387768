import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { ReactNode } from 'react'
import css from './PageHeader.module.scss'
import { Icon } from 'icon/Icon'
import { i18n } from 'i18n'

type Props = {
  children?: ReactNode
  title?: string | ReactNode
  onBack?: () => void
  onBackTitle?: string
}

export const PageHeader = ({ title, children, onBack, onBackTitle }: Props) => {
  return (
    <Space className={css.pageHeader} wide opposite gap={[24, 26]}>
      <Space gap={12} vertical>
        <Space onClick={onBack} className={css.back}>
          {onBack && (
            <Space gap={4}>
              <Icon className={css.backIcon} name="arrow_back" size={16} />
              <Typo size={13}>{onBackTitle || i18n('common.Back')}</Typo>
            </Space>
          )}
        </Space>
        <Typo size={24} semi>
          {title}
        </Typo>
      </Space>
      <Space>{children}</Space>
    </Space>
  )
}
