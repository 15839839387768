import { Card } from 'component/Card'
import { Divider } from 'component/Space/Divider'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Feedbacks } from 'saas/page/statistics/feedback/Feedbacks'
import { Likes } from 'saas/page/statistics/feedback/Likes'
import { Pie } from 'saas/page/statistics/feedback/Pie'
import { feedback } from 'saas/store/stat/FeedbackStore'
import { renderStubState } from 'util/render'

export const FeedbackCard = observer(() => {
  const { ready, error } = feedback
  const stub = renderStubState(ready, error)

  return <Card vertical pad={16} tall error={error}>
    <Vertical gap={16} width={220} tall>
      <Typo size={13} semi>
        {i18n('statistics.UserReactions')}
      </Typo>
      {stub && <Vertical center tall>
        {stub}
      </Vertical>}
      {!stub && <Vertical gap={16} tall>
        <Pie />
        <Likes />
        <Divider secondary />
        <Feedbacks />
      </Vertical>}
    </Vertical>
  </Card>
})
