// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MemberRow-picture-045c{border-radius:8px;overflow:hidden;width:32px;height:32px;min-width:32px}.MemberRow-picture-045c img{max-width:32px !important;max-height:32px !important}.MemberRow-blank-045c{width:32px;min-width:32px;height:32px;background-color:var(--ui-background-color-default);border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/saas/page/company/manage/MemberRow.module.scss"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CAEA,4BACE,yBAAA,CACA,0BAAA,CAIJ,sBACE,UAAA,CACA,cAAA,CACA,WAAA,CACA,mDAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"picture": `MemberRow-picture-045c`,
	"blank": `MemberRow-blank-045c`
};
module.exports = ___CSS_LOADER_EXPORT___;
