import { Space } from 'component/Space'
import { LimitDropdown } from 'feature/pagination/LimitDropdown'
import { MoreInput } from 'feature/pagination/MoreInput'
import { SortDropdown } from 'feature/pagination/SortDropdown'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { tsum } from 'saas/page/tsum/store/TsumDbStore'
import { getTsumSortLabel, TSUM_SORTINGS, TsumSort } from 'type/Tsum'

function scroll() {
  document.documentElement.scrollTop = 0
}

export const Pagination = observer(() => {
  const store = tsum.it

  const onPage = useLatestCallback((value: number) => {
    store.page = value
    scroll()
  })

  const onLimit = useLatestCallback((value: number) => {
    store.limit = value
    scroll()
  })

  const onSort = useLatestCallback((value: TsumSort) => {
    store.sort = value
    scroll()
  })

  const sortings = TSUM_SORTINGS.map(value => ({ value, label: getTsumSortLabel(value) }))

  return <Space gap={12} opposite wrap>
    <LimitDropdown value={store.limit} onChange={onLimit} />
    <MoreInput more={store.more} page={store.page} onChange={onPage} />
    <SortDropdown value={store.sort} items={sortings} onChange={onSort} />
  </Space>
})
