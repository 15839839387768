import { Dayjs } from 'dayjs'
import { parseDayjs } from 'util/date'
import { getIntervalName, getIntervalStart, today } from 'util/dayjs'
import { omit } from 'util/object'
import { loadJson, saveJson } from 'util/storage'

type FiltersRaw = {
  key?: string
  collection?: string
  item?: string
  interval?: string
  start?: string
  end?: string
}

type FiltersParams = {
  key?: string
  collection?: string
  item?: string
  start: Dayjs
  end: Dayjs
}

type FiltersData = {
  key?: string
  collection?: string
  item?: string
  start?: Dayjs
  end?: Dayjs
}

export function saveFilters(company_id: string | undefined, filters: FiltersParams): void {
  if (!company_id) return
  const key = filters.key || undefined
  const collection = filters.collection || undefined
  const item = filters.item || undefined
  const interval = getIntervalName(filters.start, filters.end)
  const start = interval ? undefined : filters.start?.toISOString()
  const end = interval ? undefined : filters.end?.toISOString()
  const json: FiltersRaw = omit({ key, collection, item, interval, start, end })
  saveJson(`generations/${company_id}/filters`, json)
}

export function loadFilters(company_id: string | undefined): FiltersData {
  if (!company_id) return {}
  const json = loadJson<FiltersRaw>(`generations/${company_id}/filters`) ?? {}
  const key = json.key || undefined
  const collection = json.collection || undefined
  const item = json.item || undefined
  const interval = getIntervalStart(json.interval)
  const start = interval ? interval : parseDayjs(json.start)
  const end = interval ? today() : parseDayjs(json.end)
  return { key, collection, item, start, end }
}
