import { Field } from 'component/Field'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { InputColor } from 'component/InputColor/InputColor'

export const CollapseButtonColorInput = observer(() => {
  const { collapse_button } = tryon.it.edit
  const value = collapse_button || '#fff'

  const onChangeCollapseButton = useLatestCallback((value: string) => {
    tryon.it.edit.collapse_button = value.trim()
  })

  return (
    <Field label={i18n('widget.TextCollapseButtonColor')}>
      <InputColor
        mono
        lazy
        placeholder="#ffffff"
        value={value}
        onChange={onChangeCollapseButton}
      />
    </Field>
  )
})
