import { clsx } from 'clsx'
import { Button } from 'component/Button'
import { Input } from 'component/Input'
import { SearchSelect, TagsInput } from 'component/Select'
import { Select } from 'component/Select/Select'
import { TextArea } from 'component/TextArea'
import { useFocus } from 'hook/useFocus'
import { useLatestCallback } from 'hook/useLatestCallback'
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react'
import css from './Field.module.scss'
import { InputColor } from 'component/InputColor/InputColor'

type Props = {
  children: ReactNode
  className?: string
  wide?: boolean
  error?: boolean
  label: string
  onFocus?(focus: boolean): void
  small?: boolean
}

function getContentClass(props: Props) {
  const nodes = Children.toArray(props.children)
  const node = nodes.find(isValidElement)
  if (!isValidElement(node)) return

  const type = node.type
  if (type === Button) return css.button
  if (type === Input) return css.input
  if (type === TextArea) return css.area
  if (type === Select) return css.select
  if (type === SearchSelect) return css.select
  if (type === TagsInput) return css.select
  if (type === InputColor) return css.input
  return css.content
}

export const Field = (props: Props) => {
  const { wide, error, label, small } = props
  const { onFocus, onBlur, focus } = useFocus(props)
  const content = getContentClass(props)
  const className = clsx(
    props.className,
    css.field,
    wide && css.wide,
    error && css.error,
    label && css.withLabel,
    small && css.small
  )

  const onRef = useLatestCallback((element: HTMLDivElement | null) => {
    if (!element) return
    element.addEventListener('focusin', onFocus)
    element.addEventListener('focusout', onBlur)
  })

  let value = ''
  let placeholder = ''

  const children = Children.map(props.children, (child) => {
    if (isValidElement(child)) {
      value = child.props.value
      placeholder = child.props.placeholder
      if (child.props?.error === undefined && props.error != undefined) {
        return cloneElement(child as ReactElement, { error: props.error })
      }
    }
    return child
  })

  const open = focus || value || placeholder

  return (
    <div className={className} ref={onRef}>
      <div className={content}>{children}</div>
      <div className={clsx(css.label, open && css.open)} title={label}>
        {label}
      </div>
    </div>
  )
}
