import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { observer } from 'mobx-react-lite'
import { filterConfigs } from 'saas/store/groups/GroupFilterConfigsCache'
import { getFilterActionLabel, getFilterTypeLabel, ItemGroupFilter } from 'type/ItemGroupFilter'
import css from './FilterText.module.scss'

type Props = {
  filter: ItemGroupFilter
}

export const FilterText = observer(({ filter }: Props) => {
  const { type, action, value } = filter
  const config = filterConfigs.it.config(type)
  const title = config?.values.find(it => it.val === value)?.title
  const text = title || 'N/A'

  return <Space gap={4}>
    <Typo size={13}>
      {getFilterTypeLabel(type)}
    </Typo>
    <Typo size={11} className={css.action}>
      {getFilterActionLabel(action)}
    </Typo>
    <Typo size={13} error={!title}>
      {text}
    </Typo>
  </Space>
})
