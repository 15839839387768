// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox-checkbox-5337{font-size:13px;overflow:hidden}.Checkbox-checkbox-5337 .ant-checkbox{margin-top:1px;align-self:start}.Checkbox-checkbox-5337 .ant-checkbox+span{overflow:hidden;text-overflow:ellipsis}.Checkbox-color-5337{--ant-color-border: var(--custom-color);--ant-color-primary: var(--custom-color);--ant-color-primary-hover: var(--custom-color, var)}.Checkbox-error-5337{color:var(--ui-text-color-error)}`, "",{"version":3,"sources":["webpack://./src/component/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAAA,wBACE,cAAA,CACA,eAAA,CAEA,sCACE,cAAA,CACA,gBAAA,CAGF,2CACE,eAAA,CACA,sBAAA,CAIJ,qBACE,uCAAA,CACA,wCAAA,CACA,mDAAA,CAGF,qBACE,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `Checkbox-checkbox-5337`,
	"color": `Checkbox-color-5337`,
	"error": `Checkbox-error-5337`
};
module.exports = ___CSS_LOADER_EXPORT___;
