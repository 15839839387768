import { Modal } from 'component/Modal'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { BadUrlStep } from 'modal/UploadCollectionModal/BadUrlStep'
import { CatalogStep } from 'modal/UploadCollectionModal/CatalogStep'
import { DuplicatesStep } from 'modal/UploadCollectionModal/DuplicatesStep'
import { ExistingStep } from 'modal/UploadCollectionModal/ExistingStep'
import { FinalStep } from 'modal/UploadCollectionModal/FinalStep'
import { NoItemIdStep } from 'modal/UploadCollectionModal/NoItemIdStep'
import { NoUrlStep } from 'modal/UploadCollectionModal/NoUrlStep'
import { UploadStep } from 'modal/UploadCollectionModal/UploadStep'

export const UploadCollectionModal = observer(() => {
  const mod = modal.uploadCollectionModal.it
  const { step } = mod

  const onCancel = useLatestCallback(() => {
    modal.uploadCollectionModal.close()
  })

  return <Modal stable title={i18n('catalog.UploadCatalog')} onCancel={onCancel}>
    {step === 'catalog' && <CatalogStep />}
    {step === 'upload' && <UploadStep />}
    {step === 'no_item_id' && <NoItemIdStep />}
    {step === 'existing' && <ExistingStep />}
    {step === 'no_url' && <NoUrlStep />}
    {step === 'bad_url' && <BadUrlStep />}
    {step === 'duplicates' && <DuplicatesStep />}
    {step === 'final' && <FinalStep />}
  </Modal>
})
