import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable } from 'mobx'
import { watermarkPositions } from 'saas/store/watermark/types'
import { api } from 'store/api'
import { BooleanStore } from 'store/base/BooleanStore'
import { mx } from 'store/base/mx'
import { NumberStore } from 'store/base/NumberStore'
import { StringStore } from 'store/base/StringStore'
import { WatermarkItemData, WatermarkUpdateRequest } from 'type/Watermark'
import { equal } from 'util/object'

export class WatermarkFormStore {
  private _busy = false
  private form = new FormStore()
  watermark_data: WatermarkItemData
  watermark_id: string = ''
  watermark_name: string = ''
  url: string = ''

  readonly overlay = this.form.field(new BooleanStore())
  readonly transparency = this.form.field(new NumberStore(5))
  readonly widthPercent = this.form.field(new NumberStore(0), {})
  readonly indentPercent = this.form.field(new NumberStore(0))
  readonly blur = this.form.field(new NumberStore())
  readonly position = this.form.field(new StringStore(''))

  constructor(watermark_id: string, watermark_name: string, url: string, watermark_data: WatermarkItemData) {
    makeAutoObservable(this)
    this.watermark_data = watermark_data
    this.watermark_id = watermark_id
    this.watermark_name = watermark_name
    this.url = url
    this.reset()
  }

  reset() {
    const { transparency, widthPercent, indentPercent, position } = this.watermark_data
    this.transparency.value = transparency ?? 5
    this.widthPercent.value = widthPercent ?? 0
    this.indentPercent.value = indentPercent ?? 0
    this.position.value = position ?? watermarkPositions[0]
  }

  get updates(): WatermarkItemData {
    const json = this.watermark_data

    const transparency = this.transparency.value!
    const widthPercent = this.widthPercent.value!
    const indentPercent = this.indentPercent.value!
    const position = this.position.value as typeof watermarkPositions[number]
    return { ...json, transparency, widthPercent, indentPercent, position }
  }

  get changed(): boolean {
    const json = this.watermark_data
    return !equal(json, this.updates)
  }

  get canReset(): boolean {
    return !this.busy && this.changed
  }

  get canSave(): boolean {
    return !this.busy && this.changed && !this.form.error
  }

  get busy(): boolean {
    return this._busy
  }

  private set busy(value: boolean) {
    this._busy = value
  }

  private check(): boolean {
    this.form.check()
    const error = this.form.error
    return !error
  }

  async save(): Promise<void> {
    this.busy = true
    try {
      if (!this.check()) return
      if (this.changed) {
        const request = this.buildUpdateRequest()
        await api.updateWatermark(request)
      }
    } finally {
      this.busy = false
    }
  }

  private buildUpdateRequest(): WatermarkUpdateRequest {
    const { transparency, widthPercent, indentPercent, position } = this.updates
    const watermark_data = { transparency, widthPercent, indentPercent, position }
    const watermark_name = this.watermark_name
    const watermark_id = this.watermark_id
    const url = this.url
    return { watermark_data, watermark_id, watermark_name, url }
  }
}

export const watermarkForm = mx.ref<WatermarkFormStore>()
