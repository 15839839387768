import { Affix } from 'component/Affix'
import { Card } from 'component/Card'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { ProductSection } from 'page/home/product'
import { CommentsCard } from 'page/internal/comments/CommentsCard'
import { InlineComments } from 'page/internal/comments/InlineComments'
import { ProductTitle } from 'page/internal/product/ProductTitle'
import { useEffect, useRef, useState } from 'react'
import { commenting } from 'store/comment/CommentingStore'
import { home } from 'store/home'
import css from './ProductView.module.scss'

export const ProductView = observer(() => {
  const { inline } = commenting
  const store = home.product.it.comments
  const { small } = store

  const [marginBottom, setMarginBottom] = useState<number>()
  const ref = useRef<HTMLDivElement>(null)

  const onInterval = useLatestCallback(() => {
    if (small) {
      const height = ref.current?.clientHeight ?? 0
      setMarginBottom(height)
    } else {
      setMarginBottom(undefined)
    }
  })

  useEffectOnce(() => {
    const timerId = setInterval(onInterval, 100)
    return () => clearInterval(timerId)
  })

  useEffectOnce(() => {
    window.scroll(0, 0)
  })

  useEffect(() => {
    store.collapse = small
  }, [store, small])

  if (inline) {
    return <Vertical gap={24} className={css.view}>
      <ProductTitle />
      <div className={css.paper}>
        <InlineComments />
      </div>
    </Vertical>
  }

  return <Vertical>
    <ProductTitle />
    <Card gap={24} pad={16} className={css.view} wrap top>
      <div className={css.product} style={{ marginBottom }}>
        <ProductSection />
      </div>
      <div ref={ref} className={css.box}>
        <Affix top={24}>
          <CommentsCard />
        </Affix>
      </div>
    </Card>
  </Vertical>
})
