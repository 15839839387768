import { Divider } from 'antd'
import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Table } from 'component/Table'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Condition } from 'saas/page/group/components/Condition'
import { Description } from 'saas/page/group/components/Description'
import { FilterRow } from 'saas/page/group/components/FilterRow/FilterRow'
import { Name } from 'saas/page/group/components/Name'
import { GroupFilterStore } from 'saas/store/groups/GroupFilterStore'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'
import { groupsSaas } from 'saas/store/groups/GroupsCache'

export const MainView = observer(() => {
  const { filters, canReset, canSave, changed, group } = groupFormSaas.it

  const deleteGroupIfNotSaved = () => {
    if (group.json._draft && !changed) {
      setTimeout(() => groupsSaas.it.removeGroup(group))
    }
  }

  // удаляем группу при анмаунте или закрытии окна,
  // если она не была сохранена
  useEffectOnce(() => {
    window.addEventListener('beforeunload', deleteGroupIfNotSaved)
    return deleteGroupIfNotSaved
  })

  const onAddCondition = useLatestCallback(() => {
    groupFormSaas.it.filters.data.add(new GroupFilterStore())
  })

  const onReset = useLatestCallback(() => {
    groupFormSaas.it.reset()
  })

  const onSave = useLatestCallback(async () => {
    await groupFormSaas.it.save()
    groupPreviewSaas.it.refresh()
  })

  return (
    <Vertical gap={24}>
      <Card pad={24} gap={16} vertical>
        <Vertical gap={16}>
          <Vertical>
            <Vertical gap={16}>
              <Typo size={16} semi>
                {i18n('group.TitleSettings')}
              </Typo>
              <Name />
              <Description />
            </Vertical>
            <Divider
              style={{
                marginLeft: '-24px',
                marginRight: '-24px',
                width: 'auto',
              }}
            />
            <Space wide opposite>
              <Typo size={16} semi>
                {i18n('group.Conditions')}
              </Typo>
              <Condition />
            </Space>
            <Gap height={24} />
            <Vertical gap={12}>
              <Table>
                <Table.THead>
                  <Table.Td>{i18n('group.table.parameter')}</Table.Td>
                  <Table.Td>{i18n('group.table.operator')}</Table.Td>
                  <Table.Td>{i18n('group.table.value')}</Table.Td>
                  <Table.Td></Table.Td>
                </Table.THead>
                <Table.TBody>
                  {filters.value.map((filter) => (
                    <FilterRow key={filter.key} filter={filter} />
                  ))}
                </Table.TBody>
              </Table>
              {filters.empty && (
                <Space height={32}>
                  <Typo size={14} tertiary error={filters.error}>
                    {i18n('group.NoConditions')}
                  </Typo>
                </Space>
              )}
            </Vertical>
            <Space>
              <Button
                link
                compact
                icon={<Icon name="add_circle" />}
                onClick={onAddCondition}
              >
                {i18n('group.AddCondition')}
              </Button>
            </Space>
          </Vertical>
        </Vertical>
        <Space gap={12}>
          <Button primary action={onSave} disabled={!canSave}>
            {i18n('common.Save')}
          </Button>
          <Button link action={onReset} disabled={!canReset}>
            {i18n('form.Reset')}
          </Button>
        </Space>
      </Card>
    </Vertical>
  )
})
