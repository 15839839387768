import { Card } from 'component/Card'
import { Tooltip } from 'component/Popover/Tooltip'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Switch } from 'component/Switch/Switch'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { StatusTag } from 'saas/page/product/common/StatusTag/StatusTag'
import { KeyFastSelect } from 'saas/page/product/tryon/Details/KeyFastSelect'
import { tryon } from 'saas/store/product/ProductSaasController'

export const Status = observer(() => {
  const { draft, busy, key, json } = tryon.it
  const { published } = json
  const noPublish = !key && !published
  const tooltip = draft ? i18n('product.SaveBeforePublishing') : noPublish ? i18n('product.CannotPublishWithoutApiKey') : undefined

  const onPublish = useLatestCallback((publish: boolean) => {
    void tryon.it.publish(publish)
  })

  const info = i18n('key.ApiKeyCanBeAddedInCompanySettings')

  return <Vertical maxWidth={480}>
    <Typo size={15} semi>
      {i18n('product.PublishStatus')}
    </Typo>
    <Gap height={12} />
    <Card pad={16} tertiary vertical top medium>
      <Space gap={12} wrap>
        <Vertical wide>
          <Typo size={13} semi>
            {i18n('product.PublishProduct')}
          </Typo>
          <Typo size={13} secondary>
            {i18n('product.ApiKeyRequiredToPublish')}
          </Typo>
        </Vertical>
        <Tooltip title={tooltip}>
          <Switch checked={!!published} onChange={onPublish} disabled={busy || noPublish || draft} />
        </Tooltip>
      </Space>
      <Gap height={12} />
      <StatusTag />
      <Gap height={16} />
      <Divider secondary />
      <Gap height={16} />
      <Space gap={0}>
        <KeyFastSelect />
        <Tooltip placement="topRight" title={info}>
          <Space width={32} height={48} center>
            <Icon size={16} name="info" secondary />
          </Space>
        </Tooltip>
      </Space>
    </Card>
  </Vertical>
})
