import { SearchCustomSelect, SelectOption } from 'component/Select/SearchCustomSelect'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { filters } from 'saas/page/generations/store/FiltersStore'
import { FilterCollection } from 'type/Generations'
import { matchSearch } from 'util/search'

type Opt = SelectOption<string, FilterCollection>

export const CollectionSelect = observer(() => {
  const { collection, collections } = filters.it

  const options: Opt[] = collections.map((collection, index) => ({
    key: index,
    value: collection.collection_id,
    data: collection,
  }))

  const renderOption = useLatestCallback((value: string, option?: Opt) => {
    const collection = option?.data
    if (!collection) return value

    return <Vertical gap={4} pad={[4, 0]}>
      <Typo size={13} medium ellipsis>{collection.collection_name}</Typo>
      <Typo size={10} secondary ellipsis>{collection.collection_id}</Typo>
    </Vertical>
  })

  const renderLabel = useLatestCallback((value: string, option?: Opt) => {
    const collection = option?.data
    return collection ? collection.collection_name : value
  })

  const filterOption = useLatestCallback((search: string, option?: Opt) => {
    if (!option) return false
    const { collection_id, collection_name } = option.data
    return matchSearch(search, [collection_id, collection_name])
  })

  const onChange = useLatestCallback((value?: string) => {
    filters.it.collection = value ?? ''
  })

  return <Vertical gap={8}>
    <Typo size={13} semi>
      {i18n('collection.Collection')}
    </Typo>
    <SearchCustomSelect single value={collection} options={options} onChange={onChange}
      height={40} placeholder={i18n('statistics.AnyCollection')}
      renderOption={renderOption} renderLabel={renderLabel} filterOption={filterOption} />
  </Vertical>
})
