import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { filters } from 'saas/page/generations/store/FiltersStore'
import { GenerationStore } from 'saas/page/generations/store/GenerationStore'
import { api } from 'store/api'
import { mx } from 'store/base/mx'
import { GenerationsRequest, GenerationsRequestBase } from 'type/Generations'
import { skipAsync } from 'util/async'
import { equal } from 'util/object'

const TIMEOUT_MIN = 10_000
const TIMEOUT_MAX = 10 * 60_000

export class GenerationsStore {
  readonly company_id: string

  readonly limit = 20
  private _page = 1
  private _autoupdate = false
  private _timeout = TIMEOUT_MIN
  private _more = false

  private _busy = false
  private _error = false
  private request: GenerationsRequest | undefined
  private generations: GenerationStore[] = []
  private timer: TimeoutId | undefined

  constructor(company_id: string) {
    makeAutoObservable(this)
    this.company_id = company_id

    // delay needed to merge changes of start and end
    reaction(() => filters.optional?.changes, this.reaction, { delay: 10 })
  }

  get page(): number {
    return this._page
  }

  async next() {
    ++this._page
    await this.load()
  }

  get autoupdate(): boolean {
    return this._autoupdate
  }

  set autoupdate(value: boolean) {
    this._autoupdate = value
    this.schedule()
  }

  get timeout(): number {
    return this._timeout
  }

  set timeout(value: number) {
    this._timeout = Math.max(TIMEOUT_MIN, Math.min(TIMEOUT_MAX, value))
    this.schedule()
  }

  get more(): boolean {
    return this._more
  }

  get busy(): boolean {
    return this._busy
  }

  get error(): boolean {
    return this._error
  }

  get items(): GenerationStore[] {
    return this.generations
  }

  update() {
    void this.reload()
  }

  private buildRequest(): GenerationsRequest | undefined {
    const changes = filters.optional?.changes
    if (!changes) return
    const page = this.page
    const limit = this.limit
    return { ...changes, page, limit }
  }

  private schedule() {
    clearInterval(this.timer)
    if (!this.autoupdate) return

    this.timer = setInterval(() => this.reload(), this.timeout)
  }

  private async load() {
    const request = this.buildRequest()
    if (request) await this.search(request)
  }

  private reload() {
    this._page = 1
    this.generations = []
    this._more = false
    void this.load()
  }

  private readonly reaction = (changes: GenerationsRequestBase | undefined) => {
    if (!changes) return
    const request = this.buildRequest()
    if (!request || equal(request, this.request)) return
    void this.reload()
  }

  private readonly search = skipAsync(async (request: GenerationsRequest) => {
    runInAction(() => {
      this._busy = true
      this._error = false
      this.request = request
    })

    try {
      const response = await api.searchGenerations(request)
      const generations = response.generations.map(it => new GenerationStore(it))
      const more = generations.length === request.limit

      runInAction(() => {
        this._busy = false
        this.generations.push(...generations)
        this._more = more
      })
    } catch (e) {
      console.error('search failed', e)
      runInAction(() => {
        this._busy = false
        this._error = true
      })
    }
  })
}

export const generations = mx.ref<GenerationsStore>()
