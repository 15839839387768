import { CopyButton } from 'component/Button/CopyButton'
import { IconButton } from 'component/Button/IconButton'
import { Checkbox } from 'component/Checkbox'
import { Link } from 'component/Link'
import { Gap, Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ItemStore } from 'saas/page/tsum/store/ItemStore'
import { emdash } from 'util/typo'

type Props = {
  item: ItemStore
}

export const Details = observer(({ item }: Props) => {
  const { combine } = item
  const { brand, category, color, gender, name, product_id, product_url, season } = item.json

  const onCombine = useLatestCallback((value: boolean) => {
    item.combine = value
  })

  return <Vertical gap={16}>
    <Vertical gap={8}>
      <Space gap={4}>
        <Typo line={16} size={9} secondary upper>
          {i18n('tsum.Id')}
        </Typo>
        <Typo line={16} size={11}>
          {product_id}
        </Typo>
        <CopyButton small data={product_id} />
      </Space>

      <Space gap={16}>
        <Vertical>
          <Typo size={13} semi wrap>
            {name || emdash}
          </Typo>
          <Gap height={4} />
          <Typo size={11} semi upper wrap>
            {brand || emdash}
          </Typo>
        </Vertical>
        <Space>
          <Link href={product_url} disabled={!product_url}>
            <IconButton>
              <Icon size={16} name="open_in_new" secondary />
            </IconButton>
          </Link>
        </Space>
      </Space>

      <Space gap={4} top wrap>
        <Vertical minWidth={120}>
          <Typo size={9} secondary upper>
            {i18n('tsum.Season')}
          </Typo>
          <Typo size={13} wrap>
            {season || emdash}
          </Typo>
        </Vertical>
        <Vertical minWidth={120}>
          <Typo size={9} secondary upper>
            {i18n('tsum.Category')}
          </Typo>
          <Typo size={13} wrap>
            {category || emdash}
          </Typo>
        </Vertical>
        <Vertical minWidth={120}>
          <Typo size={9} secondary upper>
            {i18n('tsum.Gender')}
          </Typo>
          <Typo size={11} wrap>
            {gender || emdash}
          </Typo>
        </Vertical>
        <Vertical minWidth={120}>
          <Typo size={9} secondary upper>
            {i18n('tsum.Color')}
          </Typo>
          <Typo size={11} wrap>
            {color || emdash}
          </Typo>
        </Vertical>
      </Space>
    </Vertical>

    <Space>
      <Checkbox checked={combine} onChange={onCombine}>
        {i18n('tsum.Combine')}
      </Checkbox>
    </Space>
  </Vertical>
})
