import { observer } from 'mobx-react-lite'
import { StatisticsView } from 'saas/page/statistics/StatisticsView'
import { Page } from 'saas/screen/Page'
import { DashboardHeader } from 'saas/screen/page/header/dashboard/DashboardHeader'

export const StatisticsPage = observer(() => {
  return <Page>
    <DashboardHeader />
    <StatisticsView />
  </Page>
})
