import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ChartView } from 'saas/page/statistics/chart/ChartView'
import { FeedbackCard } from 'saas/page/statistics/feedback/FeedbackCard'
import { TopItems } from 'saas/page/statistics/items/TopItems'
import { Parameters } from 'saas/page/statistics/Parameters'
import { statisticsPageController } from 'saas/page/statistics/StatisticsPageController'
import { Totals } from 'saas/page/statistics/total/Totals'
import { usePageController } from 'store/base/page/usePageController'
import { home } from 'store/home'

export const StatisticsView = observer(() => {
  const company_id = home.company.optional?.company_id

  const stub = usePageController(statisticsPageController, { company_id })
  if (stub) return stub

  return <Vertical>
    <Vertical wide gap={16}>
      <Typo size={18} semi>
        {i18n('statistics.Settings')}
      </Typo>
      <Vertical wide>
        <Parameters />
      </Vertical>
    </Vertical>
    <Gap height={24} />
    <Divider secondary />
    <Gap height={24} />
    <Vertical gap={16}>
      <Totals />
      <Space gap={16} top>
        <Space spring>
          <ChartView />
        </Space>
        <FeedbackCard />
      </Space>
    </Vertical>
    <Gap height={16} />
    <TopItems />
  </Vertical>
})
