// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageInput-image-6dd3{overflow:hidden;width:100%;height:100%;min-width:48px;min-height:48px}.ImageInput-image-6dd3 svg{width:auto;height:24px}.ImageInput-image-6dd3 img{max-width:100%;max-height:100%;object-fit:contain;border-radius:var(--ui-border-radius-medium)}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/stand/ImageInput/ImageInput.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CAEA,2BACE,UAAA,CACA,WAAA,CAGF,2BACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,4CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `ImageInput-image-6dd3`
};
module.exports = ___CSS_LOADER_EXPORT___;
