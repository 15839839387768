import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { GenerationCard } from 'saas/page/generations/co/GenerationCard'
import { generations } from 'saas/page/generations/store/GenerationsStore'
import css from './SearchContent.module.scss'

export const SearchContent = observer(() => {
  const store = generations.it
  const { busy, error, page, items } = store
  const empty = !items.length

  if (error) return <Vertical height={400} center>
    {busy ? <Spinner /> : <Typo size={13} error>
      {i18n('common.Error')}
    </Typo>}
  </Vertical>

  if (empty) return <Vertical height={400} center>
    {busy ? <Spinner /> : <Typo size={13} secondary>
      {page > 1 ? i18n('search.NoItems') : i18n('search.NothingFound')}
    </Typo>}
  </Vertical>

  return <Vertical>
    <div className={css.grid}>
      {items.map(item => <GenerationCard key={item.key} generation={item} />)}
    </div>
    {busy && <Space height={64} center>
      <Typo size={13} secondary>
        {i18n('statistics.Loading')}
      </Typo>
    </Space>}
  </Vertical>
})
