import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ui } from 'store/home/ui/HomeUiStore'
import { emdash } from 'util/typo'

export type SortItem<Value> = {
  value: Value
  label: string
}

type Props<Value extends string> = {
  value: Value
  items: SortItem<Value>[]
  onChange(value: Value): void
}

export const SortDropdown = observer(<Value extends string, >(props: Props<Value>) => {
  const { onChange, value, items } = props
  const { mobile } = ui.window

  const item = items.find(item => item.value === value)

  const options = items.map<DropdownItem>(({ value, label }) => ({
    key: value,
    label: <Typo primary={value === props.value}>{label}</Typo>,
    action: () => onChange(value),
  }))

  return <Space gap={8} oh maxWidth="calc(100vw - 64px)">
    {!mobile && <Typo size={13} secondary>{i18n('search.Sorting')}</Typo>}
    <Dropdown placement="bottomRight" items={options}>
      <Space gap={2} height={20} oh>
        <Typo size={13} medium ellipsis secondary={!item?.label}>
          {item?.label ?? emdash}
        </Typo>
        <Icon name="keyboard_arrow_down" />
      </Space>
    </Dropdown>
  </Space>
})
