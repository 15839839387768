import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ProductCard } from 'saas/page/products/components/ProductCard'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import {
  getProductTypeTitle,
  ProductType,
  ProductTypeList,
} from 'type/product/ProductType'

import css from './ProductList.module.scss'
import { ProductAddButton } from './ProductAddButton'
import { useLatestCallback } from 'hook/useLatestCallback'

export const ProductsList = observer(() => {
  const { sortedProducts, filters } = productsSaas.it

  const getProductsByType = (type: ProductType) => {
    return sortedProducts.filter((it) => it.json.type === type)
  }

  const productsMap = {
    [ProductTypeList.TRY_ON]: {
      type: ProductTypeList.TRY_ON,
      title: getProductTypeTitle(ProductTypeList.TRY_ON),
      content: getProductsByType(ProductTypeList.TRY_ON),
    },
    [ProductTypeList.STAND]: {
      type: ProductTypeList.STAND,
      title: getProductTypeTitle(ProductTypeList.STAND),
      content: getProductsByType(ProductTypeList.STAND),
    },
    [ProductTypeList.BOT]: {
      type: ProductTypeList.BOT,
      title: getProductTypeTitle(ProductTypeList.BOT),
      content: getProductsByType(ProductTypeList.BOT),
    },
  }

  const isAddButtonShow = useLatestCallback((type: ProductType) => {
    return (
      filters.type === 'all' ||
      (filters.type !== 'all' && filters.type === type)
    )
  })

  const isTitleShow = (type: ProductType) => {
    return filters.type === 'all' || filters.type === type
  }

  return (
    <Vertical>
      {Object.entries(productsMap).map(([key, value]) => {
        return (
          <Vertical gap={16} key={key}>
            {isTitleShow(value.type) && (
              <>
                <Typo size={18} medium>
                  {value.title}
                </Typo>
              </>
            )}
            <Space gap={16} className={css.productList}>
              {value.content.map((it) => (
                <ProductCard key={it.product_id} product={it} />
              ))}
              {isAddButtonShow(value.type) && (
                <ProductAddButton type={value.type} />
              )}
            </Space>
            {isTitleShow(value.type) && <Gap height={16} />}
          </Vertical>
        )
      })}
    </Vertical>
  )
})
