import { makeAutoObservable, observable } from 'mobx'
import { selection } from 'saas/page/tsum/store/SelectionStore'
import { TsumItem } from 'type/Tsum'

export class ItemStore {
  readonly json: TsumItem

  constructor(item: TsumItem) {
    makeAutoObservable(this, {
      json: observable.ref,
    })
    this.json = item
    selection.update(item)
  }

  get id(): string {
    return this.json.product_id
  }

  get selected(): boolean {
    return !!selection.value(this.id)?.selected
  }

  set selected(value: boolean) {
    selection.select(this.json, value)
  }

  get combine(): boolean {
    return !!selection.value(this.id)?.combine
  }

  set combine(value: boolean) {
    selection.combine(this.json, value)
  }
}
