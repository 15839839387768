import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tsum } from 'saas/page/tsum/store/TsumDbStore'

export const EmptyRow = observer(() => {
  const { state, page } = tsum.it
  const busy = state !== 'ready'

  return <tr>
    <td colSpan={99}>
      <Space height={400} wide center>
        <Typo size={13} secondary>
          {busy ? '' : page > 1 ? i18n('search.NoItems') : i18n('search.NothingFound')}
        </Typo>
      </Space>
    </td>
  </tr>
})
