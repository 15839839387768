import { getWindowHost } from 'app/config/window'
import { notice } from 'app/notice'
import { HttpError } from 'app/rest'
import { i18n } from 'i18n'
import { makeAutoObservable, observable } from 'mobx'
import { AliasStore } from 'saas/store/product/stand/AliasStore'
import { StandEditStore } from 'saas/store/product/stand/StandEditStore'
import { productsConfig, ProductsConfigStore } from 'saas/store/products/ProductsConfigStore'
import { api } from 'store/api'
import { StandProduct } from 'type/product/StandProduct'
import { minAsync } from 'util/async'
import { updateObject } from 'util/object'

export class StandProductStore {
  readonly company_id: string
  private _json: StandProduct
  private _edit: StandEditStore
  private _alias: AliasStore

  constructor(company_id: string, json: StandProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this.company_id = company_id
    this._json = json
    this._edit = new StandEditStore(this._json)
    this._alias = new AliasStore(this._json)
  }

  get product_id(): string {
    return this._json.product_id
  }

  get json(): StandProduct {
    return this._json
  }

  get draft(): boolean {
    return !!this.json._draft
  }

  get busy(): boolean {
    return this.config.busy
  }

  get edit(): StandEditStore {
    return this._edit
  }

  get alias(): AliasStore {
    return this._alias
  }

  async applyAlias() {
    if (!this.alias.check()) return
    try {
      const alias = this.alias.alias.value
      await api.setProductAlias(this.product_id, alias)
      const json = updateObject(this._json, { alias: alias || undefined })
      this.json = json
    } catch (e) {
      const used = e instanceof HttpError && e.status === 400
        && e.record.message === 'alias already used'
      const error = used ? i18n('stand.ThisLinkIsAlreadyTakenTryAnotherOne')
        : i18n('stand.FailedToSetLinkPleaseTryAgainLater')
      this.alias.error = error
    }
  }

  async saveSettings() {
    if (!this.edit.check()) return
    const { updates } = this.edit
    const json = updateObject(this._json, updates)
    this.json = json
    this._edit.update(json)
    await this.save()
    notice.success(i18n('message.Saved'))
  }

  async publish(publish: boolean) {
    if (!!this.json.published === publish) return
    const published = publish ? true : undefined
    const update = { published }
    this.json = updateObject(this._json, update)
    await this.save()
  }

  preview = minAsync(async () => {
    if (!this.edit.check()) return
    const { updates } = this.edit
    const json = updateObject(this._json, updates)
    const { company_id, product_id } = json
    await api.saveProductPreview(json)
    const host = getWindowHost()
    window.open(`${host}/preview/${company_id}/${product_id}`)
  })

  open() {
    this._edit = new StandEditStore(this._json)
    this._alias = new AliasStore(this._json)
  }

  private get config(): ProductsConfigStore {
    return productsConfig.get(this.company_id)
  }

  private set json(json: StandProduct) {
    json.updated_at = new Date().toISOString()
    this._json = json
    this.edit.json = this.json
    this.alias.json = this.json
  }

  private async save() {
    await this.config.updateProduct(this._json)
  }
}
