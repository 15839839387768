import { Input, InputProps } from 'component/Input'
import { useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import css from './InputColor.module.scss'

interface Props extends InputProps {
  value: string
  onChange: (value: string) => void
}

export const InputColor = ({ value, onChange, ...restProps }: Props) => {
  const [open, setOpen] = useState(false)

  const handleChange = (color: ColorResult) => {
    onChange(color.hex)
  }

  const handleInputChange = (color: string) => {
    onChange(color)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <div className={css.inputColor}>
      <Input
        {...restProps}
        value={value}
        placeholder="#ffffff"
        onChange={handleInputChange}
        onFocus={handleOpen}
        clear={false}
      />
      <div className={css.color} onClick={handleOpen}>
        <div className={css.colorInner} style={{ backgroundColor: value }} />
      </div>
      {open && (
        <div className={css.picker}>
          <div className={css.cover} onClick={handleClose} />
          <ChromePicker color={value} onChange={handleChange} />
        </div>
      )}
    </div>
  )
}
