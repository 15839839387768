import { Button } from 'component/Button'
import { CopyButton } from 'component/Button/CopyButton'
import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { generations } from 'saas/page/generations/store/GenerationsStore'
import { GenerationStore } from 'saas/page/generations/store/GenerationStore'
import { generationsHistory, GenerationsHistoryStore } from 'saas/shared/generations/hisory/GenerationsHistoryStore'
import css from './GenerationCard.module.scss'

type Props = {
  generation: GenerationStore
}

export const GenerationCard = observer(({ generation }: Props) => {
  const { company_id } = generations.it
  const { item_id, item_name, brand_name, images } = generation.json
  const like_mode = images.map(image => image.like_mode)[0] ?? 0
  const like = like_mode === 1
  const dislike = like_mode > 1
  const sources = images.map(image => image.url)
  const source = sources[0]

  const onHistory = () => {
    generationsHistory.open(new GenerationsHistoryStore({ company_id, item_id, item_name, brand_name }))
  }

  return <Card vertical ghost round={12} pad={16}>
    <Space round={6} oh>
      {source && <Image src={source} sources={sources} />}
      {(like || dislike) && <Space className={css.like} pin={[null, null, 8, 8]} center>
        {like && <Icon size={12} name="thumb_up" fill />}
        {dislike && <Icon size={12} name="thumb_down" fill />}
      </Space>}
    </Space>
    <Gap height={12} />
    <Typo line={20} size={13} semi secondary>
      {brand_name}
    </Typo>
    <Typo line={15} size={15} semi>
      {item_name}
    </Typo>
    <Gap height={4} />
    <Space gap={4}>
      <Space>
        <Typo line={1.5} size={11} secondary ellipsis>
          {i18n('label.ItemId')}
        </Typo>
      </Space>
      <Space gap={4} oh>
        <Typo line={1.5} size={11} ellipsis>{item_id}</Typo>
        <CopyButton data={item_id} />
      </Space>
    </Space>
    <Gap height={12} />
    <Button filled action={onHistory}>
      {i18n('statistics.HistoryOfGenerations')}
    </Button>
  </Card>
})
