import { clsx } from 'clsx'
import css from './Divider.module.scss'

type Props = {
  className?: string;
  secondary?: boolean
  tertiary?: boolean
  vertical?: boolean
}

export function Divider({ className: propsClassName, secondary, tertiary, vertical }: Props) {
  const className = clsx(
    propsClassName,
    secondary && css.secondary,
    tertiary && css.tertiary,
    vertical ? css.vertical : css.horizontal,
  )

  return <div className={className} />
}
