import { Checkbox } from 'component/Checkbox'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { ChartSerie, getSerieTitle } from 'saas/store/stat/ChartSerie'
import { chart } from 'saas/store/stat/ChartStore'

type Props = {
  serie: ChartSerie
}

export const LegendItem = observer(({ serie }: Props) => {
  const { id, color } = serie
  const checked = chart.checked(id)
  const title = getSerieTitle(id)

  const onChange = useLatestCallback((value: boolean) => {
    chart.check(id, value)
  })

  return <Space gap={8}>
    <Checkbox color={color} checked={checked} onChange={onChange}>
      {title}
    </Checkbox>
  </Space>
})
