import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'

import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { ImageUpload } from 'saas/page/company/common/ImageUpload'
import { can } from 'store/can'
import { ui } from 'store/home/ui/HomeUiStore'

export const CreateCompanyModal = observer(() => {
  const { mobile } = ui.window
  const store = modal.createCompanyModal.it
  const { name, description, link, logo, canCreate } = store

  const onCancel = useLatestCallback(() => {
    modal.createCompanyModal.close()
  })

  const onCreate = useLatestCallback(async () => {
    if (!store.form.check()) return
    await store.create()
    modal.createCompanyModal.close()
  })

  const WrapperComponent = mobile ? Vertical : Space
  const width = mobile ? '100%' : 280

  return (
    <Modal onCancel={onCancel} title={i18n('company.CreateNewCompany')}>
      <Vertical gap={16}>
        <WrapperComponent gap={24}>
          <Vertical gap={12} width={width}>
            <TextField field={name} label={i18n('label.Name')} />
            <TextField field={link} label={i18n('label.Website')} />
            <TextField
              rows={4}
              field={description}
              label={i18n('label.Description')}
            />
          </Vertical>
          <Vertical width={width} height="100%">
            <ImageUpload
              field={logo}
              title={i18n('label.Logo')}
              description={i18n('label.LogoDescription')}
            />
          </Vertical>
        </WrapperComponent>
        <Divider />
        <Space gap={12}>
          <Button wide action={onCancel}>
            {i18n('common.Cancel')}
          </Button>
          {can.CreateCompany() && (
            <Button primary wide disabled={!canCreate} action={onCreate}>
              {i18n('common.Create')}
            </Button>
          )}
        </Space>
      </Vertical>
    </Modal>
  )
})
