import { Modal as ModalAntd } from 'antd'
import { useBodyScrollLock } from 'hook/useBodyScrollLock'
import { ReactNode } from 'react'
import css from './Modal.module.scss'

type Props = {
  children?: ReactNode
  onCancel?(): void
  stable?: boolean
  title?: string
  width?: number
}

export const Modal = (props: Props) => {
  const { children, onCancel, stable, title } = props
  const width = props.width ?? 420

  useBodyScrollLock(true)

  return <ModalAntd className={css.modal} wrapClassName={css.wrap} open title={title} onCancel={onCancel}
    width={width} footer={null} destroyOnClose transitionName="" maskTransitionName=""
    maskClosable={!stable} keyboard={!stable}>
    {children}
  </ModalAntd>
}
