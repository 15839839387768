import { DatePicker } from 'antd'
import en from 'antd/es/date-picker/locale/en_US'
import ru from 'antd/es/date-picker/locale/ru_RU'
import { language } from 'app/config/constants'
import { clsx } from 'clsx'
import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { isDayjs } from 'dayjs'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { IntervalStore } from 'saas/shared/interval/IntervalStore'
import { emdash } from 'util/typo'
import css from './IntervalSelect.module.scss'

en.lang.locale = 'en'
ru.lang.locale = 'ru'

const { RangePicker } = DatePicker

type Props = {
  interval: IntervalStore
}

export const IntervalSelect = observer(({ interval }: Props) => {
  const { name, value, week, month, quarter, all, today } = interval
  const locale = language === 'ru' ? ru : en

  const onChange = useLatestCallback((values: unknown[] | null) => {
    const [start, end] = values ?? []
    interval.start = isDayjs(start) ? start : undefined
    interval.end = isDayjs(end) ? end : undefined
  })

  const onToday = useLatestCallback(() => {
    interval.today = true
  })

  const onWeek = useLatestCallback(() => {
    interval.week = true
  })

  const onMonth = useLatestCallback(() => {
    interval.month = true
  })

  const onQuarter = useLatestCallback(() => {
    interval.quarter = true
  })

  const onAll = useLatestCallback(() => {
    interval.all = true
  })

  return <Space gap={8} pad={8} className={css.wrapper} wide wrap>
    <Space gap={8} wrap>
      <Button className={css.button} text active={today} action={onToday}>
        {i18n('interval.Today')}
      </Button>
      <Button className={css.button} text active={week} action={onWeek}>
        {i18n('interval.Week')}
      </Button>
      <Button className={css.button} text active={month} action={onMonth}>
        {i18n('interval.Month')}
      </Button>
      <Button className={css.button} text active={quarter} action={onQuarter}>
        {i18n('interval.Quarter')}
      </Button>
      <Button className={css.button} text active={all} action={onAll}>
        {i18n('interval.All')}
      </Button>
    </Space>
    <RangePicker className={clsx(css.input, !name && css.selected)} popupClassName={css.dropdown}
      format={'DD MMM YYYY'} locale={locale} inputReadOnly popupAlign={{ offset: [0, 0] }}
      value={value} onChange={onChange} separator={emdash} suffixIcon={false} allowClear={false} />
  </Space>
})
