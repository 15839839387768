import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ItemsCard } from 'saas/page/tsum/co/ItemsCard'
import { TopCard } from 'saas/page/tsum/co/TopCard'
import { tsumDbPageController } from 'saas/page/tsum/TsumDbPageController'
import { Page2 } from 'saas/screen/page/Page2/Page2'
import { usePageController } from 'store/base/page/usePageController'

export const TsumDbPage = observer(() => {
  const stub = usePageController(tsumDbPageController, {})
  if (stub) return stub

  return <Page2>
    <Vertical gap={4}>
      <Typo size={23} bold>
        {i18n('tsum.ItemDatabase')}
      </Typo>
      <Typo size={11} error>
        {i18n('tsum.ProductionWarning')}
      </Typo>
    </Vertical>
    <Vertical gap={16}>
      <TopCard />
      <ItemsCard />
    </Vertical>
  </Page2>
})
