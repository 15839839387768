// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollapseButtonType-radio-3ccf{padding:11px 12px;border:1px solid var(--ui-border-color-secondary);border-radius:var(--ui-border-radius-medium);margin-inline-end:0;display:flex;align-items:center;justify-content:space-between;width:100%}.CollapseButtonType-radio-3ccf>span:first-child{align-self:center;margin:0}.CollapseButtonType-radio-3ccf>span:not(:first-child){flex:1}.CollapseButtonType-active-3ccf{border-color:var(--ui-primary-color)}.CollapseButtonType-buttonRectangle-3ccf{padding:15px 16px;border-radius:4px;background-color:rgba(30,30,30,.0784313725);text-transform:uppercase}.CollapseButtonType-buttonRound-3ccf{width:48px;height:48px;display:flex;align-items:center;justify-content:center;border-radius:50%;background-color:rgba(30,30,30,.0784313725);position:relative}.CollapseButtonType-buttonRound-3ccf .CollapseButtonType-count-3ccf{width:18px;height:18px;position:absolute;border-radius:50%;display:flex;align-items:center;justify-content:center;background-color:#fff;color:#000;font-size:9px;font-weight:600;left:0;top:0}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/tryon/SettingsCard/CollapseButtonType.module.scss"],"names":[],"mappings":"AAAA,+BACE,iBAAA,CACA,iDAAA,CACA,4CAAA,CACA,mBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CAEA,gDACE,iBAAA,CACA,QAAA,CAGF,sDACE,MAAA,CAIJ,gCACE,oCAAA,CAGF,yCACE,iBAAA,CACA,iBAAA,CACA,2CAAA,CACA,wBAAA,CAGF,qCACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,2CAAA,CACA,iBAAA,CAEA,oEACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,UAAA,CACA,aAAA,CACA,eAAA,CACA,MAAA,CACA,KAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `CollapseButtonType-radio-3ccf`,
	"active": `CollapseButtonType-active-3ccf`,
	"buttonRectangle": `CollapseButtonType-buttonRectangle-3ccf`,
	"buttonRound": `CollapseButtonType-buttonRound-3ccf`,
	"count": `CollapseButtonType-count-3ccf`
};
module.exports = ___CSS_LOADER_EXPORT___;
