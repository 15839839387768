import { chart } from 'saas/store/stat/ChartStore'
import { feedback } from 'saas/store/stat/FeedbackStore'
import { statisticsController } from 'saas/store/stat/StatisticsController'
import { statistics } from 'saas/store/stat/StatisticsStore'
import { tops } from 'saas/store/stat/TopItemsStore'
import { totals } from 'saas/store/stat/TotalsStore'
import { api } from 'store/api'
import { PageController } from 'store/base/page/PageController'
import { ApiKey } from 'type/ApiKey'
import { CollectionId } from 'type/Search'

type Params = {
  company_id: string | undefined
}

type Data = {
  keys: ApiKey[]
  collections: CollectionId[]
  brands: string[],
}

export class StatisticsPageController implements PageController<Params, Data> {
  async load({ company_id }: Params): Promise<Data | undefined> {
    if (!company_id) return

    const _keys = api.getApiKeys(company_id)
    const _config = api.getSearchConfig(company_id)

    const keys = await _keys
    const config = await _config
    const collections = config.collections ?? []
    const brands = config.brands ?? []

    return { keys, collections, brands }
  }

  select({ company_id }: Params, { keys, collections, brands }: Data): void {
    statisticsController.init()
    statistics.reset(company_id)
    statistics.init(keys, collections, brands)
  }

  close(): void {
    totals.reset()
    chart.reset()
    tops.reset()
    feedback.reset()
    statistics.reset(undefined)
  }
}

export const statisticsPageController: PageController<Params, Data> = new StatisticsPageController()
