import { Card } from 'component/Card'
import { Fixed } from 'component/Portal/Fixed'
import { observer } from 'mobx-react-lite'
import { Info } from 'saas/page/statistics/feedback/Info'
import { PieSerie } from 'saas/store/stat/Feedback'

type Props = {
  datum: {
    data: PieSerie
  }
}

export const Tooltip = observer((props: Props) => {
  return <Fixed>
    <Card pad={8}>
      <Info data={props.datum.data} />
    </Card>
  </Fixed>
})
