import { groupsSaas } from 'saas/store/groups/GroupsCache'
import { productDeleteModal } from 'saas/store/product/ProductDeleteModalStore'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { createProduct } from 'saas/store/product/utils'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { PageController } from 'store/base/page/PageController'
import { home } from 'store/home'
import { ProductType } from 'type/product/ProductType'
import { buildProductDraft } from 'type/product/utils'

type Params = {
  company_id: string
  type: ProductType
  product_id: string
}

type Data = {
  product: ProductSaasStore
}

export class SaasProductPageController implements PageController<Params, Data> {
  async load({ company_id, type, product_id }: Params): Promise<Data | undefined> {
    const company = home.selectCompany(company_id)
    if (!company) return
    const _groups = groupsSaas.add(company_id).load()
    const _products = productsSaas.add(company_id).load()
    await _groups
    await _products
    const { products } = productsSaas.get(company_id)
    const product = products.find(product => product.product_id === product_id)

    // создаем продукт, если он не существует
    if (!product) {
      const draft = buildProductDraft(company_id, type, product_id)
      const newProduct = createProduct(company_id, draft)
      if (newProduct) {
        return { product: newProduct }
      }
      return
    }

    // проверяем что тип продукта совпадает с тем что в адресной строке
    if (product.json.type !== type) {
      return
    }

    return { product }
  }

  select(params: Params, data: Data): void {
    groupsSaas.select(params.company_id)
    productsSaas.select(params.company_id)
    productSaas.open(data.product)
    productSaas.it.open()
  }

  close(): void {
    productDeleteModal.close()
    productsSaas.close()
    groupsSaas.close()
  }
}

export const saasProductPageController = new SaasProductPageController()
