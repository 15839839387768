import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Info } from 'saas/page/statistics/feedback/Info'
import { feedback } from 'saas/store/stat/FeedbackStore'

export const Feedbacks = observer(() => {
  const { feedbacks } = feedback
  const empty = !feedbacks.length

  if (empty) return <Vertical gap={8} center tall>
    <Typo size={13} medium>
      {i18n('statistics.NoUserReactions')}
    </Typo>
    <Typo size={13} secondary center>
      {i18n('statistics.PerhapsDataNotCollectedOrSettingTurnedOff')}
    </Typo>
  </Vertical>

  return <Vertical gap={12}>
    {feedbacks.map((it, index) => <Space key={index} gap={12} top>
      <Space width={16} height={16} center>
        <Icon size={8} name="circle" fill color={it.color} />
      </Space>
      <Info data={it} />
    </Space>)}
  </Vertical>
})
