import { Checkbox } from 'component/Checkbox'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const LikesCheckbox = observer(() => {
  const { likes } = tryon.it.edit

  const onChange = useLatestCallback((value: boolean) => {
    tryon.it.edit.likes = value
  })

  return <Checkbox checked={likes} onChange={onChange}>
    <Vertical gap={4}>
      <Typo size={13} medium>{i18n('tryon.AllowUsersToRateImages')}</Typo>
      <Typo size={13} secondary>{i18n('tryon.UsersCanGiveLikesAndDislikesToTheGeneratedImages')}</Typo>
    </Vertical>
  </Checkbox>
})
